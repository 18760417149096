import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/storeTypes';
import currencyData from './currencyData';
const useStyle = makeStyles((theme: Theme) => ({
  select: {
    padding: '0',
    margin: 0,
    display: 'flex',
    marginBottom: '0.625rem',
  },

  label: {
    color: theme.palette.text.primary,
  },
}));
interface Props {
  value: any;
  disabled?: boolean;
  onChange: (
    event: ChangeEvent<{ name?: string | undefined; value: string }>
  ) => void;
}
const SelectCurrency: React.FC<Props> = (props) => {
  const style = useStyle();
  const campaignTableState = useSelector((state: AppState) => state.campaigns);
  return (
    <FormControl
      className={style.select}
      disabled={campaignTableState.loading || props.disabled}
      required
    >
      <InputLabel className={style.label}>Currency</InputLabel>
      <Select value={props.value} onChange={props.onChange} variant='standard'>
        <MenuItem value=''></MenuItem>
        {currencyData.map((currency) => (
          <MenuItem key={currency} value={currency}>
            {currency}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCurrency;
