import { makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import { FileCopyOutlined, Info } from '@material-ui/icons';
import React from 'react';
import { SingleCampaignApi } from '../../../campaignsTypes';

const useStyles = makeStyles(() => ({
  copyIcon: {
    cursor: 'pointer',
  },
}));
interface Props {
  tags: SingleCampaignApi['tags'] | undefined;
  name: SingleCampaignApi['campaignName'] | undefined;
}
const TagInformationTab: React.FC<Props> = (props) => {
  const styles = useStyles();
  const homeText = 'Paste this tag on the header of the home page.';
  const categoryText =
    'Paste this tag on the header of the Category page, tell the partner to provide the category id.';
  const productText =
    'Paste this tag on the header of the Product page, tell the partner to provide the category and product id.';
  const carText =
    'Paste this tag on the header of the Car page, tell the partner to provide the ids of the products in the car.';
  const congratsText =
    'Paste this tag on the header of the Success purchase page, tell the partner to provide the ids of the products and the amount.';
  const home = props.tags?.home;

  const category = props.tags?.category;

  const product = props.tags?.product;
  const car = props.tags?.car;

  const congrats = props.tags?.congrats;

  const fullTags = `Tags for ${props.name?.replace(/\s/g, '_')} \n\n\n
----------------------------------------------------------------------------------------------------------
Home | ${homeText} 
----------------------------------------------------------------------------------------------------------
${home}
----------------------------------------------------------------------------------------------------------
Category | ${categoryText} 
----------------------------------------------------------------------------------------------------------
${category}
----------------------------------------------------------------------------------------------------------
Product | ${productText} 
----------------------------------------------------------------------------------------------------------
${product}
----------------------------------------------------------------------------------------------------------
Car | ${carText} 
----------------------------------------------------------------------------------------------------------
${car}
----------------------------------------------------------------------------------------------------------
Success | ${congratsText} 
----------------------------------------------------------------------------------------------------------
${congrats}`;
  const copy = async (element: string) => {
    try {
      await navigator.clipboard.writeText(element);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Typography variant='body1'>
        Copy all tags{' '}
        <FileCopyOutlined
          className={styles.copyIcon}
          color='secondary'
          onClick={() => copy(fullTags)}
        />
      </Typography>
      <div style={{ marginBottom: '0.625rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid gray',
            marginBottom: '0.325rem',
          }}
        >
          <Typography variant='subtitle1'>Home</Typography>
          <div>
            <FileCopyOutlined
              className={styles.copyIcon}
              color='secondary'
              onClick={() => copy(home!)}
            />
            <Tooltip title={<p>{homeText}</p>} arrow>
              <Info />
            </Tooltip>
          </div>
        </div>
        <TextField fullWidth={true} disabled value={home} multiline rows={4} />
      </div>
      <div style={{ marginBottom: '0.625rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid gray',
            marginBottom: '0.325rem',
          }}
        >
          <Typography variant='subtitle1'>Category</Typography>
          <div>
            <FileCopyOutlined
              className={styles.copyIcon}
              color='secondary'
              onClick={() => copy(category!)}
            />
            <Tooltip title={<p>{categoryText}</p>} arrow>
              <Info />
            </Tooltip>
          </div>
        </div>
        <TextField
          fullWidth={true}
          disabled
          value={category}
          multiline
          rows={4}
        />
      </div>

      <div style={{ marginBottom: '0.625rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid gray',
            marginBottom: '0.325rem',
          }}
        >
          <Typography variant='subtitle1'>Product</Typography>
          <div>
            <FileCopyOutlined
              className={styles.copyIcon}
              color='secondary'
              onClick={() => copy(product!)}
            />
            <Tooltip title={<p>{productText}</p>} arrow>
              <Info />
            </Tooltip>
          </div>
        </div>
        <TextField
          fullWidth={true}
          disabled
          value={product}
          multiline
          rows={4}
        />
      </div>
      <div style={{ marginBottom: '0.625rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid gray',
            marginBottom: '0.325rem',
          }}
        >
          <Typography variant='subtitle1'>Car</Typography>
          <div>
            <FileCopyOutlined
              className={styles.copyIcon}
              color='secondary'
              onClick={() => copy(car!)}
            />
            <Tooltip title={<p>{carText}</p>} arrow>
              <Info />
            </Tooltip>
          </div>
        </div>
        <TextField fullWidth={true} disabled value={car} multiline rows={4} />
      </div>
      <div style={{ marginBottom: '0.625rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '1px solid gray',
            marginBottom: '0.325rem',
          }}
        >
          <Typography variant='subtitle1'>Thank you page</Typography>
          <div>
            <FileCopyOutlined
              className={styles.copyIcon}
              color='secondary'
              onClick={() => copy(congrats!)}
            />
            <Tooltip title={<p>{congratsText}</p>} arrow>
              <Info />
            </Tooltip>
          </div>
        </div>
        <TextField
          fullWidth={true}
          disabled
          value={congrats}
          multiline
          rows={4}
        />
      </div>
    </div>
  );
};

export default TagInformationTab;
