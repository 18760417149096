import React from 'react';
import { Grid, Switch, Typography } from '@material-ui/core';

interface Props {
  checked?: boolean | undefined;
  onChangeSwitch: (checked: boolean, id: number) => void;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  offText: string;
  onText: string;
  buttonClass?: string;
  trackClass?: string;
  elementId: number;
}

const SwitchInput: React.FC<Props> = (props) => {
  return (
    <Typography component='div'>
      <Grid component='label' container alignItems='center' spacing={1}>
        <Grid item>{props.checked ? props.onText : props.offText}</Grid>
        <Grid item>
          <Switch
            classes={{ switchBase: props.buttonClass, track: props.trackClass }}
            checked={props.checked}
            onChange={(event, checked) =>
              props.onChangeSwitch(checked, props.elementId)
            }
            color={props.color}
          />
        </Grid>
      </Grid>
    </Typography>
  );
};

export default SwitchInput;
