import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
  Paper,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
  IconButton,
  Alert,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/storeTypes';
import {
  authErrorClear,
  authResetPassword,
  authResetPasswordEmail,
  resetPasswordMessageClear,
  signIn,
} from './authActions';
import authStyles from './authStyles';
import { Link, useParams, useHistory } from 'react-router-dom';

interface Props {
  type: 'sign in' | 'reset send email' | 'reset password';
}
const useStyles = authStyles;
const SignIn: React.FC<Props> = ({ type }) => {
  //Styles for the component.
  const {
    container,
    backButton,
    loginDiv,
    loginForm,
    inputLogin,
    buttonLogin,
    buttonResetPassword,
  } = useStyles();
  //Gets the history hook from react router.
  const history = useHistory();
  //Stores the username the user types.
  const [username, setUsername] = useState('');
  //Stores the password ther user types.
  const [password, setPassword] = useState('');
  //Store the repeat password when the user is reseting it.
  const [repeatPassword, setRepeatPassword] = useState('');
  //Handles the change in user name.
  const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  //Handles tha change on password.
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  //Handles the change on repeat password.
  const onChangeRepeatPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value);
  };
  //Gets the authstate from redux.
  const authState = useSelector((state: AppState) => state.auth);
  //Gets the dispatch hooks to be able to dispatch actions.
  const dispatch = useDispatch();
  //Gets the params from the url.
  const params: { token: string } = useParams();
  //Handles the submit from the form.
  const onSubmitForm = (event: FormEvent) => {
    event.preventDefault();
    //It dispatch the action according to the type of view of the user.
    if (type === 'sign in') {
      dispatch(signIn(username, password));
    }
    if (type === 'reset send email') {
      dispatch(authResetPasswordEmail(username));
    }
    if (type === 'reset password') {
      if (password !== repeatPassword) {
        setPasswordError((prev) => ({
          ...prev,
          errorPass2: true,
          messagePass2: 'Password dont match',
        }));
        return;
      } else {
        dispatch(
          authResetPassword(username, password, repeatPassword, params.token)
        );
      }
    }
  };
  //It clears the error or message popup.
  const onClearError = () => {
    console.log('clear');
    if (type === 'reset send email') {
      dispatch(resetPasswordMessageClear());
      return;
    }
    dispatch(authErrorClear());
    if (type === 'reset password') {
      history.push('/signIn');
    }
  };
  //Check if there is an error with the passwords when changing them.
  const [passwordError, setPasswordError] = useState({
    errorPass1: false,
    messagePass1: '',
    errorPass2: false,
    messagePass2: '',
  });
  //When the user clicks await from input it validates the passwords.
  const onBlurNewPassword = () => {
    if (password !== repeatPassword) {
      setPasswordError((prev) => ({
        ...prev,
        errorPass2: true,
        messagePass2: 'Password dont match',
      }));
    } else {
      setPasswordError({
        errorPass1: false,
        messagePass1: '',
        errorPass2: false,
        messagePass2: '',
      });
    }
  };
  //When the user clciks await from input of password it validates it.
  const onBlurPassword = () => {
    if (password.length === 0) {
      setPasswordError((prev) => ({
        ...prev,
        errorPass1: true,
        messagePass1: 'Password cant be empty',
      }));
    } else {
      setPasswordError((prev) => ({
        ...prev,
        errorPass1: false,
        messagePass1: '',
      }));
    }
  };
  //Changes text according to the type the user is viewing.
  let title = process.env.REACT_APP_TITLE;
  let userNameLabel = 'Username';
  if (type !== 'sign in') {
    title = 'Reset Password';
    userNameLabel = 'Email';
  }
  let buttonText = 'Log In';
  let passwordText = 'Password';
  if (type === 'reset password') {
    buttonText = 'Reset Password';
    passwordText = 'New Password';
  }
  if (type === 'reset send email') {
    buttonText = 'Send Reset Email';
  }

  return (
    <div className={container}>
      <Paper className={loginDiv}>
        {type !== 'sign in' && (
          <div className={backButton}>
            <Link to='/signIn'>&larr; Sign in</Link>{' '}
          </div>
        )}
        <Typography variant='h2' component='h1' color='primary'>
          {title}
        </Typography>

        {type === 'reset send email' && (
          <Typography component='p'>
            Enter your email address in the form below and we will send you
            further instructions on how to reset your password.
          </Typography>
        )}
        <form className={loginForm} onSubmit={onSubmitForm}>
          <TextField
            className={inputLogin}
            type='email'
            label={userNameLabel}
            variant='outlined'
            onChange={onChangeUsername}
            value={username}
            autoComplete={type === 'sign in' ? 'username' : 'false'}
          />
          {type !== 'reset send email' && (
            <TextField
              className={inputLogin}
              type='password'
              label={passwordText}
              variant='outlined'
              onChange={onChangePassword}
              error={passwordError.errorPass1}
              helperText={passwordError.messagePass1}
              onBlur={onBlurPassword}
              value={password}
              autoComplete={type === 'sign in' ? 'current-password' : ''}
            />
          )}
          {type === 'reset password' && (
            <TextField
              error={passwordError.errorPass2}
              helperText={passwordError.messagePass2}
              className={inputLogin}
              type='password'
              label='Repeat Password'
              variant='outlined'
              onChange={onChangeRepeatPassword}
              value={repeatPassword}
              onBlur={onBlurNewPassword}
            />
          )}
          {type === 'sign in' && (
            <div className={buttonResetPassword}>
              <Link to='/reset-password-email'>Reset Password</Link>
            </div>
          )}

          <Button
            disabled={
              authState.loading
                ? true
                : authState.error
                ? true
                : passwordError.errorPass1
                ? true
                : passwordError.errorPass2
                ? true
                : false
            }
            className={buttonLogin}
            variant='contained'
            color='primary'
            type='submit'
          >
            {authState.loading ? (
              <CircularProgress color='secondary' />
            ) : (
              buttonText
            )}
          </Button>
        </form>
      </Paper>
      <Snackbar
        open={authState.error || authState.emailSent}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={authState.error ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {authState.errorMessage
            ? authState.errorMessage
            : authState.emailSentMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={authState.error || authState.resetPassword}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={authState.error ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {authState.errorMessage
            ? authState.errorMessage
            : 'Password reset successfull!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignIn;
