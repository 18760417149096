import { Drawer, List, ListItem, Skeleton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React from 'react';

import appMenuStyles from '../appMenuStyles';

const useStyles = appMenuStyles;
const AppMenuSkeleton: React.FC = () => {
  const styles = useStyles();

  return (
    <Drawer
      variant='permanent'
      className={`${styles.drawer} ${styles.drawerClose}`}
      classes={{
        paper: `${styles.drawer} ${styles.drawerClose}`,
      }}
    >
      <div className={styles.drawerMenuClose}>
        <div className={styles.drawerMenuInner}>
          <Menu
            style={{
              marginLeft: '1.5625rem',
              marginTop: '2.8125rem',
              color: 'black',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>

      <div className={styles.iconsWrapper}>
        <List>
          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>
          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>

          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>
          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>
          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>
          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>
        </List>
      </div>
      <div className={styles.userWrapper}>
        <List>
          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>
          <ListItem className={styles.menuItem}>
            <Skeleton
              style={{
                width: '120px',
                backgroundColor: 'rgba(255, 255, 255, 0.11)',
              }}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default AppMenuSkeleton;
