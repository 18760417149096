import { combineReducers } from 'redux';
import authReducer from '../Auth/authReducer';
import accountReducer from '../Accounts/accountsReducers';
import geoReducer from '../Geos/geosReducers'
import categoryReducer from '../Categories/categoriesReducers';
import strategyReducer from '../Strategies/strategiesReducers';
import userReducer from '../Users/usersReducers';
import campaignReducer from '../Campaigns/campaignsReducers';
const allReducers = combineReducers({ auth: authReducer,accounts:accountReducer, geos:geoReducer,categories:categoryReducer,strategies:strategyReducer,users:userReducer,campaigns:campaignReducer });
export default allReducers;
