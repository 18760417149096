import { ReactNode } from 'react';
import {Route,Redirect} from 'react-router-dom';
import { AuthState } from '../Auth/authTypes';


interface Props{
    path:string;
    authState:AuthState;
    children:ReactNode;
}
export default function PublicRoute(props:Props){
  
    let component= props.children;
    if(props.authState.currentUser?.token){
        component=<Redirect to='/dashboard/accounts' />
    }
    return(
        <Route
        exact
        path={props.path}
        >
            {component}
        </Route>
    )
}