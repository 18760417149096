import React, { ChangeEvent, useState, FocusEvent, FormEvent } from 'react';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createCategoriesStyles from './createCategoryStyles';
import { CategoryCreate } from '../categoriesTypes';
import { categoryCreateErrorClear, createCategory } from '../categoriesActions';
import { Close } from '@material-ui/icons';

const useStyles = createCategoriesStyles;

const CreateCategory: React.FC = () => {
  const authState = useSelector((state: AppState) => state.auth);
  const categoryState = useSelector((state: AppState) => state.categories);
  const dispatch = useDispatch();
  const styles = useStyles();

  const [categoryName, setCategoryName] = useState({ value: '', error: false });
  const onChangeCategoryName = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryName({ value: event.target.value, error: false });
  };
  const onBlurCatName = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setCategoryName((prev) => ({ ...prev, error: true }));
    }
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    const categoryObject: CategoryCreate = {
      catName: categoryName.value,
    };
    dispatch(createCategory(authState.currentUser!.token, categoryObject));
  };

  const error = categoryName.error ? true : false;
  const onClearError = () => {
    dispatch(categoryCreateErrorClear());
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Create new Category
        </Typography>
      </div>

      <form className={styles.form} onSubmit={formSubmit}>
        <TextField
          value={categoryName.value}
          name='category-name'
          onChange={onChangeCategoryName}
          onBlur={onBlurCatName}
          error={categoryName.error}
          className={`${styles.inputs} ${styles.firstInputText}`}
          label='Category name'
          variant='standard'
          helperText={categoryName.error ? 'No less than 3 characers' : ' '}
          required
        />

        <Button
          className={styles.button}
          variant='contained'
          color='primary'
          disabled={
            error || categoryState.createLoading || categoryState.createError
          }
        >
          {categoryState.createLoading ? <CircularProgress /> : 'Submit'}
        </Button>
      </form>
      <Snackbar
        open={categoryState.createError || Boolean(categoryState.created)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={categoryState.createError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {categoryState.createError
            ? categoryState.createErrorMsg
            : 'Category created!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateCategory;
