import rolesDictionary from './rolesDictionary';


const routesPermissions={
    seeAccounts:[rolesDictionary.admin,rolesDictionary.accountManager,rolesDictionary.client,rolesDictionary.techAccountManager],
    editAccounts:[rolesDictionary.admin,rolesDictionary.accountManager,rolesDictionary.client,rolesDictionary.techAccountManager],
    createAccounts:[rolesDictionary.admin,rolesDictionary.accountManager,rolesDictionary.client,rolesDictionary.techAccountManager],
    seeCampaigns:[rolesDictionary.admin,rolesDictionary.accountManager,rolesDictionary.client,rolesDictionary.techAccountManager],
    seeUsers:[rolesDictionary.admin],
    seeCategories:[rolesDictionary.admin,rolesDictionary.techAccountManager],
    seeGeos:[rolesDictionary.admin,rolesDictionary.techAccountManager],
    seeStrategies:[rolesDictionary.admin,rolesDictionary.techAccountManager]
}
export default routesPermissions;