import { TextField } from '@material-ui/core';
import createAccountStyles from '../../createCampaignStyles';
interface Props {
  loading: boolean;
  disabled?: boolean;
  capConv: number | '';
  capClicks: number | '';
  blacklist: string;
  onChangeCapConv: (cap: string) => void;
  onChangeCapClicks: (cap: string) => void;
  onChangeBlacklist: (blacklist: string) => void;
}
const useStyles = createAccountStyles;
const TrafficCapTab = (props: Props) => {
  const styles = useStyles();
  return (
    <>
      <TextField
        fullWidth={true}
        type='number'
        name='cap_clicks'
        value={props.capClicks}
        onChange={(event) => props.onChangeCapClicks(event.target.value)}
        className={styles.inputs}
        label='Clicks daily cap:'
        variant='standard'
        helperText={' '}
        disabled={props.loading || props.disabled}
      />
      <TextField
        fullWidth={true}
        type='number'
        name='cap_conversions'
        value={props.capConv}
        onChange={(event) => props.onChangeCapConv(event.target.value)}
        className={styles.inputs}
        label='Conversion daily cap:'
        variant='standard'
        helperText={' '}
        disabled={props.loading || props.disabled}
      />
      {!props.disabled && (
        <TextField
          className={styles.blacklistInput}
          multiline
          fullWidth={true}
          label='Blacklist utms'
          variant='filled'
          rows={5}
          placeholder='Separate words by commas EX: google, facebook, ...'
          value={props.blacklist}
          onChange={(event) => props.onChangeBlacklist(event.target.value)}
          disabled={props.loading || props.disabled}
        />
      )}
    </>
  );
};

export default TrafficCapTab;
