import { makeStyles, Theme } from "@material-ui/core";


const selectStatusStyles= makeStyles((theme: Theme) => ({
    select: {
        width: '100px',
        padding: '0',
        backgroundColor:'#A9ACB9;',
        margin:0,
        marginRight:'10px',
        '& .MuiInputBase-formControl': {
            margin:0,
            height:'2.475rem'
            
        },
        '& .MuiAutocomplete-inputRoot':{
            padding:0
        },
        
        '& .MuiInputLabel-shrink':{
            transform:'translate(5px, 12px) scale(1)',
            color:'white'
            
        }
        
    },
    label:{
        transform:'translate(5px, 12px) scale(1)',
    }
}));

export default selectStatusStyles;