import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#F25050', light: '#FFE7E7', dark: '#a93838' },
    secondary: { main: '#4E5BA6', light: '#848FCE', dark: '#363f74' },
    success: { main: '#4ECB22' },
    error: { main: '#EE1B1B' },
    background: { default: '#fff' },
    text: { primary: '#575965',secondary:'#606060' },
    
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          paddingRight:'6.25rem',
          paddingLeft:'1.9375rem',
          height: '6.25rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        colorPrimary:{
          backgroundColor: 'rgba(242, 242, 242, 0.4)',
          color:'#575965'
        }
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiMenu: {
      defaultProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: '2.50rem',
          fontWeight:'bold'
        },
        h3: {
          fontSize: '1.5rem',
        },
        paragraph:{
          fontSize: '0.75rem'
        }
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'white',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          color: 'black',
        },
        root: {
          color: 'white',
          '&$focused': {
            color: '#4E5BA6',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$focused': {
            borderColor: '#4E5BA6',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4E5BA6',
            },
          },
          marginTop: '10px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          borderBottom: '1px solid #4E5BA6',
        },
        selectIcon: {
          color: '#4E5BA6',
        },
      },
    },
  },
});

export default theme;
