import { Chip, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ArrowDropDown } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import campaignFiltersStyles from './campaignFiltersStyles';
import { GeosApi } from '../../Geos/geosTypes';
import { FiltersApartFromGeo } from '../campaignsTypes';
import {
  campaignAccountsFilterChange,
  campaignCategoriesFilterChange,
  campaignDateFilterChange,
  campaignGeosFilterChange,
  campaignsStatusFilterChange,
  campaignStrategiesFilterChange,
} from '../campaignsActions';

interface Props {
  dateFrom: string;
  dateTo: string;
  loading: boolean;
  activeFilter: 0 | 1 | '';
  geosFilter: GeosApi[];
  accountsFilter: FiltersApartFromGeo[];
  strategiesFilter: FiltersApartFromGeo[];
  categoriesFilter: FiltersApartFromGeo[];
}

const useStyles = campaignFiltersStyles;
const CampaignFilters: React.FC<Props> = (props) => {
  //Styles
  const styles = useStyles();
  //Dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Store the date range from the input when the user selects in the calendar..
  const [dates, setDates] = useState<Date[]>([
    new Date(props.dateFrom),
    new Date(props.dateTo),
  ]);
  //Handels change of dates of the calendar.
  const changeDate = (value: Date[]) => {
    setDates(value);
  };
  //When the user close the calendar it will dispatch to change redux dates.
  const onCloseCalendar = () => {
    dispatch(campaignDateFilterChange(dates));
  };

  //Handles delete of the status chips and dispatch action to update redux status.
  const onDeleteChipStatus = () => {
    dispatch(campaignsStatusFilterChange(''));
  };

  //Handles delete of the geos chips and dispatch action to update redux status.
  const onDeleteChipGeos = (chipToDelete: any) => {
    const modifiedGeos = [...props.geosFilter].filter(
      (geo) => geo.idd !== chipToDelete
    );
    dispatch(campaignGeosFilterChange(modifiedGeos));
  };

  //Handles delete of the categories chips and dispatch action to update redux status.
  const onDeleteChipCategories = (chipToDelete: any) => {
    const modifiedCats = [...props.categoriesFilter].filter(
      (cat) => cat.id !== chipToDelete
    );

    dispatch(campaignCategoriesFilterChange(modifiedCats));
  };

  //Handles delete of the accounts chips and dispatch action to update redux status.
  const onDeleteChipAccount = (chipToDelete: any) => {
    const modifiedAccounts = [...props.accountsFilter].filter(
      (account) => account.id !== chipToDelete
    );
    dispatch(campaignAccountsFilterChange(modifiedAccounts));
  };

  //Handles delete of the strategis chips and dispatch action to update redux status.
  const onDeleteChipStrategies = (chipToDelete: any) => {
    const modifiedStrategies = [...props.strategiesFilter].filter(
      (strat) => strat.id !== chipToDelete
    );
    dispatch(campaignStrategiesFilterChange(modifiedStrategies));
  };

  return (
    <div className={styles.container}>
      <Paper component='div' elevation={0} className={styles.chipContainer}>
        {props.activeFilter !== '' && (
          <Chip
            className={`${styles.chip} ${
              props.activeFilter === 0
                ? styles.statusChipOff
                : styles.statusChipOn
            }`}
            label={props.activeFilter === 0 ? 'Off' : 'On'}
            onDelete={onDeleteChipStatus}
            disabled={props.loading}
          />
        )}
        {props.categoriesFilter.map((cat) => (
          <Chip
            className={styles.chip}
            variant='outlined'
            color='primary'
            key={cat.id}
            label={cat.name}
            onDelete={() => onDeleteChipCategories(cat.id)}
            disabled={props.loading}
          />
        ))}
        {props.geosFilter.map((geo) => (
          <Chip
            className={styles.chip}
            key={geo.idd}
            label={geo.name}
            onDelete={() => onDeleteChipGeos(geo.idd)}
            disabled={props.loading}
          />
        ))}
        {props.accountsFilter.map((acc) => (
          <Chip
            className={styles.chip}
            variant='outlined'
            key={acc.id}
            label={acc.name}
            onDelete={() => onDeleteChipAccount(acc.id)}
            disabled={props.loading}
          />
        ))}
        {props.strategiesFilter.map((strat) => (
          <Chip
            className={styles.chip}
            variant='outlined'
            color='secondary'
            key={strat.id}
            label={strat.name}
            onDelete={() => onDeleteChipStrategies(strat.id)}
            disabled={props.loading}
          />
        ))}
      </Paper>
      <DateRangePicker
        showDoubleView={true}
        className={styles.datPicker}
        minDetail='year'
        rangeDivider='to'
        format='dd/MM/yyyy'
        calendarIcon={<ArrowDropDown />}
        clearIcon={null}
        value={dates}
        onChange={changeDate}
        disabled={props.loading}
        closeCalendar={true}
        onCalendarClose={onCloseCalendar}
      />
    </div>
  );
};

export default CampaignFilters;
