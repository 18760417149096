import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import {
  Dashboard,
  Assessment,
  Public,
  People,
  Category,
  TrackChanges,
  ExitToApp,
  AccountCircle,
  Menu,
  ArrowBackIos,
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import rolesDictionary from '../permissions/rolesDictionary';
import { logout } from '../../Auth/authActions';
import appMenuStyles from './appMenuStyles';
import ModalContainer from '../Modal/ModalContainer';
import Profile from '../../Auth/Profile/Profile';

const useStyles = appMenuStyles;

const AppMenu: React.FC = () => {
  const styles = useStyles();
  const authState = useSelector((state: AppState) => state.auth);
  const dipatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);

  const setOpenToProfileModal = () => {
    setOpenProfileModal(true);
  };
  const setCloseToProfileModal = () => {
    setOpenProfileModal(false);
  };

  const closeDrawer = () => {
    setOpen(false);
  };
  const openDrawer = () => {
    setOpen(true);
  };
  const logOut = () => {
    dipatch(logout());
  };
  return (
    <Drawer
      variant='permanent'
      className={`${styles.drawer} ${
        open ? styles.drawerOpen : styles.drawerClose
      }`}
      classes={{
        paper: `${styles.drawer} ${
          open ? styles.drawerOpen : styles.drawerClose
        }`,
      }}
    >
      <div className={open ? styles.drawerMenuOpen : styles.drawerMenuClose}>
        <div
          className={styles.drawerMenuInner}
          style={{
            backgroundColor: `${
              open ? 'transparent' : 'rgba(242, 242, 242, 0.4)'
            }`,
          }}
        >
          {open ? (
            <ArrowBackIos
              onClick={closeDrawer}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginTop: '2.8125rem',
                cursor: 'pointer',
              }}
            />
          ) : (
            <Menu
              onClick={openDrawer}
              style={{
                marginLeft: '1.5625rem',
                marginTop: '2.8125rem',
                color: 'black',
                cursor: 'pointer',
              }}
            />
          )}
        </div>
      </div>

      <div className={styles.iconsWrapper}>
        <List>
          <ListItem className={styles.menuItem}>
            <NavLink
              className={styles.menuLink}
              activeClassName={styles.menuLinkActive}
              to='/dashboard/accounts'
            >
              <>
                <ListItemIcon className={styles.menuItemIcon}>
                  <Dashboard />
                </ListItemIcon>

                <ListItemText primary='Accounts' />
              </>
            </NavLink>
          </ListItem>
          <ListItem className={styles.menuItem}>
            <NavLink
              className={styles.menuLink}
              activeClassName={styles.menuLinkActive}
              to='/dashboard/campaigns'
            >
              <>
                <ListItemIcon className={styles.menuItemIcon}>
                  <Assessment />
                </ListItemIcon>

                <ListItemText primary='Campaigns' />
              </>
            </NavLink>
          </ListItem>
          {authState.currentUser?.role.id === rolesDictionary.admin && (
            <>
              <ListItem className={styles.menuItem}>
                <NavLink
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                  to='/dashboard/geos'
                >
                  <>
                    <ListItemIcon className={styles.menuItemIcon}>
                      <Public />
                    </ListItemIcon>

                    <ListItemText primary='Geos' />
                  </>
                </NavLink>
              </ListItem>
              <ListItem className={styles.menuItem}>
                <NavLink
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                  to='/dashboard/users'
                >
                  <>
                    <ListItemIcon className={styles.menuItemIcon}>
                      <People />
                    </ListItemIcon>

                    <ListItemText primary='Users' />
                  </>
                </NavLink>
              </ListItem>
              <ListItem className={styles.menuItem}>
                <NavLink
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                  to='/dashboard/categories'
                >
                  <>
                    <ListItemIcon className={styles.menuItemIcon}>
                      <Category />
                    </ListItemIcon>

                    <ListItemText primary='Categories' />
                  </>
                </NavLink>
              </ListItem>
              <ListItem className={styles.menuItem}>
                <NavLink
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                  to='/dashboard/strategies'
                >
                  <>
                    <ListItemIcon className={styles.menuItemIcon}>
                      <TrackChanges />
                    </ListItemIcon>

                    <ListItemText primary='Strategies' />
                  </>
                </NavLink>
              </ListItem>
            </>
          )}
        </List>
      </div>
      <div className={styles.userWrapper}>
        <List>
          <ListItem className={styles.menuItem}>
            <div className={styles.menuLink}>
              <Button
                disableRipple={true}
                className={styles.buttons}
                onClick={setOpenToProfileModal}
              >
                <ListItemIcon className={styles.menuItemIcon}>
                  <AccountCircle />
                </ListItemIcon>

                <ListItemText primary='My profile' />
              </Button>
            </div>
          </ListItem>
          <ListItem className={styles.menuItem}>
            <div className={styles.menuLink}>
              <Button
                className={styles.buttons}
                disableRipple={true}
                onClick={logOut}
              >
                <ListItemIcon className={styles.menuItemIcon}>
                  <ExitToApp />
                </ListItemIcon>

                <ListItemText primary='Log out' />
              </Button>
            </div>
          </ListItem>
        </List>
      </div>
      <ModalContainer open={openProfileModal} close={setCloseToProfileModal}>
        {<Profile closeModal={setCloseToProfileModal} />}
      </ModalContainer>
    </Drawer>
  );
};

export default AppMenu;
