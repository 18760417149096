import React, { useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import SingleCampFilter from './SingleCampFilters/SingleCampFilters';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import {
  campaignErrorClear,
  getSingleEventCampaign,
} from '../campaignsActions';
import AppContainer from '../../shared/AppContainer/AppContainer';
import AppHeader from '../../shared/AppHeader/AppHeader';

import PageTitleNoButton from './PageTitleNoButton/PageTitleNoButton';

import { DateOptions } from '../campaignsTypes';

import SingleCampaignTable from './SingleCampaignTable/SingleCampaignTable';

const CampaignSingleEventTable: React.FC = () => {
  //The authenticated user state from the reducer.
  const authState = useSelector((state: AppState) => state.auth);
  //Get the state of the campaigns from the reducer.
  const campaignState = useSelector((state: AppState) => state.campaigns);
  //Get the id from the url, had to set ignore because typescript was not recognizing it.

  const { campaignId } = useParams<{ campaignId: string }>();
  //Get the styles.
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();

  //gets today date.
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  //Store the date range from the input when the user selects in the calendar..
  const [dates, setDates] = useState<Date[]>([
    new Date(year, month, 1),
    new Date(year, month + 1, 0),
  ]);
  //Handels change of dates of the calendar.
  const changeDate = (value: Date[]) => {
    setDates(value);
  };
  //Store the dates when the user closes the calendar, I did this to avoid dispatch of the useEffect when user clicks and changes dates also did this to not store it on the reducer.
  const [finalDates, setFinalDates] = useState<Date[]>([
    new Date(year, month, 1),
    new Date(year, month + 1, 0),
  ]);
  //When the user close the calendar it will set the selected dates.
  const onCloseCalendar = () => {
    const datesSelected = [...dates];
    setFinalDates(datesSelected);
  };
  //Get the campaign with the selected filters.
  useEffect(() => {
    const dateOptions: DateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    dispatch(
      getSingleEventCampaign(
        authState.currentUser!.token,
        parseInt(campaignId),
        campaignState.singleCampaignEventPage,
        campaignState.singleCampaignEventLimit,
        'Date',
        'asc',
        finalDates[0].toLocaleDateString('ja-JP', dateOptions),
        finalDates[1].toLocaleDateString('ja-JP', dateOptions)
      )
    );
  }, [
    authState.currentUser,
    dispatch,
    campaignId,
    finalDates,
    campaignState.singleCampaignEventLimit,
    campaignState.singleCampaignEventPage,
  ]);

  //clears error message or success message.
  const onClearError = () => {
    dispatch(campaignErrorClear());
  };

  return (
    <AppContainer>
      <AppHeader />
      <main>
        <PageTitleNoButton
          title={campaignState.singleCampaignEvent?.campaignName}
          loading={campaignState.singleCampaignEventLoading}
        />
        <SingleCampFilter
          dates={dates}
          loading={campaignState.singleCampaignEventLoading}
          onChangeDates={changeDate}
          onCloseCalendar={onCloseCalendar}
        />
        <SingleCampaignTable
          amount={campaignState.singleCampaignEventAmount}
          campaignEvents={campaignState.singleCampaignEvent}
          error={campaignState.singleCampaignError}
          limit={campaignState.singleCampaignEventLimit}
          loading={campaignState.singleCampaignEventLoading}
          page={campaignState.singleCampaignEventPage}
        />
        <Snackbar
          open={campaignState.error}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClose={onClearError}
        >
          <Alert
            severity='error'
            role='alert'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={onClearError}
              >
                <Close />{' '}
              </IconButton>
            }
            variant='filled'
          >
            {campaignState.errorMessage}
          </Alert>
        </Snackbar>
      </main>
    </AppContainer>
  );
};

export default CampaignSingleEventTable;
