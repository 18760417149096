import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './theme/theme';
import { AppState } from './store/storeTypes';
import PublicRoutes from './Routes/PublicRoutes';
import PrivateRoutes from './Routes/PrivateRoutes';
import { publicRoutes, privateRoutes } from './Routes/routes';

const App: React.FC = () => {
  const authState = useSelector((state: AppState) => state.auth);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          {publicRoutes.map((publicRoute) => (
            <PublicRoutes
              key={publicRoute.link}
              authState={authState}
              path={publicRoute.link}
            >
              {publicRoute.component}
            </PublicRoutes>
          ))}
          {privateRoutes.map((privateRoute) => (
            <PrivateRoutes
              key={privateRoute.link}
              authState={authState}
              path={privateRoute.link}
              permission={privateRoute.permission}
            >
              {privateRoute.component}{' '}
            </PrivateRoutes>
          ))}
          <Redirect
            from='*'
            to={
              authState.currentUser?.token ? '/dashboard/accounts' : '/signIn'
            }
          />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
