import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './../store/storeTypes';
import { campaignErrorClear, getCampaigns } from './campaignsActions';
import CampaignsFilters from './CampaignsFilters/CampaignsFilters';
import EditCampaign from './EditCampaign/EditCampaign';
import ModalContainer from './../shared/Modal/ModalContainer';
import CreateCampaign from './CreateCampaign/CreateCampaign';
import PageTitle from '../shared/PageTitle/PageTitle';
import AppContainer from '../shared/AppContainer/AppContainer';
import CampaignHeader from './CampaignHeader/CampaignHeader';
import CampaignTable from './CampaignsTable/CampaignsTable';

const CampaignsPage: React.FC = () => {
  //AuthState to get the current user token from redux.
  const authState = useSelector((state: AppState) => state.auth);
  //Get the campaign state from redux.
  const campaignState = useSelector((state: AppState) => state.campaigns);

  //Dispatch hook to dispatch actions.
  const dispatch = useDispatch();

  //Search for the campaigns.
  useEffect(() => {
    if (!campaignState.searching) {
      dispatch(
        getCampaigns(
          authState.currentUser!.token,
          campaignState.page,
          campaignState.limit,
          campaignState.orderBy,
          campaignState.orderDirection,
          campaignState.dateFrom,
          campaignState.dateTo,
          campaignState.search,
          campaignState.active,
          campaignState.categories,
          campaignState.geos,
          campaignState.accounts,
          campaignState.strategies
        )
      );
    }
  }, [
    authState.currentUser,
    dispatch,
    campaignState.search,
    campaignState.page,
    campaignState.searching,
    campaignState.limit,
    campaignState.active,
    campaignState.accounts,
    campaignState.categories,
    campaignState.dateFrom,
    campaignState.dateTo,
    campaignState.geos,
    campaignState.strategies,
    campaignState.orderBy,
    campaignState.orderDirection,
  ]);

  //State to manage the opening of the table menu.
  const [openTableMenu, setOpenTableMenu] = useState<{
    id: number;
    element: null | HTMLInputElement;
  }>({
    id: 0,
    element: null,
  });
  //When a checkbox is checked it opens the menu.
  const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setOpenTableMenu({
      element: event.target,
      id: parseInt(event.target.value),
    });
  };
  //When a click is registered outside the menu it will close it and remove the element id.
  const onCloseTableMenu = () => {
    setOpenTableMenu({ id: 0, element: null });
  };

  //Clears error or message pop up
  const onClearError = () => {
    dispatch(campaignErrorClear());
  };
  //Handles the state of the edit modal
  const [openEditModal, setOpenEditModal] = useState(false);
  //Opens the edit modal.
  const setOpenToEditModal = () => {
    setOpenEditModal(true);
  };
  //Closes the edit modal.
  const setCloseToEditModal = () => {
    setOpenEditModal(false);
  };
  //Handles the state of the create modal
  const [createOpen, setCreateOpen] = useState(false);
  const openCreateModal = () => {
    setCreateOpen(true);
  };
  const closeCreateModal = () => {
    setCreateOpen(false);
  };

  return (
    <AppContainer>
      <>
        <CampaignHeader
          categoriesFilter={campaignState.categories}
          accountsFilter={campaignState.accounts}
          activeFilter={campaignState.active}
          geosFilter={campaignState.geos}
          strategiesFilter={campaignState.strategies}
          loading={campaignState.loading}
          roleId={authState.currentUser!.role.id}
          search={campaignState.search}
          userToken={authState.currentUser!.token}
        />

        <main>
          <PageTitle
            buttonText='Create Campaign'
            openCreate={openCreateModal}
            title='Campaigns'
            userRoleId={authState.currentUser!.role.id}
          />
          <CampaignsFilters
            categoriesFilter={campaignState.categories}
            accountsFilter={campaignState.accounts}
            activeFilter={campaignState.active}
            geosFilter={campaignState.geos}
            strategiesFilter={campaignState.strategies}
            loading={campaignState.loading}
            dateFrom={campaignState.dateFrom}
            dateTo={campaignState.dateTo}
          />

          <CampaignTable
            onChangeCheckbox={onChangeCheckBox}
            onCloseTableMenu={onCloseTableMenu}
            openTableMenu={openTableMenu}
            setOpenEditModal={setOpenToEditModal}
          />

          <Snackbar
            open={campaignState.error}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={onClearError}
          >
            <Alert
              severity='error'
              role='alert'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={onClearError}
                >
                  <Close />{' '}
                </IconButton>
              }
              variant='filled'
            >
              {campaignState.errorMessage}
            </Alert>
          </Snackbar>

          <ModalContainer open={openEditModal} close={setCloseToEditModal}>
            {
              <EditCampaign
                closeModal={setCloseToEditModal}
                closeMenu={onCloseTableMenu}
                campaignId={openTableMenu.id}
              />
            }
          </ModalContainer>
          <ModalContainer open={createOpen} close={closeCreateModal}>
            <CreateCampaign />
          </ModalContainer>
        </main>
      </>
    </AppContainer>
  );
};

export default CampaignsPage;
