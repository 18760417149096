import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/storeTypes';
import selectStatusStyles from './selectStatusStyles';

const useStyle = selectStatusStyles;
interface Props {
  value: any;
  onChange: (
    event: ChangeEvent<{ name?: string | undefined; value: number }>
  ) => void;
  onBlur?: () => void;
}
const SelectStatusFilter: React.FC<Props> = (props) => {
  const style = useStyle();
  const campaignState = useSelector((state: AppState) => state.campaigns);

  return (
    <FormControl className={style.select} disabled={campaignState.loading}>
      <InputLabel className={style.label}>Status</InputLabel>
      <Select
        value={props.value}
        onChange={props.onChange}
        variant='outlined'
        renderValue={() => ''}
        onBlur={props.onBlur}
      >
        <MenuItem value=''></MenuItem>
        <MenuItem value={0}>Off</MenuItem>
        <MenuItem value={1}>On</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectStatusFilter;
