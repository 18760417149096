import { CampaignCreateObject } from '../campaignsTypes';

export const isCampaignNameValid = (campaignName: string) =>
  campaignName.length > 3;

export const isURLValid = (url: string) => {
  try {
    const urlFormated = new URL(url);
    if (urlFormated.protocol !== 'https:') {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
};

export const createCampaignFormIsValid = (
  campaignObject: CampaignCreateObject
) => {
  const errors: string[] = [];
  if (!isCampaignNameValid(campaignObject.campaignName)) {
    errors.push('- Campaign name to small in Campaign information');
  }
  if (!campaignObject.accountId) {
    errors.push('\n- Account can not  be empty in Campaign information');
  }
  if (!isURLValid(campaignObject.domain)) {
    errors.push('\n- Set a correct url in Campaign information');
  }
  if (campaignObject.currency.length < 2) {
    errors.push('\n- Select a currency in Campaign information');
  }
  if (!campaignObject.campaignsCategories) {
    errors.push('\n- Select at least one category in Campaign');
  }
  if (!isURLValid(campaignObject.tracking_url)) {
    errors.push('\n- Set a correct tracking Url in Traffic');
  }
  if (!campaignObject.startDate) {
    errors.push('\n- Set a start date in  Traffic');
  }

  if (!campaignObject.strategy) {
    errors.push('\n- Strategy cant be empty in Traffic');
  }

  if (!campaignObject.campaignsGeos) {
    errors.push('\n- Select at least one geo in Traffic');
  }
  return errors;
};
