import * as geoTypes from './geosTypes';
import * as geoActions from './geosActions';
const initialSingleGeo: geoTypes.GeosApi = { code: '', id: 0, idd: 0, name: '' };
const initialState: geoTypes.GeosState = {
  geos: null,
  page: 0,
  limit: 10,
  amount: 0,
  search: '',
  searching: false,
  loading: false,
  error: false,
  errorMessage: null,
  singleGeo: initialSingleGeo,
  singleGeoError: false,
  singleGeoErrorMsg: null,
  singleGeoLoading: false,
  createError: false,
  createErrorMsg: null,
  createLoading: false,
  created: null,
  editError: false,
  editErrorMessage: null,
  editGeoInfo: null,
  editLoading: false,
  deleteGeoLoading: false,
  deletedGeo: null,
  deleteGeoError: false,
  deleteGeoErroMsg: null,
};

const geosGetStart = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    loading: true,
  };
};

const geosGetSuccess = (state: geoTypes.GeosState, action: geoTypes.GetGeoSucces): geoTypes.GeosState => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
    geos: action.payload.geos,
    amount: action.payload.amount,
    searching: false,
  };
};
const geosGetFail = (state: geoTypes.GeosState, action: geoTypes.GetGeoFail): geoTypes.GeosState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};

const geosSearch = (state: geoTypes.GeosState, action: geoTypes.GeosSearch): geoTypes.GeosState => {
  return {
    ...state,
    search: action.payload.search,
    searching: action.payload.searching,
    error: false,
    errorMessage: null,
    loading: false,
    page:0,
  };
};
const geosChangePage = (state: geoTypes.GeosState, action: geoTypes.GeosChangePage): geoTypes.GeosState => {
  return {
    ...state,
    page: action.payload.page,
  };
};

const geosLimitChange = (state: geoTypes.GeosState, action: geoTypes.GeosLimitChage): geoTypes.GeosState => {
  return {
    ...state,
    limit: action.payload.limit,
  };
};

const geosErrorClear = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    error: false,
    errorMessage: null,
  };
};

const geoCreateStart = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    createLoading: true,
  };
};
const geoCreateFail = (state: geoTypes.GeosState, action: geoTypes.GeoCreateFail): geoTypes.GeosState => {
  return {
    ...state,
    createLoading: false,
    createError: action.payload.error,
    createErrorMsg: action.payload.errorMessage,
  };
};

const geoCreateSuccess = (state: geoTypes.GeosState, action: geoTypes.GeoCreateSuccess): geoTypes.GeosState => {
  return {
    ...state,
    created: action.payload,
    createLoading: false,
    createError: false,
    createErrorMsg: null,
  };
};

const geoCreateErrorClear = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    createError: false,
    createErrorMsg: null,
    created: null,
  };
};

const singleGeoGetStart = (state: geoTypes.GeosState): geoTypes.GeosState => {
    return {
      ...state,
      singleGeoLoading: true,
    };
  };
  const singleGeoGetSuccess = (
    state: geoTypes.GeosState,
    action: geoTypes.SingleGeoGetSuccess
  ): geoTypes.GeosState => {
    return {
      ...state,
      singleGeo: action.payload,
      singleGeoLoading: false,
      singleGeoError: false,
    };
  };
  const singleGeoGetFail = (
    state: geoTypes.GeosState,
    action: geoTypes.SingleGeoGetFail
  ): geoTypes.GeosState => {
    return {
      ...state,
      singleGeoError: action.payload.error,
      singleGeoErrorMsg: action.payload.errorMessage,
      singleGeo: initialSingleGeo,
    };
  };
  const singleGeoErrorClear = (state: geoTypes.GeosState): geoTypes.GeosState => {
    return {
      ...state,
      singleGeoError: false,
      singleGeoErrorMsg: null,
    };
  };

const geoEditStart = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    editLoading: true,
  };
};
const geoEditSuccess = (state: geoTypes.GeosState, action: geoTypes.GeoEditSuccess): geoTypes.GeosState => {
  return {
    ...state,
    editGeoInfo: action.payload,
    editError: false,
    editErrorMessage: null,
    editLoading: false,
  };
};

const geoEditFail = (state: geoTypes.GeosState, action: geoTypes.GeoEditFail): geoTypes.GeosState => {
  return {
    ...state,
    editGeoInfo: null,
    editError: action.payload.error,
    editErrorMessage: action.payload.errorMessage,
    editLoading: false,
  };
};
const geoEditErrorClear = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    editError: false,
    editErrorMessage: null,
    editGeoInfo: null,
  };
};

const geoDeleteStart = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    deleteGeoLoading: true,
  };
};
const geoDeleteFail = (state: geoTypes.GeosState, action: geoTypes.GeoDeleteFail): geoTypes.GeosState => {
  return {
    ...state,
    deleteGeoLoading: false,
    deleteGeoError: action.payload.error,
    deleteGeoErroMsg: action.payload.errorMessage,
  };
};

const accountDeleteSuccess = (state: geoTypes.GeosState, action: geoTypes.GeoDeleteSuccess): geoTypes.GeosState => {
  return {
    ...state,
    deletedGeo: action.payload,
    deleteGeoLoading: false,
    deleteGeoError: false,
    deleteGeoErroMsg: null,
  };
};
const accountDeleteClearError = (state: geoTypes.GeosState): geoTypes.GeosState => {
  return {
    ...state,
    deleteGeoError: false,
    deleteGeoErroMsg: null,
    deletedGeo: null,
  };
};

const reducer = (state: geoTypes.GeosState = initialState, action: geoTypes.GeoActionsTypes) => {
  switch (action.type) {
    case geoActions.GEOS_GET_START:
      return geosGetStart(state);
    case geoActions.GEOS_GET_SUCCESS:
      return geosGetSuccess(state, action);
    case geoActions.GEOS_GET_FAIL:
      return geosGetFail(state, action);
    case geoActions.GEOS_SEARCH:
      return geosSearch(state, action);
    case geoActions.GEOS_CHANGE_PAGE:
      return geosChangePage(state, action);
    case geoActions.GEOS_LIMIT_CHANGE:
      return geosLimitChange(state, action);
    case geoActions.GEOS_ERROR_CLEAR:
      return geosErrorClear(state);
    case geoActions.GEO_CREATE_START:
      return geoCreateStart(state);
    case geoActions.GEO_CREATE_SUCCESS:
      return geoCreateSuccess(state, action);
    case geoActions.GEO_CREATE_FAIL:
      return geoCreateFail(state, action);
    case geoActions.GEO_CREATE_CLEAR_ERROR:
      return geoCreateErrorClear(state);
    case geoActions.SINGLE_GEO_GET_START:
      return singleGeoGetStart(state);
    case geoActions.SINGLE_GEO_GET_SUCCESS:
      return singleGeoGetSuccess(state, action);
    case geoActions.SINGLE_GEO_GET_FAIL:
      return singleGeoGetFail(state, action);
    case geoActions.SINGLE_GEO_ERROR_CLEAR:
        return singleGeoErrorClear(state)
    case geoActions.GEO_EDIT_START:
      return geoEditStart(state);
    case geoActions.GEO_EDIT_SUCCESS:
      return geoEditSuccess(state, action);
    case geoActions.GEO_EDIT_FAIL:
      return geoEditFail(state, action);
    case geoActions.GEO_EDIT_ERROR_CLEAR:
      return geoEditErrorClear(state);
    case geoActions.GEO_DELETE_START:
      return geoDeleteStart(state);
    case geoActions.GEO_DELETE_FAIL:
      return geoDeleteFail(state, action);
    case geoActions.GEO_DELETE_SUCCESS:
      return accountDeleteSuccess(state, action);
    case geoActions.GEO_DELETE_ERROR_CLEAR:
      return accountDeleteClearError(state);
    default:
      return state;
  }
};

export default reducer;
