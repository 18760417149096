import Axios from 'axios';
import { CurrentUser, ReponseMe, SignInReponse } from './authTypes';

export const getMeApi = async (token: string) => {
  const { data } = await Axios.get<ReponseMe>('/api/users/me', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

export const signInApi = async (email: string, password: string) => {
  const { data } = await Axios.post<SignInReponse>('/api/users/login', { email, password });
  return data.data;
};

export const resetPasswordEmailApi = async (email: string) => {
  const { status } = await Axios.post('/api/users/reset-password-email', { email });
  return status;
};

export const resetPasswordApi = async (email: string, newPassword: string,repeatNewPassword:string, token: string) => {
  const { status } = await Axios.post('/api/users/reset-password', { email, newPassword,repeatNewPassword, token });
  return status;
};
export const checkTokenUser= async(id:string,token:string)=>{
  const {status}=await Axios.get(`/api/users/password-reset-email/${id}/${token}`)
  return status;
}

//Edit User
export const editUserProfileApi=async(token:string,body:FormData)=>{
  const {data}= await Axios.patch<{message:string,data:{user:CurrentUser}}>(`/api/users/profile`,body,{
    headers: { 'Accept':'application/json','Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    });
  return data.data
}