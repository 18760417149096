import errorMessageBuilder from '../shared/errorMessageBuilder/errorMessageBuilder';
import { AppActions, AppState, ThunkDispatchType } from '../store/storeTypes';
import { createCategoryApi, getTableCategoriesApi,getOneCategoryApi, editCategoryApi, deleteCategoryApi } from './categoriesApi';
import * as catsTypes from './categoriesTypes';
export const CATEGORIES_GET_START = 'CATEGORIES_GET_START';
export const CATEGORIES_GET_FAIL = 'CATEGORIES_GET_FAIL';
export const CATEGORIES_GET_SUCCESS = 'CATEGORIES_GET_SUCCESS';
export const CATEGORIES_SEARCH='CATEGORIES_SEARCH';
export const CATEGORIES_LIMIT_CHANGE='CATEGORIES_LIMIT_CHANGE';
export const CATEGORIES_CHANGE_PAGE='CATEGORIES_CHANGE_PAGE';
export const CATEGORIES_ERROR_CLEAR='CATEGORIES_ERROR_CLEAR';
export const CATEGORY_CREATE_START='CATEGORY_CREATE_START';
export const CATEGORY_CREATE_FAIL='CATEGORY_CREATE_FAIL';
export const CATEGORY_CREATE_SUCCESS='CATEGORY_CREATE_SUCCESS';
export const CATEGORY_CREATE_CLEAR_ERROR ='CATEGORY_CREATE_CLEAR_ERROR';
export const SINGLE_CATEGORY_GET_START = 'SINGLE_CATEGORY_GET_START';
export const SINGLE_CATEGORY_GET_SUCCESS = 'SINGLE_CATEGORY_GET_SUCCESS';
export const SINGLE_CATEGORY_GET_FAIL = 'SINGLE_CATEGORY_GET_FAIL';
export const SINGLE_CATEGORY_ERROR_CLEAR = 'SINGLE_CATEGORY_ERROR_CLEAR';
export const CATEGORY_EDIT_START = 'CATEGORY_EDIT_START';
export const CATEGORY_EDIT_SUCCESS = 'CATEGORY_EDIT_SUCCESS';
export const CATEGORY_EDIT_FAIL = 'CATEGORY_EDIT_FAIL';
export const CATEGORY_EDIT_ERROR_CLEAR = 'CATEGORY_EDIT_ERROR_CLEAR';
export const CATEGORY_DELETE_START = 'CATEGORY_DELETE_START';
export const CATEGORY_DELETE_SUCCESS='CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAIL='CATEGORY_DELETE_FAIL';
export const CATEGORY_DELETE_ERROR_CLEAR='CATEGORY_DELETE_ERROR_CLEAR';

export const categoriesGetStart = (): AppActions => {
  return {
    type: CATEGORIES_GET_START,
  };
};
export const categoriesGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: CATEGORIES_GET_FAIL,
    payload: { error, errorMessage },
  };
};

export const categoriesGetSuccess = (categories: catsTypes.CategoriesApi[], amount: string): AppActions => {
  return {
    type: CATEGORIES_GET_SUCCESS,
    payload: { categories: categories, amount: parseInt(amount) },
  };
};

export const categoriesSearch = (search: string, searching: boolean): AppActions => {
    return {
      type: CATEGORIES_SEARCH,
      payload: { search, searching },
    };
  };
  
  export const categoriesPageChange = (page: number): AppActions => {
    return {
      type: CATEGORIES_CHANGE_PAGE,
      payload: { page },
    };
  };
  
  export const categoriesLimitChange = (limit: number): AppActions => {
    return {
      type: CATEGORIES_LIMIT_CHANGE,
      payload: { limit },
    };
  };
  
  export const categoriesErrorClear = (): AppActions => {
    return {
      type: CATEGORIES_ERROR_CLEAR,
    };
  };

export const getCategories = (token: string, page: number, numberOffers: number, search: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
    dispatch(categoriesGetStart())
    const categories =await getTableCategoriesApi(token,page,numberOffers,search);
    dispatch(categoriesGetSuccess(categories.categories,categories.amount));
  try {
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    
    dispatch(categoriesGetFail(true, errorMessage ? errorMessage : err))
  }
};


//Create category actions;
export const categoryCreatestart = (): AppActions => {
    return {
      type: CATEGORY_CREATE_START,
    };
  };
  
  export const categoryCreateFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: CATEGORY_CREATE_FAIL,
      payload: { error, errorMessage },
    };
  };
  
  export const categoryCreateSuccess = (category: catsTypes.CategoriesApi): AppActions => {
    return {
      type: CATEGORY_CREATE_SUCCESS,
      payload:category,
    };
  };
  
  export const categoryCreateErrorClear = (): AppActions => {
    return {
      type: CATEGORY_CREATE_CLEAR_ERROR,
    };
  };
  
  export const createCategory = (token: string, category: catsTypes.CategoryCreate) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(categoryCreatestart());
       const createdCategory= await createCategoryApi(token, category);
  
      dispatch(categoryCreateSuccess(createdCategory.category));
      dispatch(getCategories(token, 0, 10, ''));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(categoryCreateFail(true, errorMessage ? errorMessage : err));
    }
  };


  //Get Single Category
export const singleCategoryGetStart = (): AppActions => {
    return {
      type: SINGLE_CATEGORY_GET_START,
    };
  };
  
  export const singleCategoryGetSuccess = (category: catsTypes.CategoriesApi): AppActions => {
    return {
      type: SINGLE_CATEGORY_GET_SUCCESS,
      payload: category,
    };
  };
  
  export const singleCategoryGetFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: SINGLE_CATEGORY_GET_FAIL,
      payload: { error, errorMessage },
    };
  };
  export const singleCategoryErrorClear = () => {
    return {
      type: SINGLE_CATEGORY_ERROR_CLEAR,
    };
  };
  
  export const getSingleCategory = (token: string, categoryId: number) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(singleCategoryGetStart());
      const category = await getOneCategoryApi(token, categoryId);
      dispatch(singleCategoryGetSuccess(category.category));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(singleCategoryGetFail(true, errorMessage ? errorMessage : err));
    }
  };

  //Edit Categories
export const editCategoryStart = ():AppActions => {
    return {
      type: CATEGORY_EDIT_START,
    };
  };
  
  export const editCategoryFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: CATEGORY_EDIT_FAIL,
      payload: { error, errorMessage },
    };
  };
  export const editCategorySuccess = (category: catsTypes.CategoriesApi): AppActions => {
    return {
      type: CATEGORY_EDIT_SUCCESS,
      payload: category,
    };
  };
  
  export const editCategoryErrorClear = (): AppActions => {
    return {
      type: CATEGORY_EDIT_ERROR_CLEAR,
    };
  };
  
  export const editCategory = (token: string, categoryInfo:catsTypes.CategoryCreate ,categoryId: number) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(editCategoryStart());
      const editCategory = await editCategoryApi(token,categoryInfo,categoryId);
      dispatch(editCategorySuccess(editCategory.category));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(editCategoryFail(true, errorMessage ? errorMessage : err));
    }
  };
  
  //Delete Categories
  
  export const deleteCategoryStart=():AppActions=>{
    return{
      type:CATEGORY_DELETE_START,
    }
  }
  
  export const deleteCategoryFail=(error: boolean, errorMessage: string):AppActions=>{
    return{
      type:CATEGORY_DELETE_FAIL,
      payload:{error,errorMessage}
    }
  }
  
  export const deleteCategorySuccess=(category:catsTypes.DeletedCategory):AppActions=>{
    return{
      type:CATEGORY_DELETE_SUCCESS,
      payload:category
    }
  }
  
  export const deleteCategoryErrorClear=():AppActions=>{
    return{
      type:CATEGORY_DELETE_ERROR_CLEAR
    }
  }
  export const deleteCategory=(token:string,categoryId:number)=>async(dispatch:ThunkDispatchType,getState:()=>AppState)=>{
    try {
      dispatch(deleteCategoryStart());
      const category = await deleteCategoryApi(token,categoryId);
      dispatch(deleteCategorySuccess(category.category));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(deleteCategoryFail(true, errorMessage ? errorMessage : err))
    }
  }