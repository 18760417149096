import {
  Checkbox,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';

const TableSkeleton: React.FC = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width='60px'>
            <Skeleton style={{ width: '60px' }} />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton>
              <Checkbox />
            </Skeleton>
          </TableCell>
          <TableCell>
            {' '}
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton>
              <Checkbox />
            </Skeleton>
          </TableCell>
          <TableCell>
            {' '}
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton>
              <Checkbox />
            </Skeleton>
          </TableCell>
          <TableCell>
            {' '}
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton>
              <Checkbox />
            </Skeleton>
          </TableCell>
          <TableCell>
            {' '}
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton>
              <Checkbox />
            </Skeleton>
          </TableCell>
          <TableCell>
            {' '}
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell colSpan={2}>
            <Skeleton style={{ marginLeft: 'auto' }} />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default TableSkeleton;
