import * as authActions from './authActions';
import * as authTypes from './authTypes';

const initialState: authTypes.AuthState = {
  currentUser: null,
  error: false,
  loading: false,
  errorMessage: null,
  emailSent: false,
  emailSentMessage: null,
  loadingEditProfile: false,
  profileUpdated:false,
  resetPassword:false,
  authInitLoading:false,
};

const authStart = (state: authTypes.AuthState):authTypes. AuthState => {
  return { ...state, loading: true };
};

const authLogOut = (state: authTypes.AuthState): authTypes.AuthState => {
  return {
    ...state,
    currentUser: null,
  };
};
const authErrorClear = (state: authTypes.AuthState): authTypes.AuthState => {
  return {
    ...state,
    currentUser: null,
    error: false,
    errorMessage: null,
    resetPassword:false,
  };
};

const authSuccess = (state: authTypes.AuthState, action: authTypes.AuthActionSuccessInterface): authTypes.AuthState => {
  return {
    ...state,
    currentUser: {
      name: action.payload.name,
      lastName: action.payload.lastName,
      email: action.payload.email,
      profileUrl: action.payload.profileUrl,
      token: action.payload.token,
      userStatus: action.payload.userStatus,
      role: { id: action.payload.role.id, name: action.payload.role.name },
    },
    loading: false,
    error: false,
    errorMessage: null,
    authInitLoading:false,
  };
};

const authFail = (state: authTypes.AuthState, action: authTypes.AuthFailInteface): authTypes.AuthState => {
  return {
    ...state,
    currentUser: null,
    loading: false,
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};
const authInitStart= (state:authTypes.AuthState):authTypes.AuthState=>{
  return{
    ...state,
    authInitLoading:true
  }
}

const resetPasswordSendEmailSuccess = (state: authTypes.AuthState, action: authTypes.ResetPasswordEmail): authTypes.AuthState => {
  return {
    ...state,
    loading: false,
    emailSent: true,
    emailSentMessage: `Email sent to ${action.paload.email} if email exists.`,
  };
};
const resetPasswordSendEmailMessageClear = (state: authTypes.AuthState): authTypes.AuthState => {
  return {
    ...state,
    emailSent: false,
    emailSentMessage: null,
    error:false,
    errorMessage:null
  };
};

const resetPasswordSuccess = (state: authTypes.AuthState): authTypes.AuthState => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
    resetPassword:true,
  };
};

const authEditProfileStart = (state: authTypes.AuthState): authTypes.AuthState => {
  return {
    ...state,
    loadingEditProfile: true,
  };
};
const authEditProfileSuccess = (state: authTypes.AuthState, action: authTypes.AuthEditProfileSuccess): authTypes.AuthState => {
  return {
    ...state,
    currentUser: {
      name:action.payload.user.name,
      lastName:action.payload.user.lastName,
      email:action.payload.user.email,
      profileUrl:action.payload.user.profilePicture,
      role:action.payload.user.role,
      token:state.currentUser!.token,
      userStatus:action.payload.user.userStatus
    },
    profileUpdated:true,
    loadingEditProfile:false,
    error:false,
    errorMessage:null
  };
};

const authEditProfileFail =(state:authTypes.AuthState,action:authTypes.AuthEditProfileFail): authTypes.AuthState=>{
  return{
    ...state,
    loadingEditProfile:false,
    error:action.payload.error,
    errorMessage:action.payload.errorMessage
  }
}
const authEditProfileErrorClear=(state:authTypes.AuthState):authTypes.AuthState=>{
  return{
    ...state,
    profileUpdated:false,
    error:false,
    errorMessage:null,
  }
}
const reducer = (state: authTypes.AuthState = initialState, action: authTypes.AuthActionTypes): authTypes.AuthState => {
  switch (action.type) {
    case authActions.AUTH_START:
      return authStart(state);
    case authActions.AUTH_SUCCESS:
      return authSuccess(state, action);
    case authActions.AUTH_LOGOUT:
      return authLogOut(state);
    case authActions.AUTH_FAIL:
      return authFail(state, action);
    case authActions.AUTH_CLEAR_ERROR:
      return authErrorClear(state);
    case authActions.AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS:
      return resetPasswordSendEmailSuccess(state, action);
    case authActions.AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS_CLEAR_MESSAGE:
      return resetPasswordSendEmailMessageClear(state);
    case authActions.AUTH_RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state);
    case authActions.AUTH_EDIT_PROFILE_START:
      return authEditProfileStart(state);
    case authActions.AUTH_EDIT_PROFILE_SUCCESS:
      return authEditProfileSuccess(state,action);
    case authActions.AUTH_EDIT_PROFILE_FAIL:
      return authEditProfileFail(state,action);
    case authActions.AUTH_EDIT_PROFILE_ERROR_CLEAR:
      return authEditProfileErrorClear(state);
    case authActions.AUTH_INIT_START:
      return authInitStart(state);
    default:
      return state;
  }
};

export default reducer;
