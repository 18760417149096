import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';

import AppHeader from '../../shared/AppHeader/AppHeader';
import SearchInput from '../../shared/SearchInput/SearchInput';
import {
  campaignAccountsFilterChange,
  campaignCategoriesFilterChange,
  campaignGeosFilterChange,
  campaignsSearch,
  campaignsStatusFilterChange,
  campaignStrategiesFilterChange,
} from '../campaignsActions';
import SelectStatus from '../Inputs/SelectStatus/SelectStatus';
import AutoCompleteInput from '../Inputs/AutoCompleteInput/AutoCompleteInput';
import { GeosApi } from '../../Geos/geosTypes';
import rolesDictionary from '../../shared/permissions/rolesDictionary';
import { FiltersApartFromGeo } from '../campaignsTypes';

interface Props {
  search: string;
  loading: boolean;
  userToken: string;
  roleId: number;
  activeFilter: 0 | 1 | '';
  geosFilter: GeosApi[];
  accountsFilter: FiltersApartFromGeo[];
  strategiesFilter: FiltersApartFromGeo[];
  categoriesFilter: FiltersApartFromGeo[];
}
let timer: NodeJS.Timeout;
const CampaignHeader: React.FC<Props> = (props) => {
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Search value changes when user types.
  const onChangeSearch = (searchVal: string) => {
    //clear previour timeout
    clearTimeout(timer);
    //We send the typing value to the reducer and set the searching to true since it is a search.
    dispatch(campaignsSearch(searchVal, true));
    //Set the timeout to dispatch the search
    timer = setTimeout(() => dispatch(campaignsSearch(searchVal, false)), 500);
  };

  //Handles change of the status filter and dispatch action to update redux status.
  const onChangeStatusFilter = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: number;
    }>
  ) => {
    const active =
      event.target.value === 0 ? 0 : event.target.value === 1 ? 1 : '';
    dispatch(campaignsStatusFilterChange(active));
  };

  //Handles change of the geos filter and dispatch action to update redux status.
  const onChangeGeos = (event: SyntheticEvent, value: GeosApi[]) => {
    dispatch(campaignGeosFilterChange(value));
  };

  //Handles change of the categories filter and dispatch action to update redux status.
  const onChangeCategories = (
    event: SyntheticEvent,
    value: { id: number; name: string }[]
  ) => {
    dispatch(campaignCategoriesFilterChange(value));
  };

  //Handles change of the accounts filter and dispatch action to update redux status.
  const onChangeAccounts = (
    event: SyntheticEvent,
    value: { id: number; name: string }[]
  ) => {
    dispatch(campaignAccountsFilterChange(value));
  };

  //Handles change of the categories filter and dispatch action to update redux status.
  const onChangeStrategies = (
    event: SyntheticEvent,
    value: { id: number; name: string }[]
  ) => {
    dispatch(campaignStrategiesFilterChange(value));
  };
  return (
    <AppHeader
      search={
        <SearchInput
          searchValue={props.search}
          setSearch={onChangeSearch}
          disabled={props.loading}
        />
      }
      statusFilter={
        <SelectStatus
          value={props.activeFilter}
          onChange={onChangeStatusFilter}
        />
      }
      countryFilter={
        <AutoCompleteInput
          required={false}
          fullWidth={false}
          multiple={true}
          type='geo'
          variant='outlined'
          token={props.userToken}
          label='Geos'
          defaultValue={props.geosFilter}
          changeValue={onChangeGeos}
          shouldRender={false}
        />
      }
      accountFilter={
        <AutoCompleteInput
          required={false}
          fullWidth={false}
          multiple={true}
          type='account'
          variant='outlined'
          token={props.userToken}
          label='Accounts'
          changeValue={onChangeAccounts}
          defaultValue={props.accountsFilter}
          shouldRender={false}
        />
      }
      strategyFilter={
        props.roleId !== rolesDictionary.client && (
          <AutoCompleteInput
            required={false}
            fullWidth={false}
            multiple={true}
            type='strategy'
            variant='outlined'
            token={props.userToken}
            label='Strategies'
            changeValue={onChangeStrategies}
            defaultValue={props.strategiesFilter}
            shouldRender={false}
          />
        )
      }
      categoryFilter={
        <AutoCompleteInput
          required={false}
          fullWidth={false}
          multiple={true}
          type='category'
          variant='outlined'
          token={props.userToken}
          label='Categories'
          changeValue={onChangeCategories}
          defaultValue={props.categoriesFilter}
          shouldRender={false}
        />
      }
    />
  );
};

export default CampaignHeader;
