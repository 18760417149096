import React from 'react';
import { Link } from 'react-router-dom';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import singleCampFilterStyle from './singleCampFiltersStyle';
import { ArrowDropDown } from '@material-ui/icons';

interface Props {
  dates: Date[];
  loading: boolean;
  onChangeDates: (value: Date[]) => void;
  onCloseCalendar: () => void;
}
const useStyles = singleCampFilterStyle;

const SingleCampFilters = (props: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Link style={{ color: 'black' }} to='/dashboard/campaigns'>
        &larr; Back to campaigns
      </Link>
      <DateRangePicker
        showDoubleView={true}
        className={styles.datPicker}
        minDetail='year'
        rangeDivider='to'
        format='dd/MM/yyyy'
        calendarIcon={<ArrowDropDown />}
        clearIcon={null}
        value={props.dates}
        onChange={props.onChangeDates}
        disabled={props.loading}
        closeCalendar={true}
        onCalendarClose={props.onCloseCalendar}
      />
    </div>
  );
};

export default SingleCampFilters;
