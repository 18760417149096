import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../Inputs/AutoCompleteInput/AutoCompleteInput';
import CurrencySelect from '../../../Inputs/CurrencySelect/CurrencySelect';
import createAccountStyles from '../../createCampaignStyles';
import {
  isCampaignNameValid,
  isURLValid,
} from '../../../Validation/validation';

interface ObjValue {
  id: number;
  name: string;
}
interface Props {
  loading: boolean;
  disabled?: boolean;
  campaignName: string;
  userToken: string;
  accounts: ObjValue | null;
  domain: string;
  currency: string;
  categories: ObjValue[] | null;
  onChangeCampName: (campName: string) => void;
  onChangeAccName: (accName: ObjValue) => void;
  onChangeDomain: (domain: string) => void;
  onChangeCurrency: (currency: string) => void;
  onChangeCategories: (category: ObjValue[]) => void;
}

const useStyles = createAccountStyles;

const CampaignTab: React.FC<Props> = (props) => {
  const styles = useStyles();
  const [campaingNameTouched, setCampaignNameTouched] = useState(false);
  const onBlurCampaignName = () => setCampaignNameTouched(true);

  const campaingNameError =
    campaingNameTouched && !isCampaignNameValid(props.campaignName);

  const [domainTouched, setDomainTouched] = useState(false);

  const onBlurDomain = () => setDomainTouched(true);
  const domainError = domainTouched && !isURLValid(props.domain);
  return (
    <>
      <TextField
        fullWidth={true}
        disabled={props.loading || props.disabled}
        value={props.campaignName}
        name='campaign-name'
        onChange={(event) => props.onChangeCampName(event.target.value)}
        onBlur={onBlurCampaignName}
        error={campaingNameError}
        className={`${styles.inputs} ${styles.firstInputText}`}
        label='Campaign name'
        variant='standard'
        helperText={campaingNameError ? 'No less than 3 characers' : ' '}
        required
      />
      <AutoCompleteInput
        disabled={props.loading || props.disabled}
        required={true}
        fullWidth={true}
        multiple={false}
        type='account'
        variant={'standard'}
        token={props.userToken}
        label='Account'
        changeValue={(event, value) => props.onChangeAccName(value)}
        defaultValue={props.accounts}
        shouldRender={true}
      />
      <TextField
        fullWidth={true}
        disabled={props.loading || props.disabled}
        type='url'
        name='domain'
        value={props.domain}
        onChange={(event) => props.onChangeDomain(event.target.value)}
        onBlur={onBlurDomain}
        error={domainError}
        className={styles.inputs}
        label='Campaign website'
        variant='standard'
        helperText={
          domainError ? 'Check if it has https or it is a valid url' : ' '
        }
        required
      />
      <CurrencySelect
        onChange={(event) => props.onChangeCurrency(event.target.value)}
        value={props.currency}
        disabled={props.loading || props.disabled}
      />
      <AutoCompleteInput
        disabled={props.loading || props.disabled}
        required={true}
        fullWidth={true}
        multiple={true}
        type='category'
        variant={'standard'}
        token={props.userToken}
        label='Categories'
        changeValue={(event, value) => props.onChangeCategories(value)}
        defaultValue={props.categories}
        shouldRender={true}
      />
    </>
  );
};

export default CampaignTab;
