import errorMessageBuilder from '../shared/errorMessageBuilder/errorMessageBuilder';
import { AppActions, AppState, ThunkDispatchType } from '../store/storeTypes';
import { createUserApi, getTableStrategiesApi,getOneUserApi, editUserApi, deleteUserApi } from './usersApi';
import * as stratTypes from './usersTypes';
export const USERS_GET_START = 'USERS_GET_START';
export const USERS_GET_FAIL = 'USERS_GET_FAIL';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_SEARCH='USERS_SEARCH';
export const USERS_LIMIT_CHANGE='USERS_LIMIT_CHANGE';
export const USERS_CHANGE_PAGE='USERS_CHANGE_PAGE';
export const USERS_ERROR_CLEAR='USERS_ERROR_CLEAR';
export const USER_CREATE_START='USER_CREATE_START';
export const USER_CREATE_FAIL='USER_CREATE_FAIL';
export const USER_CREATE_SUCCESS='USER_CREATE_SUCCESS';
export const USER_CREATE_CLEAR_ERROR ='USER_CREATE_CLEAR_ERROR';
export const SINGLE_USER_GET_START = 'SINGLE_USER_GET_START';
export const SINGLE_USER_GET_SUCCESS = 'SINGLE_USER_GET_SUCCESS';
export const SINGLE_USER_GET_FAIL = 'SINGLE_USER_GET_FAIL';
export const SINGLE_USER_ERROR_CLEAR = 'SINGLE_USER_ERROR_CLEAR';
export const USER_EDIT_START = 'USER_EDIT_START';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL';
export const USER_EDIT_ERROR_CLEAR = 'USER_EDIT_ERROR_CLEAR';
export const USER_DELETE_START = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS='USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL='USER_DELETE_FAIL';
export const USER_DELETE_ERROR_CLEAR='USER_DELETE_ERROR_CLEAR';

export const usersGetStart = (): AppActions => {
  return {
    type: USERS_GET_START,
  };
};
export const usersGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: USERS_GET_FAIL,
    payload: { error, errorMessage },
  };
};

export const usersGetSuccess = (users: stratTypes.UsersApi[], amount: string): AppActions => {
  return {
    type: USERS_GET_SUCCESS,
    payload: { users: users, amount: parseInt(amount) },
  };
};

export const usersSearch = (search: string, searching: boolean): AppActions => {
    return {
      type: USERS_SEARCH,
      payload: { search, searching },
    };
  };
  
  export const usersPageChange = (page: number): AppActions => {
    return {
      type: USERS_CHANGE_PAGE,
      payload: { page },
    };
  };
  
  export const usersLimitChange = (limit: number): AppActions => {
    return {
      type: USERS_LIMIT_CHANGE,
      payload: { limit },
    };
  };
  
  export const usersErrorClear = (): AppActions => {
    return {
      type: USERS_ERROR_CLEAR,
    };
  };

export const getUsers = (token: string, page: number, numberOffers: number, search: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
    dispatch(usersGetStart())
    const users =await getTableStrategiesApi(token,page,numberOffers,search);
    dispatch(usersGetSuccess(users.users,users.amount));
  try {
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(usersGetFail(true, errorMessage ? errorMessage : err))
  }
};


//Create strategy actions;
export const userCreatestart = (): AppActions => {
    return {
      type: USER_CREATE_START,
    };
  };
  
  export const userCreateFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: USER_CREATE_FAIL,
      payload: { error, errorMessage },
    };
  };
  
  export const userCreateSuccess = (user: stratTypes.UsersApi): AppActions => {
    return {
      type: USER_CREATE_SUCCESS,
      payload:user,
    };
  };
  
  export const userCreateErrorClear = (): AppActions => {
    return {
      type: USER_CREATE_CLEAR_ERROR,
    };
  };
  //user stratTypes.UserCreate
  export const createUser = (token: string, user: FormData) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(userCreatestart());
       const createdUser= await createUserApi(token, user);
      dispatch(userCreateSuccess(createdUser.user));
      dispatch(getUsers(token, 0, 10, ''));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(userCreateFail(true, errorMessage ? errorMessage : err));
    }
  };


  //Get Single Strategy
export const singleUserGetStart = (): AppActions => {
    return {
      type: SINGLE_USER_GET_START,
    };
  };
  
  export const singleUserGetSuccess = (user: stratTypes.UsersApi): AppActions => {
    return {
      type: SINGLE_USER_GET_SUCCESS,
      payload: user,
    };
  };
  
  export const singleUserGetFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: SINGLE_USER_GET_FAIL,
      payload: { error, errorMessage },
    };
  };
  export const singleUserErrorClear = () => {
    return {
      type: SINGLE_USER_ERROR_CLEAR,
    };
  };
  
  export const getSingleUser = (token: string, userId: number) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(singleUserGetStart());
      const user = await getOneUserApi(token, userId);
      dispatch(singleUserGetSuccess(user.user));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(singleUserGetFail(true, errorMessage ? errorMessage : err));
    }
  };

  //Edit Strategies
export const editUserStart = ():AppActions => {
    return {
      type: USER_EDIT_START,
    };
  };
  
  export const editUserFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: USER_EDIT_FAIL,
      payload: { error, errorMessage },
    };
  };
  export const editUserSuccess = (user: stratTypes.UsersApi): AppActions => {
    return {
      type: USER_EDIT_SUCCESS,
      payload: user,
    };
  };
  
  export const editUserErrorClear = (): AppActions => {
    return {
      type: USER_EDIT_ERROR_CLEAR,
    };
  };
  
  export const editUser = (token: string, userInfo:FormData ,userId: number) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(editUserStart());
      const editUser = await editUserApi(token,userInfo,userId);
      dispatch(editUserSuccess(editUser.user));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(editUserFail(true, errorMessage ? errorMessage : err));
    }
  };
  
  //Delete Strategies
  
  export const deleteUserStart=():AppActions=>{
    return{
      type:USER_DELETE_START,
    }
  }
  
  export const deleteUserFail=(error: boolean, errorMessage: string):AppActions=>{
    return{
      type:USER_DELETE_FAIL,
      payload:{error,errorMessage}
    }
  }
  
  export const deleteUserSuccess=(user:stratTypes.DeletedUser):AppActions=>{
    return{
      type:USER_DELETE_SUCCESS,
      payload:user
    }
  }
  
  export const deleteUserErrorClear=():AppActions=>{
    return{
      type:USER_DELETE_ERROR_CLEAR
    }
  }
  export const deleteUser=(token:string,userId:number)=>async(dispatch:ThunkDispatchType,getState:()=>AppState)=>{
    try {
      dispatch(deleteUserStart());
      const user = await deleteUserApi(token,userId);
      dispatch(deleteUserSuccess(user.user));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(deleteUserFail(true, errorMessage ? errorMessage : err))
    }
  }