import errorMessageBuilder from '../shared/errorMessageBuilder/errorMessageBuilder';
import { AppActions, AppState, ThunkDispatchType } from '../store/storeTypes';
import {
  createStrategyApi,
  getTableStrategiesApi,
  getOneStrategyApi,
  editStrategyApi,
  deleteStrategyApi,
} from './strategiesApi';
import * as stratTypes from './strategiesTypes';
export const STRATEGIES_GET_START = 'STRATEGIES_GET_START';
export const STRATEGIES_GET_FAIL = 'STRATEGIES_GET_FAIL';
export const STRATEGIES_GET_SUCCESS = 'STRATEGIES_GET_SUCCESS';
export const STRATEGIES_SEARCH = 'STRATEGIES_SEARCH';
export const STRATEGIES_LIMIT_CHANGE = 'STRATEGIES_LIMIT_CHANGE';
export const STRATEGIES_CHANGE_PAGE = 'STRATEGIES_CHANGE_PAGE';
export const STRATEGIES_ERROR_CLEAR = 'STRATEGIES_ERROR_CLEAR';
export const STRATEGY_CREATE_START = 'STRATEGY_CREATE_START';
export const STRATEGY_CREATE_FAIL = 'STRATEGY_CREATE_FAIL';
export const STRATEGY_CREATE_SUCCESS = 'STRATEGY_CREATE_SUCCESS';
export const STRATEGY_CREATE_CLEAR_ERROR = 'STRATEGY_CREATE_CLEAR_ERROR';
export const SINGLE_STRATEGY_GET_START = 'SINGLE_STRATEGY_GET_START';
export const SINGLE_STRATEGY_GET_SUCCESS = 'SINGLE_STRATEGY_GET_SUCCESS';
export const SINGLE_STRATEGY_GET_FAIL = 'SINGLE_STRATEGY_GET_FAIL';
export const SINGLE_STRATEGY_ERROR_CLEAR = 'SINGLE_STRATEGY_ERROR_CLEAR';
export const STRATEGY_EDIT_START = 'STRATEGY_EDIT_START';
export const STRATEGY_EDIT_SUCCESS = 'STRATEGY_EDIT_SUCCESS';
export const STRATEGY_EDIT_FAIL = 'STRATEGY_EDIT_FAIL';
export const STRATEGY_EDIT_ERROR_CLEAR = 'STRATEGY_EDIT_ERROR_CLEAR';
export const STRATEGY_DELETE_START = 'STRATEGY_DELETE_START';
export const STRATEGY_DELETE_SUCCESS = 'STRATEGY_DELETE_SUCCESS';
export const STRATEGY_DELETE_FAIL = 'STRATEGY_DELETE_FAIL';
export const STRATEGY_DELETE_ERROR_CLEAR = 'STRATEGY_DELETE_ERROR_CLEAR';

export const strategiesGetStart = (): AppActions => {
  return {
    type: STRATEGIES_GET_START,
  };
};
export const strategiesGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: STRATEGIES_GET_FAIL,
    payload: { error, errorMessage },
  };
};

export const strategiesGetSuccess = (strategies: stratTypes.StrategiesApi[], amount: string): AppActions => {
  return {
    type: STRATEGIES_GET_SUCCESS,
    payload: { strategies: strategies, amount: parseInt(amount) },
  };
};

export const strategiesSearch = (search: string, searching: boolean): AppActions => {
  return {
    type: STRATEGIES_SEARCH,
    payload: { search, searching },
  };
};

export const strategiesPageChange = (page: number): AppActions => {
  return {
    type: STRATEGIES_CHANGE_PAGE,
    payload: { page },
  };
};

export const strategiesLimitChange = (limit: number): AppActions => {
  return {
    type: STRATEGIES_LIMIT_CHANGE,
    payload: { limit },
  };
};

export const strategiesErrorClear = (): AppActions => {
  return {
    type: STRATEGIES_ERROR_CLEAR,
  };
};

export const getStrategies = (token: string, page: number, numberOffers: number, search: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  dispatch(strategiesGetStart());
  const strategies = await getTableStrategiesApi(token, page, numberOffers, search);
  dispatch(strategiesGetSuccess(strategies.strategies, strategies.amount));
  try {
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(strategiesGetFail(true, errorMessage ? errorMessage : err));
  }
};

//Create strategy actions;
export const strategyCreatestart = (): AppActions => {
  return {
    type: STRATEGY_CREATE_START,
  };
};

export const strategyCreateFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: STRATEGY_CREATE_FAIL,
    payload: { error, errorMessage },
  };
};

export const strategyCreateSuccess = (strategy: stratTypes.StrategiesApi): AppActions => {
  return {
    type: STRATEGY_CREATE_SUCCESS,
    payload: strategy,
  };
};

export const strategyCreateErrorClear = (): AppActions => {
  return {
    type: STRATEGY_CREATE_CLEAR_ERROR,
  };
};

export const createStrategy = (token: string, strategy: stratTypes.StrategyCreate) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(strategyCreatestart());
    const createdStrategy = await createStrategyApi(token, strategy);

    dispatch(strategyCreateSuccess(createdStrategy.strategy));
    dispatch(getStrategies(token, 0, 10, ''));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(strategyCreateFail(true, errorMessage ? errorMessage : err));
  }
};

//Get Single Strategy
export const singleStrategyGetStart = (): AppActions => {
  return {
    type: SINGLE_STRATEGY_GET_START,
  };
};

export const singleStrategyGetSuccess = (strategy: stratTypes.StrategiesApi): AppActions => {
  return {
    type: SINGLE_STRATEGY_GET_SUCCESS,
    payload: strategy,
  };
};

export const singleStrategyGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: SINGLE_STRATEGY_GET_FAIL,
    payload: { error, errorMessage },
  };
};
export const singleStrategyErrorClear = () => {
  return {
    type: SINGLE_STRATEGY_ERROR_CLEAR,
  };
};

export const getSingleStrategy = (token: string, strategyId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(singleStrategyGetStart());
    const strategy = await getOneStrategyApi(token, strategyId);
    dispatch(singleStrategyGetSuccess(strategy.strategy));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(singleStrategyGetFail(true, errorMessage ? errorMessage : err));
  }
};

//Edit Strategies
export const editStrategyStart = (): AppActions => {
  return {
    type: STRATEGY_EDIT_START,
  };
};

export const editStrategyFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: STRATEGY_EDIT_FAIL,
    payload: { error, errorMessage },
  };
};
export const editStrategySuccess = (strategy: stratTypes.StrategiesApi): AppActions => {
  return {
    type: STRATEGY_EDIT_SUCCESS,
    payload: strategy,
  };
};

export const editStrategyErrorClear = (): AppActions => {
  return {
    type: STRATEGY_EDIT_ERROR_CLEAR,
  };
};

export const editStrategy = (token: string, strategyInfo: stratTypes.StrategyCreate, strategyId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(editStrategyStart());
    const editStrategy = await editStrategyApi(token, strategyInfo, strategyId);
    dispatch(editStrategySuccess(editStrategy.strategy));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(editStrategyFail(true, errorMessage ? errorMessage : err));
  }
};

//Delete Strategies

export const deleteStrategyStart = (): AppActions => {
  return {
    type: STRATEGY_DELETE_START,
  };
};

export const deleteStrategyFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: STRATEGY_DELETE_FAIL,
    payload: { error, errorMessage },
  };
};

export const deleteStrategySuccess = (strategy: stratTypes.DeletedStrategy): AppActions => {
  return {
    type: STRATEGY_DELETE_SUCCESS,
    payload: strategy,
  };
};

export const deleteStrategyErrorClear = (): AppActions => {
  return {
    type: STRATEGY_DELETE_ERROR_CLEAR,
  };
};
export const deleteStrategy = (token: string, strategyId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(deleteStrategyStart());
    const strategy = await deleteStrategyApi(token, strategyId);
    dispatch(deleteStrategySuccess(strategy.strategy));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(deleteStrategyFail(true, errorMessage ? errorMessage : err));
  }
};
