import { useDispatch } from 'react-redux';
import AppHeader from '../../shared/AppHeader/AppHeader';
import SearchInput from '../../shared/SearchInput/SearchInput';
import { geosSearch } from '../geosActions';

interface Props {
  search: string;
  loading: boolean;
}
//Timer for search
let timer: NodeJS.Timeout;
const GeoHeader: React.FC<Props> = (props) => {
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Search value changes when user types.
  const onChangeSearch = (searchVal: string) => {
    //clear previous timeout
    clearTimeout(timer);
    //We send the typing value to the reducer and set the searching to true since it is a search.
    dispatch(geosSearch(searchVal, true));
    //Set the timeout to dispatch the search
    timer = setTimeout(() => dispatch(geosSearch(searchVal, false)), 500);
  };
  return (
    <AppHeader
      search={
        <SearchInput
          searchValue={props.search}
          setSearch={onChangeSearch}
          disabled={props.loading}
        />
      }
    />
  );
};

export default GeoHeader;
