import React, { ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthState } from '../Auth/authTypes';
import isPermited from '../shared/permissions/permissionChecker';
import AppSkeleton from './AppSkeleton/AppSkeleton';

interface Props {
    path: string;
    permission: number[];
    authState: AuthState;
    children: ReactNode;
}
export default function PrivateRoute(props: Props) {
    let component: ReactNode = <Redirect to='/signIn' />;
    if (props.authState.currentUser?.token && isPermited(props.permission, props.authState.currentUser.role.id) && !props.authState.authInitLoading) {
        component = props.children;
    }if(props.authState.authInitLoading){
        component= <AppSkeleton />
    }
    return (
        <Route
            exact
            path={props.path}
        >
            {component}
        </Route>
    )
}