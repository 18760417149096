import React from 'react';
import AppMenu from '../AppMenu/AppMenu';

const AppContainer: React.FC = (props) => {
  return (
    <div style={{ display: 'flex' }}>
      <AppMenu />
      <div style={{ flexGrow: 1, overflow: 'hidden' }}>{props.children}</div>
    </div>
  );
};

export default AppContainer;
