import { Autocomplete, AutocompleteChangeReason, TextField } from '@material-ui/core'
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountsForFilterApi } from '../../../Accounts/accountsApi';
import { getCategoriesForFilterApi } from '../../../Categories/categoriesApi';
import { getGeosForFilterApi } from '../../../Geos/geosApi';
import { AppState } from '../../../store/storeTypes';
import { getStrategiesForFilterApi } from '../../../Strategies/strategiesApi';
import autoCompleteStyles from './autoCompleteStyles';



//Styles
const useStyle = autoCompleteStyles;
//Interface for the selectedObject.
export interface SelectedObject {
    id: number,
    name: string,
    idd?: number
}
export type TypeOfInfo = 'account' | 'geo' | 'category' | 'strategy';
//Generic props to allow reusability.
interface Props<T> {
    token: string;
    fullWidth: boolean;
    required: boolean;
    multiple: boolean;
    type: TypeOfInfo;
    label: string;
    shouldRender: boolean;
    variant: 'standard' | 'outlined';
    defaultValue?: T[] | T | null;
    disabled?: boolean;
    changeValue: (event: SyntheticEvent, value: any, reason?: AutocompleteChangeReason) => void;
}

export default function AutoCompleteInput<T extends SelectedObject>(props: Props<T>) {
    //State for the autocomplete options if they are open or not.
    const [open, setOpen] = useState(false);
    //Store the options for the autocomplete to show.
    const [options, setOptions] = useState<{ id: number, name: string, idd?: number, code?: string }[]>([]);
    //Check if it is still loading or not.
    const loading = open && options.length === 0;
    //State of the campaigns from redux.
    const campaignState = useSelector((state: AppState) => state.campaigns);
    //According to the type of filter it will get it from the server.
    useEffect(() => {
        //Avoids setting the value if the component is not mounted.
        let unmounted = false;
        async function getAccounts() {
            try {
                const accounts = await getAccountsForFilterApi(props.token)
                if (!unmounted) {
                    setOptions(accounts.accounts);

                }

            } catch (err) {
                console.log(err)
            }

        }
        async function getCategories() {
            try {
                const categories = await getCategoriesForFilterApi(props.token)
                if (!unmounted) {
                    setOptions(categories.categories);

                }

            } catch (err) {
                console.log(err)
            }

        }
        async function getGeos() {
            try {
                const geos = await getGeosForFilterApi(props.token)
                if (!unmounted) {
                    setOptions(geos.geos);

                }

            } catch (err) {
                console.log(err)
            }

        }
        async function getStrategies() {
            try {
                const strategies = await getStrategiesForFilterApi(props.token)
                if (!unmounted) {
                    setOptions(strategies.strategies);

                }

            } catch (err) {
                console.log(err)
            }

        }
        switch (props.type) {
            case 'account':
                getAccounts();
                break;
            case 'geo':
                getGeos();
                break;
            case 'category':
                getCategories();
                break
            case 'strategy':
                getStrategies();
                break
            default:
                break;
        }

        return () => { unmounted = true }
    }, [props.token, props.type]);
    
    const style = useStyle();
    //this is for the props of the autocomplete, if it should not render it will set this.
    const extraPropNotRender = {
        renderTags: () => ''
    }
    return (<>
        <Autocomplete
            value={props.defaultValue}
            fullWidth={props.fullWidth}
            disabled={campaignState.loading || props.disabled}
            className={props.variant === 'outlined' ? style.autoCompleteOutlined : style.autoCompleteStandard}
            open={open}
            multiple={props.multiple}
            limitTags={1}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            options={options}
            loading={loading}
            onChange={props.changeValue}
            getOptionSelected={(option, value) => {
                if (props.type === 'geo') {
                    return option.idd === value.idd
                } else { return option.id === value.id }
            }}
            getOptionLabel={(option) => {
                if (props.type === 'geo') {
                    return `(${option.code}) ${option.name}`
                } else {
                    return option.name
                }

            }}

            renderInput={(params) => (<TextField {...params} required={props.multiple ? ((props.defaultValue) as T[]).length === 0 && props.required : props.required} className={props.variant === 'outlined' ? style.inputOutlined : style.inputStandard} fullWidth={props.fullWidth} variant={props.variant} label={props.label} />)}
            {...(props.shouldRender ? {} : extraPropNotRender)}

        />
    </>
    )
}