import Axios from 'axios';

import { StrategyCreate, StrategiesApi,DeletedStratAnswer } from './strategiesTypes';

export const getTableStrategiesApi = async (token: string, page: number, numberOffers: number, search: string) => {
  const { data } = await Axios.get<{ message: string; data: { strategies: StrategiesApi[]; amount: string } }>(
    `/api/strategies/?page=${page}&take=${numberOffers}&search=${search}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

//Create Strategy
export const createStrategyApi = async (token: string, body: StrategyCreate) => {
  const { data } = await Axios.post<{ message: string; data: { strategy: StrategiesApi } }>('/api/strategies', body, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Single Strategy
export const getOneStrategyApi = async (token: string, strategyId: number) => {
  const { data } = await Axios.get<{ message: string; data: { strategy: StrategiesApi } }>(`/api/strategies/${strategyId}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Edit Strategy
export const editStrategyApi=async(token:string,body:StrategyCreate,strategyId:number)=>{
    const {data}= await Axios.patch<{message:string,data:{strategy:StrategiesApi}}>(`/api/strategies/${strategyId}`,body,{
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });
    return data.data
}

//Delete Strategy
export const deleteStrategyApi= async(token:string,strategyId:number)=>{
    const {data}= await Axios.delete<DeletedStratAnswer>('/api/strategies',{
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        data: { id:strategyId },
      })
    return data.data
}

export const getStrategiesForFilterApi=async(token:string)=>{
  const {data}=await Axios.get<{message:string,data:{strategies:{id:number,name:string}[]}}>('/api/strategies/autocomplete', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
}