import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import createCampaignStyles from '../../CreateCampaign/createCampaignStyles';

interface Props {
  editError: boolean;
  editCampaignInfo: boolean;
  editErrorMsg: string | null;
  deleteError: boolean;
  deleteCampaign: boolean;
  deleteErrMsg: string | null;
  singleCampaignError: boolean;
  singleCampErrMsg: string | null;
  onClearEditError: () => void;
  onClearDeleteError: () => void;
  onClearErrorSingleCamp: () => void;
}
const useStyles = createCampaignStyles;
const EditCampaignError = (props: Props) => {
  const styles = useStyles();
  return (
    <>
      <Snackbar
        open={props.editError || props.editCampaignInfo}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={props.onClearEditError}
      >
        <Alert
          className={styles.errorMessageValidation}
          severity={props.editError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={props.onClearEditError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {props.editError ? props.editErrorMsg : 'Campaign edited!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={props.deleteCampaign || props.deleteError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={props.onClearDeleteError}
      >
        <Alert
          severity={props.deleteError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={props.onClearDeleteError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {props.deleteError ? props.deleteErrMsg : 'Campaign Deleted!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={props.singleCampaignError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={props.onClearErrorSingleCamp}
      >
        <Alert
          severity='error'
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={props.onClearErrorSingleCamp}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {props.singleCampaignError
            ? props.singleCampErrMsg
            : 'Cant find campaign!'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditCampaignError;
