import React from 'react';
import {
  Avatar,
  Typography,
  makeStyles,
  Theme,
  Skeleton,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { AppState } from '../../store/storeTypes';
interface Props {
  data: { name?: string; lastName?: string; url?: string };
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      margin: '0 10px',

      alignItems: 'center',
    },

    name: {
      '&.MuiTypography-subtitle1': {
        marginRight: '23px',
      },
    },
  };
});
const Avatars: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const authState = useSelector((state: AppState) => state.auth);

  return (
    <div className={classes.container}>
      <Typography variant='subtitle1' className={classes.name}>
        {data.name ? (
          `${data.name} ${data.lastName ? data.lastName : ''}`
        ) : (
          <Skeleton style={{ width: '100px' }} />
        )}
      </Typography>
      {authState.loading || authState.authInitLoading ? (
        <Skeleton style={{ borderRadius: '50%', height: '70px' }}>
          <Avatar />
        </Skeleton>
      ) : (
        <Avatar
          alt={authState.currentUser?.name}
          src={'/images/' + authState.currentUser!.profileUrl}
        >
          <Person />
        </Avatar>
      )}
    </div>
  );
};

export default Avatars;
