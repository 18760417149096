import { TextField } from '@material-ui/core';
import CheckboxWithLabel from '../../../Inputs/CheckboxWithLabel/CheckboxWithLabel';
import createAccountStyles from '../../createCampaignStyles';

interface Props {
  regexText: string;
  loading: boolean;
  isMobile: boolean;
  isNextSession: boolean;
  onChangeRegexText: (regex: string) => void;
  onChangeIsMobile: (checked: boolean) => void;
  onChangeIsNextSession: (checked: boolean) => void;
}
const useStyles = createAccountStyles;

const SettingsTab = (props: Props) => {
  const styles = useStyles();
  return (
    <>
      <CheckboxWithLabel
        checked={props.isMobile}
        label='Mobile only'
        onChange={(checked) => props.onChangeIsMobile(checked)}
      />
      <CheckboxWithLabel
        checked={props.isNextSession}
        label='Next Session'
        onChange={(checked) => props.onChangeIsNextSession(checked)}
      />
      <TextField
        className={styles.blacklistInput}
        multiline
        fullWidth={true}
        label='Zero event Campaign regex'
        variant='filled'
        rows={5}
        placeholder='create the regex object for the campaign 1:(),2:asd dont use commas inside the regex'
        value={props.regexText}
        onChange={(event) => props.onChangeRegexText(event.target.value)}
        disabled={props.loading}
      />
    </>
  );
};

export default SettingsTab;
