import Axios from 'axios';
import {
  CampaignTableApi,
  DeleteCampaignAnswer,
  EditCampaignObject,
  OrderBy,
  OrderDirection,
  SingleCampaignApi,
  CampaignCreateObject,
  SingleCampaignEventApi
} from './campaignsTypes';
/**
 *
 * @param token auth token
 * @param page a number starting from 0;
 * @param numberOffers  Quantity of offers you want to get.
 * @param orderBy  campaignName or active...
 * @param orderDirection  desc \ asc
 * @param dateFrom date should be year/month/day 2020/11/01
 * @param dateTo  date should be year/month/day 2020/11/01
 * @param search  a search query
 * @param status  0 1 or ''
 * @param categories  Array with id of categories
 * @param geos Array with ids of geos
 * @param accountIds  array with ids of accounts
 * @param strategyIds array with id of strategies.
 */
export const getTableCampaignsApi = async (
  token: string,
  page: number,
  numberOffers: number,
  orderBy: OrderBy,
  orderDirection: OrderDirection,
  dateFrom: string,
  dateTo: string,
  search: string,
  status: 1 | 0 | '',
  categories: number[],
  geos: number[],
  accountIds: number[],
  strategyIds: number[]
) => {
  const categoryString = categories ? categories.join(',') : '';
  const geoString = geos ? geos.join(',') : '';
  const accountIdsString = accountIds ? accountIds.join(',') : '';
  const strategyIdsString = strategyIds ? strategyIds.join(',') : '';
  const activeFilter = status === 0 || status === 1 ? status : '';
  const { data } = await Axios.get<{ message: string; data: { campaigns: CampaignTableApi[]; amount: string } }>(
    `/api/campaigns/?page=${page}&take=${numberOffers}&orderBy=${orderBy}&orderDirection=${orderDirection}&dateFrom=${dateFrom}&dateTo=${dateTo}&search=${search}&active=${activeFilter}&categories=${categoryString}&geos=${geoString}&accountIds=${accountIdsString}&strategyIds=${strategyIdsString}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

export const getEventsCampaignsApi = async (
  token: string,
  campaignId:number,
  page: number,
  numberOffers: number,
  orderBy: 'Date',
  orderDirection: OrderDirection,
  dateFrom: string,
  dateTo: string,
  
) => {
  const { data } = await Axios.get<{ message: string; data: { campaign: SingleCampaignEventApi; amount: string } }>(
    `/api/campaigns/${campaignId}/events/stats?page=${page}&take=${numberOffers}&orderBy=${orderBy}&orderDirection=${orderDirection}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};




//Create campaign
export const createCampaignApi = async (token: string, body:CampaignCreateObject ) => {
  const { data } = await Axios.post('/api/campaigns', body, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Edit campaign

export const editCampaignApi = async (token: string, body: EditCampaignObject, campaignId: number) => {
  const { data } = await Axios.patch<{ message: string; data: { campaign: SingleCampaignApi } }>(
    `/api/campaigns/${campaignId}`,
    body,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  
  return data.data;
};

export const statusChangeApi = async (token: string, body: { status: 1 | 0 }, campaignId: number) => {
  const { data } = await Axios.patch<{ message: string; data: { campaign: SingleCampaignApi } }>(
    `/api/campaigns/status/${campaignId}`,
    body,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};
//Get one campaign

export const getOneCampaignApi = async (token: string, campaignId: number) => {
  const { data } = await Axios.get<{ message: string; data: { campaign: SingleCampaignApi } }>(
    `/api/campaigns/${campaignId}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

//Delete campaign

export const deleteCampaignApi = async (token: string, campaignId: number) => {
  const { data } = await Axios.delete<DeleteCampaignAnswer>('/api/campaigns', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    data: { id: campaignId },
  });
  return data.data;
};
