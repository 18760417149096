export default function errorMessageBuilder(err: any) {
  let errorMessage = 'An error ocurred, talk with IT if the error persists.';
  if (err.response?.data.message) {
    errorMessage = err.response.data.message;
  }
  if (err?.response?.data?.error && err?.response?.data?.error.length > 0) {
    errorMessage = err.response.data.error[0].msg;
  }
  if (err?.response?.status === 404 || err?.response?.status === 500) {
    errorMessage = err.response.statusText;
  }
  return errorMessage;
}
