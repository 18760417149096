import React, { ChangeEvent, useState, FocusEvent, FormEvent } from 'react';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createStrategyStyles from './createStrategyStyles';
import { StrategyCreate } from '../strategiesTypes';
import { strategyCreateErrorClear, createStrategy } from '../strategiesActions';
import { Close } from '@material-ui/icons';

const useStyles = createStrategyStyles;

const CreateStrategy: React.FC = () => {
  const authState = useSelector((state: AppState) => state.auth);
  const strategyState = useSelector((state: AppState) => state.strategies);
  const dispatch = useDispatch();
  const styles = useStyles();

  const [strategyName, setStrategyName] = useState({ value: '', error: false });
  const onChangeStrategyName = (event: ChangeEvent<HTMLInputElement>) => {
    setStrategyName({ value: event.target.value, error: false });
  };
  const onBlurStrategyName = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setStrategyName((prev) => ({ ...prev, error: true }));
    }
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    const strategyObject: StrategyCreate = {
      name: strategyName.value,
    };
    dispatch(createStrategy(authState.currentUser!.token, strategyObject));
  };

  const error = strategyName.error ? true : false;
  const onClearError = () => {
    dispatch(strategyCreateErrorClear());
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Create new strategy
        </Typography>
      </div>

      <form className={styles.form} onSubmit={formSubmit}>
        <TextField
          value={strategyName.value}
          name='strategy-name'
          onChange={onChangeStrategyName}
          onBlur={onBlurStrategyName}
          error={strategyName.error}
          className={`${styles.inputs} ${styles.firstInputText}`}
          label='Strategy name'
          variant='standard'
          helperText={strategyName.error ? 'No less than 3 characers' : ' '}
          required
        />

        <Button
          className={styles.button}
          variant='contained'
          color='primary'
          disabled={
            error || strategyState.createLoading || strategyState.createError
          }
        >
          {strategyState.createLoading ? <CircularProgress /> : 'Submit'}
        </Button>
      </form>
      <Snackbar
        open={strategyState.createError || Boolean(strategyState.created)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={strategyState.createError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {strategyState.createError
            ? strategyState.createErrorMsg
            : 'Strategy created!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateStrategy;
