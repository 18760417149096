import Axios from 'axios';

import { CategoryCreate, CategoriesApi,DeletedCatAnswer } from './categoriesTypes';

export const getTableCategoriesApi = async (token: string, page: number, numberOffers: number, search: string) => {
  const { data } = await Axios.get<{ message: string; data: { categories: CategoriesApi[]; amount: string } }>(
    `/api/categories/?page=${page}&take=${numberOffers}&search=${search}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

//Create Category
export const createCategoryApi = async (token: string, body: CategoryCreate) => {
  const { data } = await Axios.post<{ message: string; data: { category: CategoriesApi } }>('/api/categories', body, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Single Category
export const getOneCategoryApi = async (token: string, categoryId: number) => {
  const { data } = await Axios.get<{ message: string; data: { category: CategoriesApi } }>(`/api/categories/${categoryId}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Edit Category
export const editCategoryApi=async(token:string,body:CategoryCreate,categoryId:number)=>{
    const {data}= await Axios.patch<{message:string,data:{category:CategoriesApi}}>(`/api/categories/${categoryId}`,body,{
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });
    return data.data
}

//Delete Category
export const deleteCategoryApi= async(token:string,categoryId:number)=>{
    const {data}= await Axios.delete<DeletedCatAnswer>('/api/categories',{
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        data: { id:categoryId },
      })
    return data.data
}

export const getCategoriesForFilterApi=async(token:string)=>{
  const {data}=await Axios.get<{message:string,data:{categories:{id:number,name:string}[]}}>('/api/categories/autocomplete', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
}