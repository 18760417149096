import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const createGeoStyles = makeStyles((theme: Theme) => ({
  container: { padding: '3.678rem', paddingTop: '0' },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '1.5rem',
    marginBottom: '1.25rem',
  },
  form:{ display: 'flex', flexDirection: 'column' },
  inputs: {
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
    },
  },
  firstInputText:{
    marginBottom:'0'
  },

  button: {
    display: 'block',
    marginLeft: 'auto',
    marginTop:'1.25rem'
  },
}));

export default createGeoStyles;
