import React, {
  ChangeEvent,
  useState,
  FocusEvent,
  FormEvent,
  useEffect,
} from 'react';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createCategoryStyles from '../CreateCategory/createCategoryStyles';
import { CategoryCreate } from '../categoriesTypes';
import {
  deleteCategory,
  deleteCategoryErrorClear,
  editCategory,
  editCategoryErrorClear,
  getCategories,
  getSingleCategory,
  singleCategoryErrorClear,
} from '../categoriesActions';
import { Close } from '@material-ui/icons';
import EditCategorySkeleton from './EditCategorySkeleton';
import DeleteDialog from '../../shared/DeleteDialog/DeleteDialog';

const useStyles = createCategoryStyles;

interface Props {
  categoryId: number;
  closeModal: () => void;
  closeMenu: () => void;
}
const EditCategory: React.FC<Props> = (props) => {
  const authState = useSelector((state: AppState) => state.auth);
  const categoryState = useSelector((state: AppState) => state.categories);
  const dispatch = useDispatch();
  const styles = useStyles();
  useEffect(() => {
    dispatch(getSingleCategory(authState.currentUser!.token, props.categoryId));
  }, [dispatch, authState.currentUser, props.categoryId]);

  useEffect(() => {
    setCategoryName({ error: false, value: categoryState.singleCategory.name });
  }, [props.categoryId, categoryState.singleCategory]);

  const [categoryName, setCategoryName] = useState({
    value: categoryState.singleCategory.name,
    error: false,
  });
  const onChangeCategoryName = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryName({ value: event.target.value, error: false });
  };
  const onBlurCategoryName = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setCategoryName((prev) => ({ ...prev, error: true }));
    }
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    const categoryObject: CategoryCreate = {
      catName: categoryName.value,
    };
    dispatch(
      editCategory(
        authState.currentUser!.token,
        categoryObject,
        categoryState.singleCategory.id
      )
    );
  };

  const error = categoryName.error ? true : false;
  const onClearError = () => {
    dispatch(editCategoryErrorClear());
    if (!categoryState.editError) {
      props.closeMenu();
      props.closeModal();
      dispatch(
        getCategories(
          authState.currentUser!.token,
          categoryState.page,
          categoryState.limit,
          categoryState.search
        )
      );
    }
  };

  const deleteCategories = () => {
    dispatch(
      deleteCategory(
        authState.currentUser!.token,
        categoryState.singleCategory.id
      )
    );
  };
  const clearErrorDelete = () => {
    dispatch(deleteCategoryErrorClear());
    props.closeMenu();
    props.closeModal();
    dispatch(
      getCategories(
        authState.currentUser!.token,
        categoryState.page,
        categoryState.limit,
        categoryState.search
      )
    );
  };
  const clearErrorSingleCategory = () => {
    dispatch(singleCategoryErrorClear());
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const acceptDeleteDialog = () => {
    setOpenDeleteDialog(false);
    deleteCategories();
  };
  const rejectDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Edit Category
        </Typography>
      </div>

      {!categoryState.singleCategoryLoading && (
        <form className={styles.form} onSubmit={formSubmit}>
          <TextField
            value={categoryName.value}
            name='category-name'
            onChange={onChangeCategoryName}
            onBlur={onBlurCategoryName}
            error={categoryName.error}
            className={`${styles.inputs} ${styles.firstInputText}`}
            label='Category name'
            variant='standard'
            helperText={categoryName.error ? 'No less than 3 characers' : ' '}
            required
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1.25rem',
            }}
          >
            <Button
              onClick={deleteDialog}
              type='button'
              variant='text'
              color='primary'
              style={{ marginRight: '1.25rem' }}
              disabled={
                error ||
                categoryState.editLoading ||
                categoryState.editError ||
                categoryState.deleteCategoryLoading ||
                categoryState.deleteCategoryError
              }
            >
              <Delete style={{ marginRight: '0.3125rem' }} /> Delete
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={
                error ||
                categoryState.editLoading ||
                categoryState.editError ||
                categoryState.deleteCategoryLoading ||
                categoryState.deleteCategoryError
              }
            >
              {categoryState.editLoading ? <CircularProgress /> : 'Submit'}
            </Button>
          </div>
        </form>
      )}
      {categoryState.singleCategoryLoading && <EditCategorySkeleton />}

      <Snackbar
        open={
          categoryState.editError || Boolean(categoryState.editCategoryInfo)
        }
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={categoryState.editError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {categoryState.editError
            ? categoryState.editErrorMessage
            : 'Category edited!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={
          Boolean(categoryState.deletedCategory) ||
          categoryState.deleteCategoryError
        }
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorDelete}
      >
        <Alert
          severity={categoryState.deleteCategoryError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorDelete}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {categoryState.deleteCategoryError
            ? categoryState.deleteCategoryErroMsg
            : 'Category Deleted!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={categoryState.singleCategoryError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorSingleCategory}
      >
        <Alert
          severity='error'
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorSingleCategory}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {categoryState.singleCategoryError
            ? categoryState.singleCategoryErrorMsg
            : 'Cant find category!'}
        </Alert>
      </Snackbar>
      <DeleteDialog
        title='Delete Category'
        bodyText='Do you want to delete this category?'
        open={openDeleteDialog}
        handleAcceptance={acceptDeleteDialog}
        handleRejection={rejectDeleteDialog}
      />
    </div>
  );
};

export default EditCategory;
