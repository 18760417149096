import { Button, Typography } from '@material-ui/core';
import React from 'react';
import rolesDictionary from '../permissions/rolesDictionary';
import pageTitleStyles from './pageTitleStyles';

interface Props {
  title: string;
  buttonText: string;
  userRoleId: number;
  openCreate: () => void;
}
const useStyles = pageTitleStyles;
const PageTitle: React.FC<Props> = (props) => {
  const { container } = useStyles();
  return (
    <div className={container}>
      <Typography component='h2' variant='h3'>
        {props.title}
      </Typography>
      {props.userRoleId !== rolesDictionary.client && (
        <Button onClick={props.openCreate} variant='contained' color='primary'>
          {props.buttonText}
        </Button>
      )}
    </div>
  );
};

export default PageTitle;
