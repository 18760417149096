import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './../store/storeTypes';
import { usersErrorClear, getUsers } from './usersActions';
import EditUser from './EditUser/EditUser';
import ModalContainer from './../shared/Modal/ModalContainer';
import CreateGeo from './CreateUser/CreateUser';
import AppContainer from '../shared/AppContainer/AppContainer';
import PageTitle from '../shared/PageTitle/PageTitle';
import UsersTable from './UsersTable/UsersTable';
import UserHeader from './UserHeader/UserHeader';

const UsersPage: React.FC = () => {
  //The authenticated user state from the reducer.
  const authState = useSelector((state: AppState) => state.auth);
  //The user state from the reducer.
  const userState = useSelector((state: AppState) => state.users);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //For each render if the values of the filters has changed and is not searching the accounts will be fetched.
  useEffect(() => {
    if (!userState.searching) {
      dispatch(
        getUsers(
          authState.currentUser!.token,
          userState.page,
          userState.limit,
          userState.search
        )
      );
    }
  }, [
    authState.currentUser,
    dispatch,
    userState.search,
    userState.page,
    userState.searching,
    userState.limit,
  ]);

  //State to manage the opening of the table menu.
  const [openTableMenu, setOpenTableMenu] = useState<{
    id: number;
    element: null | HTMLInputElement;
  }>({
    id: 0,
    element: null,
  });
  //When a checkbox is checked it opens the menu.
  const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setOpenTableMenu({
      element: event.target,
      id: parseInt(event.target.value),
    });
  };
  //When a click is registered outside the menu it will close it and remove the element id.
  const onCloseTableMenu = () => {
    setOpenTableMenu({ id: 0, element: null });
  };
  //Closes the error when the x is click.
  const onClearError = () => {
    dispatch(usersErrorClear());
  };
  //State to manage if the edit modal is open.
  const [openEditModal, setOpenEditModal] = useState(false);
  //Opens edit modal.
  const setOpenToEditModal = () => {
    setOpenEditModal(true);
  };
  //Closes edit modal.
  const setCloseToEditModal = () => {
    setOpenEditModal(false);
  };
  //State to manage if create modal is open or closed.
  const [createUserOpen, setCreateUserOpen] = useState(false);
  //Opens create modal
  const openCreateUser = () => {
    setCreateUserOpen(true);
  };
  //Closes create modal.
  const closeCreateUser = () => {
    setCreateUserOpen(false);
  };

  return (
    <AppContainer>
      <>
        <UserHeader loading={userState.loading} search={userState.search} />
        <main>
          <PageTitle
            buttonText='Create User'
            title='Users'
            openCreate={openCreateUser}
            userRoleId={authState.currentUser!.role.id}
          />
          <UsersTable
            onChangeCheckbox={onChangeCheckBox}
            onCloseTableMenu={onCloseTableMenu}
            openTableMenu={openTableMenu}
            setOpenEditModal={setOpenToEditModal}
          />
          <Snackbar
            open={userState.error}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={onClearError}
          >
            <Alert
              severity='error'
              role='alert'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={onClearError}
                >
                  <Close />{' '}
                </IconButton>
              }
              variant='filled'
            >
              {userState.errorMessage}
            </Alert>
          </Snackbar>
          <ModalContainer open={openEditModal} close={setCloseToEditModal}>
            {
              <EditUser
                closeModal={setCloseToEditModal}
                closeMenu={onCloseTableMenu}
                userId={openTableMenu.id}
              />
            }
          </ModalContainer>
          <ModalContainer open={createUserOpen} close={closeCreateUser}>
            <CreateGeo />
          </ModalContainer>
        </main>
      </>
    </AppContainer>
  );
};

export default UsersPage;
