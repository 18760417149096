import rolesDictionary from '../../../shared/permissions/rolesDictionary';
import { Link } from 'react-router-dom';
interface Props {
  roleID: number;
  campaignName: string;
  campaignID: number;
}
const CampaignNameLink = (props: Props) => {
  let element: JSX.Element | string = <p>props.campaignName</p>;
  if (props.roleID !== rolesDictionary.client) {
    element = (
      <Link to={`/dashboard/events/campaigns/${props.campaignID}`}>
        {props.campaignName}
      </Link>
    );
  }
  return element;
};

export default CampaignNameLink;
