import React, {
  ChangeEvent,
  useState,
  FocusEvent,
  FormEvent,
  useEffect,
} from 'react';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createStrategyStyles from '../CreateStrategy/createStrategyStyles';
import { StrategyCreate } from '../strategiesTypes';
import {
  deleteStrategy,
  deleteStrategyErrorClear,
  editStrategy,
  editStrategyErrorClear,
  getStrategies,
  getSingleStrategy,
  singleStrategyErrorClear,
} from '../strategiesActions';
import { Close } from '@material-ui/icons';
import EditStrategySkeleton from './EditStrategySkeleton';
import DeleteDialog from '../../shared/DeleteDialog/DeleteDialog';

const useStyles = createStrategyStyles;

interface Props {
  strategyId: number;
  closeModal: () => void;
  closeMenu: () => void;
}
const EditStrategy: React.FC<Props> = (props) => {
  const authState = useSelector((state: AppState) => state.auth);
  const strategyState = useSelector((state: AppState) => state.strategies);
  const dispatch = useDispatch();
  const styles = useStyles();
  useEffect(() => {
    dispatch(getSingleStrategy(authState.currentUser!.token, props.strategyId));
  }, [dispatch, authState.currentUser, props.strategyId]);

  useEffect(() => {
    setStrategyName({ error: false, value: strategyState.singleStrategy.name });
  }, [props.strategyId, strategyState.singleStrategy]);

  const [strategyName, setStrategyName] = useState({
    value: strategyState.singleStrategy.name,
    error: false,
  });
  const onChangeStrategyName = (event: ChangeEvent<HTMLInputElement>) => {
    setStrategyName({ value: event.target.value, error: false });
  };
  const onBlurStrategyName = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setStrategyName((prev) => ({ ...prev, error: true }));
    }
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    const strategyObject: StrategyCreate = {
      name: strategyName.value,
    };
    dispatch(
      editStrategy(
        authState.currentUser!.token,
        strategyObject,
        strategyState.singleStrategy.id
      )
    );
  };

  const error = strategyName.error ? true : false;
  const onClearError = () => {
    dispatch(editStrategyErrorClear());
    if (!strategyState.editError) {
      props.closeMenu();
      props.closeModal();
      dispatch(
        getStrategies(
          authState.currentUser!.token,
          strategyState.page,
          strategyState.limit,
          strategyState.search
        )
      );
    }
  };

  const deleteStrategies = () => {
    dispatch(
      deleteStrategy(
        authState.currentUser!.token,
        strategyState.singleStrategy.id
      )
    );
  };
  const clearErrorDelete = () => {
    dispatch(deleteStrategyErrorClear());
    props.closeMenu();
    props.closeModal();
    dispatch(
      getStrategies(
        authState.currentUser!.token,
        strategyState.page,
        strategyState.limit,
        strategyState.search
      )
    );
  };
  const clearErrorSingleStrategy = () => {
    dispatch(singleStrategyErrorClear());
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const acceptDeleteDialog = () => {
    setOpenDeleteDialog(false);
    deleteStrategies();
  };
  const rejectDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Edit Strategy
        </Typography>
      </div>

      {!strategyState.singleStrategyLoading && (
        <form className={styles.form} onSubmit={formSubmit}>
          <TextField
            value={strategyName.value}
            name='strategy-name'
            onChange={onChangeStrategyName}
            onBlur={onBlurStrategyName}
            error={strategyName.error}
            className={`${styles.inputs} ${styles.firstInputText}`}
            label='Strategy name'
            variant='standard'
            helperText={strategyName.error ? 'No less than 3 characers' : ' '}
            required
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1.25rem',
            }}
          >
            <Button
              onClick={deleteDialog}
              type='button'
              variant='text'
              color='primary'
              style={{ marginRight: '1.25rem' }}
              disabled={
                error ||
                strategyState.editLoading ||
                strategyState.editError ||
                strategyState.deleteStrategyLoading ||
                strategyState.deleteStrategyError
              }
            >
              <Delete style={{ marginRight: '0.3125rem' }} /> Delete
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={
                error ||
                strategyState.editLoading ||
                strategyState.editError ||
                strategyState.deleteStrategyLoading ||
                strategyState.deleteStrategyError
              }
            >
              {strategyState.editLoading ? <CircularProgress /> : 'Submit'}
            </Button>
          </div>
        </form>
      )}
      {strategyState.singleStrategyLoading && <EditStrategySkeleton />}

      <Snackbar
        open={
          strategyState.editError || Boolean(strategyState.editStrategyInfo)
        }
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={strategyState.editError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {strategyState.editError
            ? strategyState.editErrorMessage
            : 'Strategy edited!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={
          Boolean(strategyState.deletedStrategy) ||
          strategyState.deleteStrategyError
        }
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorDelete}
      >
        <Alert
          severity={strategyState.deleteStrategyError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorDelete}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {strategyState.deleteStrategyError
            ? strategyState.deleteStrategyErroMsg
            : 'Strategy Deleted!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={strategyState.singleStrategyError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorSingleStrategy}
      >
        <Alert
          severity='error'
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorSingleStrategy}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {strategyState.singleStrategyError
            ? strategyState.singleStrategyErrorMsg
            : 'Cant find strategy!'}
        </Alert>
      </Snackbar>
      <DeleteDialog
        title='Delete Strategy'
        bodyText='Do you want to delete this strategy?'
        open={openDeleteDialog}
        handleAcceptance={acceptDeleteDialog}
        handleRejection={rejectDeleteDialog}
      />
    </div>
  );
};

export default EditStrategy;
