import { Skeleton, Typography } from '@material-ui/core';
import React from 'react';

import pageTitleNoButtonStyles from './pageTitleNoButtonStyles';

interface Props {
  title: string | undefined;
  loading: boolean;
}
const useStyles = pageTitleNoButtonStyles;
const PageTitleNoButton: React.FC<Props> = (props) => {
  const { container } = useStyles();
  let content = (
    <Skeleton>
      <Typography component='h3' variant='h3'>
        placeholder
      </Typography>{' '}
    </Skeleton>
  );
  if (!props.loading) {
    content = (
      <Typography component='h2' variant='h3'>
        {props.title}
      </Typography>
    );
  }
  return <div className={container}>{content}</div>;
};

export default PageTitleNoButton;
