import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"

const authStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            background: ' linear-gradient(135deg, #f25050 0%,#f49090 26%,#848fce 66%,#4e5ba6 100%);',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        backButton:{
            marginRight:'auto',
            '& a':{
                textDecoration:'none',
                color:theme.palette.secondary.main,
            }
        },
        loginDiv: {
            display: 'flex',
            flexDirection: 'column',
            width: '18.75rem',
            height: 'auto',
            padding:'30px',
            alignItems: 'center',

        },
        loginForm: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1.875rem',
        },
        inputLogin: {
            '& label': {
                color: 'black'
            },
            marginBottom: '1.25rem',
            '& input': {
                padding: '0.62rem 0.87rem'
            }
        },
        buttonLogin: {
            height: '2.75rem'
        },
        buttonResetPassword:{
            height: '1.25rem',
            marginBottom:'0.8rem',
            textTransform:'none',
            width:'auto',
            position:'relative',
            top:'-1.25rem',
            '& a':{
                color:theme.palette.secondary.main,
            }
            
        }

    }
})
export default authStyles;