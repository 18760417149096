import { makeStyles, Theme } from "@material-ui/core";



const autoCompleteUserStyles = makeStyles((theme: Theme) => ({
    autoCompleteStandard: {
        display: 'inline-flex',
        width: 'fit-content',
        marginTop: '1.25rem',
        padding: '0',
        minWidth: '12.20rem',
        '& .MuiInputLabel-root': {
            color: theme.palette.text.primary
        }
    },
    input: {
        minWidth: '12.20rem',

    }
}));

export default autoCompleteUserStyles;