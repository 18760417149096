import React, { useState, FormEvent } from 'react';
import {
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createAccountStyles from './createCampaignStyles';
import {
  campaignCreateErrorClear,
  campaignCreateFail,
  createCampaign,
} from '../campaignsActions';

import { GeosApi } from '../../Geos/geosTypes';
import TabPanel from './TabPanel/TabPanel';
import rolesDictionary from '../../shared/permissions/rolesDictionary';
import { CampaignCreateObject } from '../campaignsTypes';
import CampaignTab from './TabPanel/Tabs/CampaignTab';
import TrafficTab from './TabPanel/Tabs/TrafficTab';
import TrafficCapTab from './TabPanel/Tabs/TrafficCapTab';
import SettingsTab from './TabPanel/Tabs/SettingsTab';
import {
  isCampaignNameValid,
  isURLValid,
  createCampaignFormIsValid,
} from '../Validation/validation';
import { Close } from '@material-ui/icons';

interface ObjValue {
  id: number;
  name: string;
}

const useStyles = createAccountStyles;

const CreateCampaign: React.FC = () => {
  const authState = useSelector((state: AppState) => state.auth);
  const campaignState = useSelector((state: AppState) => state.campaigns);
  const dispatch = useDispatch();
  const styles = useStyles();
  const [campaignName, setCampaignName] = useState('');
  const onChangeCampaignName = (campaignName: string) => {
    setCampaignName(campaignName);
  };

  const [domain, setDomain] = useState('');
  const onChangeDomain = (domain: string) => {
    setDomain(domain);
  };

  const [trackingLink, setTrackingLink] = useState('');
  const onChangeTrackingLink = (tracking: string) => {
    setTrackingLink(tracking);
  };

  const [currency, setCurrency] = useState('');
  const onChangeCurrency = (currency: string) => {
    setCurrency(currency);
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  const [startDate, setStartDate] = useState<Date>(new Date(year, month, 1));

  const onChangeStartDate = (value: Date) => {
    setStartDate(value);
  };

  const [endDate, setEndDate] = useState<Date>(new Date(year, month + 1, 0));
  const onChangeEndDate = (value: Date) => {
    setEndDate(value);
  };

  const [sliderValue, setSliderValue] = useState<number>(1.2);
  const onChangeSlider = (newValue: number) => {
    setSliderValue(newValue);
  };
  const [capClicks, setCapClicks] = useState<'' | number>('');
  const onChangeCapClicks = (cap: string) => {
    if (!isNaN(parseInt(cap))) {
      setCapClicks(parseInt(cap));
    } else {
      setCapClicks('');
    }
  };

  const [capConv, setCapConv] = useState<'' | number>('');
  const onChangeCapConv = (cap: string) => {
    if (!isNaN(parseInt(cap))) {
      setCapConv(parseInt(cap));
    } else {
      setCapConv('');
    }
  };

  const [accounts, setAccounts] =
    useState<{ id: number; name: string } | null>(null);
  const onChangeAccounts = (account: ObjValue) => {
    setAccounts(account);
  };
  const [categories, setCategories] = useState<{ id: number; name: string }[]>(
    []
  );
  const onChangeCategories = (categories: { id: number; name: string }[]) => {
    setCategories(categories);
  };
  const [strategies, setStrategies] =
    useState<{
      id: number;
      name: string;
    } | null>(null);
  const onChangeStrategies = (strategy: { id: number; name: string }) => {
    setStrategies(strategy);
  };
  const [geos, setGeos] = useState<GeosApi[]>([]);

  const onChangeGeos = (geos: GeosApi[]) => {
    setGeos(geos);
  };
  const [blacklistGeos, setBlacklistGeos] = useState<GeosApi[]>([]);

  const onChangeBlackListGeos = (geos: GeosApi[]) => {
    setBlacklistGeos(geos);
  };
  const [blacklist, setBlacklist] = useState('');
  const onChangeBlackList = (blacklist: string) => {
    setBlacklist(blacklist);
  };

  const [isMobile, setIsMobile] = useState(false);
  const onChangeIsMobile = (checked: boolean) => {
    setIsMobile(checked);
  };

  const [isNextSession, setIsNextSession] = useState(false);
  const onChangeIsNextSession = (checked: boolean) => {
    setIsNextSession(checked);
  };

  const [regexText, setRegexObject] = useState('');
  const onChangeRegexText = (regex: string) => {
    setRegexObject(regex);
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();

    const campaignObject: CampaignCreateObject = {
      campaignName: campaignName,
      accountId: accounts ? accounts.id : null,
      domain: domain,
      tracking_url: trackingLink,
      currency: currency,
      startDate: startDate,
      endDate: endDate,
      redirectPercent: sliderValue,
      strategy: strategies ? strategies.id : null,
      campaignsCategories:
        categories.length > 0 ? categories.map((cat) => cat.id) : null,
      campaignsGeos: geos.length > 0 ? geos : null,
      urlpartsBlacklist: blacklist.split(','),
      geosBlacklist: blacklistGeos,
      capClicks: capClicks,
      capConv: capConv,
      mobileOnly: isMobile ? 1 : 0,
      nextSession: isNextSession ? 1 : 0,
    };
    if (
      authState.currentUser?.role.id === rolesDictionary.admin ||
      authState.currentUser?.role.id === rolesDictionary.techAccountManager
    ) {
      campaignObject.regexUrls = regexText;
    }
    const errors: string[] = createCampaignFormIsValid(campaignObject);
    if (errors.length > 0) {
      const text = errors.join(',');
      dispatch(campaignCreateFail(true, text));
      return;
    }
    dispatch(createCampaign(authState.currentUser!.token, campaignObject));
  };

  const error =
    !isCampaignNameValid(campaignName) &&
    !isURLValid(domain) &&
    !isURLValid(trackingLink);
  const onClearError = () => {
    dispatch(campaignCreateErrorClear());
  };
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Create new Campaign
        </Typography>
      </div>
      <Paper>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='tabs'
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab label='Campaign' />
          <Tab label='Traffic' />
          <Tab label='Traffic Cap' />
          {authState.currentUser?.role.id === rolesDictionary.admin && (
            <Tab label='Settings' />
          )}
        </Tabs>
      </Paper>
      <form className={styles.form} onSubmit={formSubmit} noValidate={true}>
        <TabPanel index={0} value={tabValue}>
          <CampaignTab
            accounts={accounts}
            campaignName={campaignName}
            categories={categories}
            domain={domain}
            currency={currency}
            loading={campaignState.createLoading}
            onChangeCampName={onChangeCampaignName}
            onChangeAccName={onChangeAccounts}
            onChangeCategories={onChangeCategories}
            onChangeCurrency={onChangeCurrency}
            onChangeDomain={onChangeDomain}
            userToken={authState.currentUser?.token!}
          />
        </TabPanel>

        <TabPanel index={1} value={tabValue}>
          <TrafficTab
            loading={campaignState.createLoading}
            trackingLink={trackingLink}
            onChangeTrackingLink={onChangeTrackingLink}
            startDate={startDate}
            onChageStartDate={onChangeStartDate}
            endDate={endDate}
            onChangeEndDate={onChangeEndDate}
            sliderValue={sliderValue}
            onChageSlider={onChangeSlider}
            strategies={strategies}
            onChangeStrategies={onChangeStrategies}
            geos={geos}
            onChangeGeo={onChangeGeos}
            blacklistGeos={blacklistGeos}
            onChangeBlacklistGeo={onChangeBlackListGeos}
            token={authState.currentUser?.token!}
          />
        </TabPanel>

        <TabPanel index={2} value={tabValue}>
          <TrafficCapTab
            blacklist={blacklist}
            capClicks={capClicks}
            capConv={capConv}
            loading={campaignState.createLoading}
            onChangeBlacklist={onChangeBlackList}
            onChangeCapClicks={onChangeCapClicks}
            onChangeCapConv={onChangeCapConv}
          />
        </TabPanel>
        {authState.currentUser?.role.id === rolesDictionary.admin && (
          <TabPanel index={3} value={tabValue}>
            <SettingsTab
              loading={campaignState.createLoading}
              regexText={regexText}
              isNextSession={isNextSession}
              isMobile={isMobile}
              onChangeIsMobile={onChangeIsMobile}
              onChangeIsNextSession={onChangeIsNextSession}
              onChangeRegexText={onChangeRegexText}
            />
          </TabPanel>
        )}

        <Button
          className={styles.button}
          variant='contained'
          color='primary'
          disabled={
            error || campaignState.createLoading || campaignState.createError
          }
        >
          {campaignState.createLoading ? <CircularProgress /> : 'Submit'}
        </Button>
      </form>
      <Snackbar
        open={campaignState.createError || Boolean(campaignState.created)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          className={styles.errorMessageValidation}
          severity={campaignState.createError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {campaignState.createError
            ? campaignState.createErrorMessage
            : 'Campaign created!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateCampaign;
