import React from 'react';
import Auth from '../Auth/Auth';
import AuthTokenCheck from '../Auth/HoC/AuthTokenCheck';
import AccountsPage from '../Accounts/AccountsPage';
import GeosPage from '../Geos/GeosPage';
import CategoriesPage from '../Categories/CategoriesPage';
import StrategiesPage from '../Strategies/StrategiesPage';
import UsersPage from '../Users/UsersPage';
import CampaignsPage from '../Campaigns/CampaignsPage';
import routesPermissions from '../shared/permissions/permissions';
import SingleCampaignPage from '../Campaigns/SingleCampaignPage/SingleCampaignPage';
export const publicRoutes = [
  {
    link: '/signIn',
    component: <Auth type='sign in' />,
  },
  {
    link: '/reset-password-email',
    component: <Auth type='reset send email' />,
  },
  {
    link: '/reset-password/:id/:token',
    component: (
      <AuthTokenCheck>
        <Auth type='reset password' />
      </AuthTokenCheck>
    ),
  },
];

export const privateRoutes = [
  {
    link: '/dashboard/accounts',
    component: <AccountsPage />,
    permission: routesPermissions.seeAccounts,
  },
  {
    link: '/dashboard/campaigns',
    component: <CampaignsPage />,
    permission: routesPermissions.seeCampaigns,
  },

  {
    link: '/dashboard/geos',
    component: <GeosPage />,
    permission: routesPermissions.seeGeos,
  },
  {
    link: '/dashboard/categories',
    component: <CategoriesPage />,
    permission: routesPermissions.seeCategories,
  },
  {
    link: '/dashboard/strategies',
    component: <StrategiesPage />,
    permission: routesPermissions.seeStrategies,
  },
  {
    link: '/dashboard/users',
    component: <UsersPage />,
    permission: routesPermissions.seeUsers,
  },
  {
    link: '/dashboard/events/campaigns/:campaignId',
    component: <SingleCampaignPage />,
    permission: routesPermissions.seeCampaigns,
  },
];
