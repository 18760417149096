import { Button, Skeleton } from '@material-ui/core';
import React from 'react';

const EditAccountSkeleton: React.FC = () => {
  return (
    <div>
      <Skeleton />
      <Skeleton />
      <Skeleton style={{ width: '150px', marginTop: '1rem' }} />
      <Skeleton style={{ width: '120px' }} />
      <div style={{ marginTop: '53px' }}>
        <Skeleton style={{ width: '250px' }} />
        <Skeleton style={{ width: '250px' }} />
        <Skeleton style={{ width: '250px' }} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Skeleton style={{ marginRight: '1.25rem' }}>
          <Button variant='contained' color='primary'>
            Submit
          </Button>
        </Skeleton>
        <Skeleton>
          <Button variant='contained' color='primary'>
            Submit
          </Button>
        </Skeleton>
      </div>
    </div>
  );
};
export default EditAccountSkeleton;
