import TableSkeleton from '../../../shared/TableSkeleton/TableSkeleton';
import { SingleCampaignEventApi } from '../../campaignsTypes';
import TableComponent from '../../../shared/TableComponent/TableComponent';
import tableDefinition from './tableDefinition';
import {
  singleCampaignEventLimitChange,
  singleCampaignEventPageChange,
} from '../../campaignsActions';
import { useDispatch } from 'react-redux';
interface Props {
  campaignEvents: SingleCampaignEventApi | null;
  loading: boolean;
  error: boolean;
  limit: number;
  amount: number;
  page: number;
}
const SingleCampaignTable: React.FC<Props> = (props) => {
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Handles page change.
  const onChangePage = (page: number) => {
    dispatch(singleCampaignEventPageChange(page));
  };
  //Handles limit change.
  const onChangeLimit = (limit: number) => {
    dispatch(singleCampaignEventLimitChange(limit));
  };
  let tableData = <TableSkeleton />;
  if (!props.loading && props.campaignEvents && !props.error) {
    tableData = (
      <TableComponent
        amount={props.amount}
        items={props.campaignEvents.events.map((el, ind) => ({
          ...el,
          id: ind,
        }))}
        tableDefinition={tableDefinition}
        error={props.error}
        limit={props.limit}
        loading={props.loading}
        name={'Events'}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
        page={props.page}
      />
    );
  }

  return tableData;
};

export default SingleCampaignTable;
