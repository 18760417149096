import { FormLabel, Slider, TextField, useTheme } from '@material-ui/core';
import DateTimePickers from 'react-datetime-picker';
import React, { useState } from 'react';
import AutoCompleteInput from '../../../Inputs/AutoCompleteInput/AutoCompleteInput';
import { isURLValid } from '../../../Validation/validation';
import createAccountStyles from '../../createCampaignStyles';
import { ArrowDropDown } from '@material-ui/icons';
import { GeosApi } from '../../../../Geos/geosTypes';

interface ObjValue {
  id: number;
  name: string;
}
interface Props {
  loading: boolean;
  disabled?: boolean;
  token: string;
  trackingLink: string;
  startDate: Date | '';
  endDate: Date | '';
  sliderValue: number;
  strategies: ObjValue | null;
  geos: GeosApi[];
  blacklistGeos: GeosApi[];
  onChangeTrackingLink: (trackingLink: string) => void;
  onChageStartDate: (date: Date) => void;
  onChangeEndDate: (date: Date) => void;
  onChageSlider: (number: number) => void;
  onChangeStrategies: (strategy: ObjValue) => void;
  onChangeGeo: (geos: GeosApi[]) => void;
  onChangeBlacklistGeo: (geos: GeosApi[]) => void;
}

const useStyles = createAccountStyles;
const TrafficTab = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  const [touchedTracking, setTouchedTracking] = useState(false);
  const onBlurTrackingLink = () => setTouchedTracking(true);
  const trackingLinkError = touchedTracking && !isURLValid(props.trackingLink);

  return (
    <>
      <TextField
        fullWidth={true}
        disabled={props.loading || props.disabled}
        type='url'
        name='tracking_link'
        value={props.trackingLink}
        onChange={(event) => props.onChangeTrackingLink(event.target.value)}
        onBlur={onBlurTrackingLink}
        error={trackingLinkError}
        className={styles.inputs}
        label='Tracking link'
        variant='standard'
        helperText={
          trackingLinkError ? 'Check if it has https or it is a valid url' : ' '
        }
        required
      />
      <div className={styles.datesContainer}>
        <div>
          Start Date
          <DateTimePickers
            required={false}
            dayAriaLabel='day start date'
            monthAriaLabel='month start date'
            yearAriaLabel='year start date'
            calendarAriaLabel='Toggle calendar start date'
            hourAriaLabel='hour start date'
            minuteAriaLabel='minutes start date'
            amPmAriaLabel='Select AM/PM start date'
            nativeInputAriaLabel='start date'
            className={styles.datPicker}
            minDetail='year'
            format='dd/MM/yyyy:h:mm:a'
            isClockOpen={false}
            calendarIcon={<ArrowDropDown />}
            clearIcon={null}
            value={props.startDate}
            onChange={props.onChageStartDate}
            disabled={props.loading || props.disabled}
          />
        </div>
        <div>
          End Date
          <DateTimePickers
            required={false}
            dayAriaLabel='day end date'
            monthAriaLabel='month end date'
            yearAriaLabel='year end date'
            calendarAriaLabel='Toggle calendar end date'
            hourAriaLabel='hour end date'
            minuteAriaLabel='minutes end date'
            amPmAriaLabel='Select AM/PM end date'
            nativeInputAriaLabel='end date'
            className={styles.datPicker}
            minDetail='year'
            format='dd/MM/yyyy:h:mm:a'
            isClockOpen={false}
            calendarIcon={<ArrowDropDown />}
            value={props.endDate}
            onChange={props.onChangeEndDate}
            disabled={props.loading || props.disabled}
          />
        </div>

        <span style={{ color: theme.palette.secondary.main }}>
          *if no end date leave it blank
        </span>
      </div>
      {!props.disabled && (
        <>
          <div className={styles.redirectWrapper}>
            <FormLabel component='label'>Redirect percent</FormLabel>
            <Slider
              aria-labelledby='redirect-slider'
              value={props.sliderValue}
              max={100}
              step={0.1}
              onChange={(_event: any, newValue) =>
                props.onChageSlider(newValue as number)
              }
              valueLabelDisplay='auto'
              disabled={props.loading || props.disabled}
            />
          </div>
          <AutoCompleteInput
            required={true}
            fullWidth={true}
            multiple={false}
            type='strategy'
            variant={'standard'}
            token={props.token}
            label='Strategy'
            changeValue={(_event, value: ObjValue) =>
              props.onChangeStrategies(value)
            }
            defaultValue={props.strategies}
            shouldRender={true}
            disabled={props.loading || props.disabled}
          />
        </>
      )}

      <AutoCompleteInput
        required={true}
        fullWidth={true}
        multiple={true}
        type='geo'
        variant={'standard'}
        token={props.token}
        label='Geos'
        changeValue={(_event, values: GeosApi[]) => props.onChangeGeo(values)}
        defaultValue={props.geos}
        shouldRender={true}
        disabled={props.loading || props.disabled}
      />
      <AutoCompleteInput
        required={true}
        fullWidth={true}
        multiple={true}
        type='geo'
        variant={'standard'}
        token={props.token}
        label='Blacklist Geos'
        changeValue={(_event, values: GeosApi[]) =>
          props.onChangeBlacklistGeo(values)
        }
        defaultValue={props.blacklistGeos}
        shouldRender={true}
        disabled={props.loading || props.disabled}
      />
    </>
  );
};
export default TrafficTab;
