import React, { useState, FormEvent, useEffect } from 'react';
import {
  Typography,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Paper,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import rolesDictionary from '../../shared/permissions/rolesDictionary';
import createCampaignStyles from '../CreateCampaign/createCampaignStyles';
import {
  deleteCampaign,
  deleteCampaignErrorClear,
  editCampaign,
  editCampaignErrorClear,
  editCampaignFail,
  getCampaigns,
  getSingleCampaign,
  singleCampaignErrorClear,
} from '../campaignsActions';

import EditAccountSkeleton from './EditCampaignSkeleton';
import DeleteDialog from '../../shared/DeleteDialog/DeleteDialog';

import { GeosApi } from '../../Geos/geosTypes';
import { EditCampaignObject } from '../campaignsTypes';
import TabPanel from '../CreateCampaign/TabPanel/TabPanel';
import CampaignTab from '../CreateCampaign/TabPanel/Tabs/CampaignTab';
import TrafficCapTab from '../CreateCampaign/TabPanel/Tabs/TrafficCapTab';
import TrafficTab from '../CreateCampaign/TabPanel/Tabs/TrafficTab';
import TagInformationTab from '../CreateCampaign/TabPanel/Tabs/TagInformationTab';
import EditCampaignError from './EditCampaignError/EditCampaignError';
import {
  createCampaignFormIsValid,
  isCampaignNameValid,
  isURLValid,
} from '../Validation/validation';
import SettingsTab from '../CreateCampaign/TabPanel/Tabs/SettingsTab';

const useStyles = createCampaignStyles;

interface Props {
  campaignId: number;
  closeModal: () => void;
  closeMenu: () => void;
}

const EditCampaign: React.FC<Props> = (props) => {
  const authState = useSelector((state: AppState) => state.auth);
  const campaignTableState = useSelector((state: AppState) => state.campaigns);
  const dispatch = useDispatch();
  const styles = useStyles();

  useEffect(() => {
    dispatch(getSingleCampaign(authState.currentUser!.token, props.campaignId));
  }, [
    dispatch,
    authState.currentUser,
    props.campaignId,
    campaignTableState.editError,
  ]);

  useEffect(() => {
    if (props.campaignId === campaignTableState.singleCampaign?.id) {
      if (campaignTableState.singleCampaign?.campaignName) {
        setCampaignName(campaignTableState.singleCampaign!.campaignName);
      }
      if (campaignTableState.singleCampaign?.account) {
        setAccounts(campaignTableState.singleCampaign!.account);
      }
      if (campaignTableState.singleCampaign?.domain) {
        setDomain(campaignTableState.singleCampaign!.domain);
      }
      if (campaignTableState.singleCampaign?.tracking_url) {
        setTrackingLink(campaignTableState.singleCampaign!.tracking_url);
      }
      if (campaignTableState.singleCampaign?.currency) {
        setCurrency(campaignTableState.singleCampaign!.currency);
      }
      if (campaignTableState.singleCampaign?.startDate) {
        setStartDate(new Date(campaignTableState.singleCampaign.startDate));
      }
      if (campaignTableState.singleCampaign?.endDate) {
        setEndDate(new Date(campaignTableState.singleCampaign.endDate));
      }
      if (campaignTableState.singleCampaign?.redirectPercent) {
        setSliderValue(campaignTableState.singleCampaign!.redirectPercent);
      }
      if (campaignTableState.singleCampaign?.strategyId) {
        setStrategies(campaignTableState.singleCampaign.strategy);
      }
      if (campaignTableState.singleCampaign?.campaignsCategories) {
        const cats = campaignTableState.singleCampaign.campaignsCategories.map(
          (value) => value.categories
        );
        setCategories(cats);
      }
      if (campaignTableState.singleCampaign?.campaignsGeos) {
        const geos = campaignTableState.singleCampaign.campaignsGeos.map(
          (value) => value.geos
        );
        setGeos(geos);
      }
      if (campaignTableState.singleCampaign?.geosBlacklist) {
        setBlacklistGeos(campaignTableState.singleCampaign.geosBlacklist);
      }
      if (campaignTableState.singleCampaign?.capClicks) {
        setCapClicks(campaignTableState.singleCampaign.capClicks);
      }
      if (campaignTableState.singleCampaign?.capConv) {
        setCapConv(campaignTableState.singleCampaign.capConv);
      }
      if (campaignTableState.singleCampaign?.urlpartsBlacklist) {
        setBlacklist(
          campaignTableState.singleCampaign.urlpartsBlacklist.join(',')
        );
      }
      if (
        campaignTableState.singleCampaign?.mobileOnly === 0 ||
        campaignTableState.singleCampaign?.mobileOnly === 1
      ) {
        setIsMobile(
          campaignTableState.singleCampaign.mobileOnly ? true : false
        );
      }
      if (
        campaignTableState.singleCampaign?.nextSession === 0 ||
        campaignTableState.singleCampaign?.nextSession === 1
      ) {
        setIsNextSession(
          campaignTableState.singleCampaign.nextSession ? true : false
        );
      }

      if (
        campaignTableState.singleCampaign?.regexUrls ||
        typeof campaignTableState.singleCampaign?.regexUrls === 'string'
      ) {
        setRegexObject(campaignTableState.singleCampaign.regexUrls);
      }
    }
  }, [
    props.campaignId,
    campaignTableState.singleCampaign,
    campaignTableState.singleCampaign?.capClicks,
    campaignTableState.singleCampaign?.capConv,
    campaignTableState.singleCampaign?.regexUrls,
  ]);

  const [campaignName, setCampaignName] = useState('');
  const onChangeCampaignName = (campaignName: string) => {
    setCampaignName(campaignName);
  };

  const [domain, setDomain] = useState('');
  const onChangeDomain = (domain: string) => {
    setDomain(domain);
  };

  const [trackingLink, setTrackingLink] = useState('');
  const onChangeTrackingLink = (tracking: string) => {
    setTrackingLink(tracking);
  };

  const [currency, setCurrency] = useState('');
  const onChangeCurrency = (currency: string) => {
    setCurrency(currency);
  };

  const [startDate, setStartDate] = useState<Date | ''>('');

  const onChangeStartDate = (value: Date) => {
    setStartDate(value);
  };

  const [endDate, setEndDate] = useState<Date | ''>('');
  const onChangeEndDate = (value: Date) => {
    setEndDate(value);
  };

  const [sliderValue, setSliderValue] = useState<number>(1.2);
  const onChangeSlider = (newValue: number) => {
    setSliderValue(newValue);
  };

  const [capClicks, setCapClicks] = useState<'' | number>('');
  const onChangeCapClicks = (cap: string) => {
    if (!isNaN(parseInt(cap))) {
      setCapClicks(parseInt(cap));
    } else {
      setCapClicks('');
    }
  };

  const [capConv, setCapConv] = useState<'' | number>('');
  const onChangeCapConv = (cap: string) => {
    if (!isNaN(parseInt(cap))) {
      setCapConv(parseInt(cap));
    } else {
      setCapConv('');
    }
  };

  type ObjValue = { id: number; name: string };
  const [accounts, setAccounts] = useState<ObjValue | null>(null);
  const onChangeAccounts = (account: ObjValue) => {
    setAccounts(account);
  };
  const [categories, setCategories] = useState<ObjValue[]>([]);
  const onChangeCategories = (categories: ObjValue[]) => {
    setCategories(categories);
  };

  const [strategies, setStrategies] =
    useState<{
      id: number;
      name: string;
    } | null>(null);

  const onChangeStrategies = (strategy: ObjValue) => {
    setStrategies(strategy);
  };

  const [geos, setGeos] = useState<GeosApi[]>([]);

  const onChangeGeos = (geos: GeosApi[]) => {
    setGeos(geos);
  };
  const [blacklistGeos, setBlacklistGeos] = useState<GeosApi[]>([]);

  const onChangeBlackListGeos = (geos: GeosApi[]) => {
    setBlacklistGeos(geos);
  };
  const [blacklist, setBlacklist] = useState('');
  const onChangeBlackList = (blacklist: string) => {
    setBlacklist(blacklist);
  };

  const [isMobile, setIsMobile] = useState(false);
  const onChangeIsMobile = (checked: boolean) => {
    setIsMobile(checked);
  };

  const [isNextSession, setIsNextSession] = useState(false);
  const onChangeIsNextSession = (checked: boolean) => {
    setIsNextSession(checked);
  };

  const [regexText, setRegexObject] = useState('');
  const onChangeRegexText = (regex: string) => {
    setRegexObject(regex);
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();

    const campaignObject: EditCampaignObject = {
      campaignName: campaignName,
      accountId: accounts!.id,
      domain: domain,
      tracking_url: trackingLink,
      currency: currency,
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      redirectPercent: sliderValue,
      strategy: strategies ? strategies.id : null,
      campaignsCategories:
        categories.length > 0 ? categories.map((cat) => cat.id) : null,
      campaignsGeos: geos.length > 0 ? geos : null,
      urlpartsBlacklist: blacklist.split(','),
      geosBlacklist: blacklistGeos,
      capConv: capConv,
      capClicks: capClicks,
      mobileOnly: isMobile ? 1 : 0,
      nextSession: isNextSession ? 1 : 0,
      voluumOfferId: campaignTableState.singleCampaign?.voluumOfferId,
    };
    if (
      authState.currentUser?.role.id === rolesDictionary.admin ||
      authState.currentUser?.role.id === rolesDictionary.techAccountManager
    ) {
      campaignObject.regexUrls = regexText;
    }
    const errors: string[] = createCampaignFormIsValid(campaignObject);
    if (errors.length > 0) {
      const text = errors.join(',');
      dispatch(editCampaignFail(true, text));
      return;
    }
    campaignObject.deleteCategories =
      campaignTableState.singleCampaign?.campaignsCategories
        .filter(
          (cat) =>
            !campaignObject.campaignsCategories?.includes(cat.categories.id)
        )
        .map((el) => el.categories.id);
    campaignObject.deleteGeos = campaignTableState.singleCampaign?.campaignsGeos
      .filter(
        (geo) =>
          !campaignObject.campaignsGeos
            ?.map((oldGeos) => oldGeos.idd)
            .includes(geo.geos.idd)
      )
      .map((el) => el.geos);
    campaignObject.campaignsCategories =
      campaignObject.campaignsCategories!.filter((newCats) => {
        const oldCats =
          campaignTableState.singleCampaign?.campaignsCategories.map(
            (oldCat) => oldCat.categories.id
          );
        if (oldCats?.includes(newCats)) {
          return false;
        } else {
          return newCats;
        }
      });
    campaignObject.campaignsGeos = campaignObject?.campaignsGeos!.filter(
      (newGeo) => {
        const oldGeos = campaignTableState.singleCampaign?.campaignsGeos.map(
          (oldGeo) => oldGeo.geos.idd
        );
        if (oldGeos?.includes(newGeo.idd)) {
          return false;
        } else {
          return newGeo;
        }
      }
    );

    dispatch(
      editCampaign(
        authState.currentUser!.token,
        campaignObject,
        campaignTableState.singleCampaign!.id
      )
    );
  };

  const error =
    !isURLValid(trackingLink) &&
    !isCampaignNameValid(campaignName) &&
    !isURLValid(domain);

  const onClearEditError = () => {
    dispatch(editCampaignErrorClear());
    if (!campaignTableState.editError) {
      props.closeMenu();
      props.closeModal();
      getsCampaigns();
    }
  };
  const onDeleteCampaign = () => {
    dispatch(
      deleteCampaign(
        authState.currentUser!.token,
        campaignTableState.singleCampaign!.id
      )
    );
  };
  const onClearErrorDelete = () => {
    dispatch(deleteCampaignErrorClear());
    props.closeMenu();
    props.closeModal();
    getsCampaigns();
  };
  const getsCampaigns = () => {
    dispatch(
      getCampaigns(
        authState.currentUser!.token,
        campaignTableState.page,
        campaignTableState.limit,
        campaignTableState.orderBy,
        campaignTableState.orderDirection,
        campaignTableState.dateFrom,
        campaignTableState.dateTo,
        campaignTableState.search,
        campaignTableState.active,
        campaignTableState.categories,
        campaignTableState.geos,
        campaignTableState.accounts,
        campaignTableState.strategies
      )
    );
  };
  const onClearSingleCamp = () => {
    dispatch(singleCampaignErrorClear());
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const acceptDeleteDialog = () => {
    setOpenDeleteDialog(false);
    onDeleteCampaign();
  };
  const rejectDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  let editBody = (
    <>
      <Paper>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label='tabs'
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab label='Campaign' />
          <Tab label='Traffic' />
          <Tab label='Traffic Cap' />
          <Tab label='Tags' />
          {(authState.currentUser?.role.id === rolesDictionary.admin ||
            authState.currentUser?.role.id ===
              rolesDictionary.techAccountManager) && <Tab label='Settings' />}
        </Tabs>
      </Paper>
      <form className={styles.form} onSubmit={formSubmit} noValidate={true}>
        <TabPanel index={0} value={tabValue}>
          <CampaignTab
            accounts={accounts}
            campaignName={campaignName}
            categories={categories}
            currency={currency}
            domain={domain}
            loading={campaignTableState.editLoading}
            onChangeAccName={onChangeAccounts}
            onChangeCampName={onChangeCampaignName}
            onChangeCategories={onChangeCategories}
            onChangeCurrency={onChangeCurrency}
            onChangeDomain={onChangeDomain}
            userToken={authState.currentUser?.token!}
            disabled={authState.currentUser!.role.id === rolesDictionary.client}
          />
        </TabPanel>

        <TabPanel index={1} value={tabValue}>
          <TrafficTab
            loading={campaignTableState.editLoading}
            startDate={startDate}
            endDate={endDate}
            geos={geos}
            blacklistGeos={blacklistGeos}
            sliderValue={sliderValue}
            onChageSlider={onChangeSlider}
            strategies={strategies}
            trackingLink={trackingLink}
            onChageStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            onChangeBlacklistGeo={onChangeBlackListGeos}
            onChangeGeo={onChangeGeos}
            onChangeStrategies={onChangeStrategies}
            onChangeTrackingLink={onChangeTrackingLink}
            token={authState.currentUser?.token!}
            disabled={authState.currentUser!.role.id === rolesDictionary.client}
          />
        </TabPanel>

        <TabPanel index={2} value={tabValue}>
          <TrafficCapTab
            blacklist={blacklist}
            capClicks={capClicks}
            capConv={capConv}
            loading={campaignTableState.editLoading}
            onChangeBlacklist={onChangeBlackList}
            onChangeCapClicks={onChangeCapClicks}
            onChangeCapConv={onChangeCapConv}
            disabled={authState.currentUser!.role.id === rolesDictionary.client}
          />
        </TabPanel>

        <TabPanel index={3} value={tabValue}>
          <TagInformationTab
            tags={campaignTableState.singleCampaign?.tags}
            name={campaignTableState.singleCampaign?.campaignName}
          />
        </TabPanel>
        {(authState.currentUser?.role.id === rolesDictionary.admin ||
          authState.currentUser?.role.id ===
            rolesDictionary.techAccountManager) && (
          <TabPanel index={4} value={tabValue}>
            <SettingsTab
              loading={campaignTableState.editLoading}
              regexText={regexText}
              isNextSession={isNextSession}
              isMobile={isMobile}
              onChangeIsMobile={onChangeIsMobile}
              onChangeIsNextSession={onChangeIsNextSession}
              onChangeRegexText={onChangeRegexText}
            />
          </TabPanel>
        )}

        {authState.currentUser!.role.id !== rolesDictionary.client && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={deleteDialog}
              type='button'
              variant='text'
              color='primary'
              style={{ marginRight: '1.25rem' }}
              disabled={
                error ||
                campaignTableState.editLoading ||
                campaignTableState.editError ||
                campaignTableState.deleteCampaignLoading ||
                campaignTableState.deleteCampaignError
              }
            >
              <Delete style={{ marginRight: '0.3125rem' }} /> Delete
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={
                error ||
                campaignTableState.editLoading ||
                campaignTableState.editError ||
                campaignTableState.deleteCampaignLoading ||
                campaignTableState.deleteCampaignError ||
                authState.currentUser!.role.id === rolesDictionary.client
              }
            >
              {campaignTableState.editLoading ? <CircularProgress /> : 'Submit'}
            </Button>
          </div>
        )}
      </form>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          {authState.currentUser!.role.id !== rolesDictionary.client
            ? 'Edit Campaign'
            : 'View Campaign'}
        </Typography>
      </div>

      {!campaignTableState.singleCampaignLoading && editBody}
      {campaignTableState.singleCampaignLoading && <EditAccountSkeleton />}
      <EditCampaignError
        editError={campaignTableState.editError}
        editCampaignInfo={Boolean(campaignTableState.editCampaignInfo)}
        editErrorMsg={campaignTableState.editErrorMessage}
        onClearEditError={onClearEditError}
        deleteCampaign={Boolean(campaignTableState.deletedCampaign)}
        deleteError={campaignTableState.deleteCampaignError}
        deleteErrMsg={campaignTableState.deletedCampaignErrorMessage}
        onClearDeleteError={onClearErrorDelete}
        singleCampaignError={campaignTableState.singleCampaignError}
        singleCampErrMsg={campaignTableState.singleCampaignErrorMsg}
        onClearErrorSingleCamp={onClearSingleCamp}
      />

      <DeleteDialog
        title='Delete Campaign'
        bodyText='Do you want to delete this campaign?'
        open={openDeleteDialog}
        handleAcceptance={acceptDeleteDialog}
        handleRejection={rejectDeleteDialog}
      />
    </div>
  );
};

export default EditCampaign;
