import React, {
  ChangeEvent,
  useState,
  FocusEvent,
  FormEvent,
  useEffect,
  useRef,
} from 'react';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  ContentCopy,
  Delete,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createUserStyles from '../CreateUser/createUserStyles';
import {
  deleteUser,
  deleteUserErrorClear,
  editUser,
  editUserErrorClear,
  getUsers,
  getSingleUser,
  singleUserErrorClear,
} from '../usersActions';
import { Close } from '@material-ui/icons';
import EditUserSkeleton from './EditUserSkeleton';
import DeleteDialog from '../../shared/DeleteDialog/DeleteDialog';
import { getRolesIputApi, getStatusInputApi } from '../usersApi';

const useStyles = createUserStyles;

interface Props {
  userId: number;
  closeModal: () => void;
  closeMenu: () => void;
}
const EditUser: React.FC<Props> = (props) => {
  const authState = useSelector((state: AppState) => state.auth);
  const userState = useSelector((state: AppState) => state.users);
  const dispatch = useDispatch();
  const styles = useStyles();
  useEffect(() => {
    dispatch(getSingleUser(authState.currentUser!.token, props.userId));
  }, [dispatch, authState.currentUser, props.userId]);

  useEffect(() => {
    let unmounted = false;
    const getStatus = async () => {
      try {
        const fetchedStatus = await getStatusInputApi(
          authState.currentUser!.token
        );
        if (!unmounted) {
          setStatusOptions(fetchedStatus.status);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const getRoles = async () => {
      try {
        const fetchedRoles = await getRolesIputApi(
          authState.currentUser!.token
        );
        if (!unmounted) {
          setRolesOptions(fetchedRoles.roles);
        }
      } catch (err) {}
    };
    getStatus();
    getRoles();
    return () => {
      unmounted = true;
    };
  }, [authState.currentUser]);

  const [name, setName] = useState({
    value: userState.singleUser.name,
    error: false,
  });
  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName((prev) => ({ ...prev, value: event.target.value }));
  };
  const onBlurName = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 3) {
      setName((prev) => ({ ...prev, error: true }));
    } else {
      setName((prev) => ({ ...prev, error: false }));
    }
  };

  const [lastName, setLastName] = useState({ value: '', error: false });
  const onChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName((prev) => ({ ...prev, value: event.target.value }));
  };
  const onBlurLastName = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 3) {
      setLastName((prev) => ({ ...prev, error: true }));
    } else {
      setLastName((prev) => ({ ...prev, error: false }));
    }
  };
  const [email, setEmail] = useState({ value: '', error: false });
  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail((prev) => ({ ...prev, value: event.target.value }));
  };
  const onBlurEmail = (event: FocusEvent<HTMLInputElement>) => {
    let res = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    setEmail((prev) => ({ ...prev, error: !res.test(event.target.value) }));
  };

  const [password, setPassword] = useState({
    value: '',
    error: false,
    show: false,
  });
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword((prev) => ({ ...prev, value: event.target.value }));
  };
  const onBlurPassword = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 6) {
      setPassword((prev) => ({ ...prev, error: true }));
    } else {
      setPassword((prev) => ({ ...prev, error: false }));
    }
  };
  const showPassword = () => {
    setPassword((prev) => ({ ...prev, show: !prev.show }));
  };
  const copyPassword = async () => {
    await navigator.clipboard.writeText(password.value);
  };
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  const [rolesOptions, setRolesOptions] = useState<
    { id: number; name: string }[]
  >([]);
  useEffect(() => {
    if (
      rolesOptions.length > 0 &&
      statusOptions.length > 0 &&
      userState.singleUser.roleId !== 0
    ) {
      setName({ error: false, value: userState.singleUser.name });
      setLastName({
        error: false,
        value: userState.singleUser.lastName
          ? userState.singleUser.lastName
          : '',
      });
      setEmail({ error: false, value: userState.singleUser.email });
      setStatus(userState.singleUser.userStatus);
      setRole(userState.singleUser.roleId.toString());
      if (Boolean(userState.singleUser.profilePicture)) {
        setPicture({
          file: new File([], 'dontTake'),
          url: userState.singleUser.profilePicture
            ? '/images/' + userState.singleUser.profilePicture
            : '',
        });
      }
    }
  }, [props.userId, userState.singleUser, rolesOptions, statusOptions]);
  const [status, setStatus] = useState('');
  const onChangeStatus = (
    event: ChangeEvent<{ name?: string | undefined; value: string }>
  ) => {
    setStatus(event.target.value);
  };
  const [role, setRole] = useState('');
  const onChangeRole = (
    event: ChangeEvent<{ name?: string | undefined; value: string }>
  ) => {
    setRole(event.target.value);
  };

  const fileUploader = useRef<HTMLInputElement>(null);
  const upload = () => {
    if (fileUploader.current) {
      fileUploader.current.click();
    }
  };
  const [picture, setPicture] = useState<{ file: File; url: string } | null>(
    null
  );
  const onChangeFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files[0]) {
        setPicture({
          file: event.target.files[0],
          url: URL.createObjectURL(event.target.files[0]),
        });
      } else {
        setPicture(null);
      }
    }
  };
  const formSubmit = (event: FormEvent) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', name.value);
    formData.append('lastName', lastName.value);
    formData.append('email', email.value);
    formData.append('password', password.value);
    formData.append('status', status);
    formData.append('roleId', role);
    if (picture?.file && picture.file.name !== 'dontTake') {
      formData.append('profilePicture', picture.file);
    }
    dispatch(
      editUser(authState.currentUser!.token, formData, userState.singleUser.id)
    );
  };

  const error = name.error || lastName.error || email.error;
  const onClearError = () => {
    dispatch(editUserErrorClear());
    if (!userState.editError) {
      props.closeMenu();
      props.closeModal();
      dispatch(
        getUsers(
          authState.currentUser!.token,
          userState.page,
          userState.limit,
          userState.search
        )
      );
    }
  };

  const deleteUsers = () => {
    dispatch(deleteUser(authState.currentUser!.token, userState.singleUser.id));
  };
  const clearErrorDelete = () => {
    dispatch(deleteUserErrorClear());
    props.closeMenu();
    props.closeModal();
    dispatch(
      getUsers(
        authState.currentUser!.token,
        userState.page,
        userState.limit,
        userState.search
      )
    );
  };
  const clearErrorSingleUser = () => {
    dispatch(singleUserErrorClear());
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const acceptDeleteDialog = () => {
    setOpenDeleteDialog(false);
    deleteUsers();
  };
  const rejectDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Edit User
        </Typography>
      </div>
      {!userState.singleUserLoading && (
        <form className={styles.form} onSubmit={formSubmit} autoComplete='off'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              value={name.value}
              name='user-name'
              onChange={onChangeName}
              onBlur={onBlurName}
              error={name.error}
              className={`${styles.inputs} ${styles.firstInputText}`}
              label='First name'
              variant='standard'
              helperText={name.error ? 'No less than 3 characers' : ' '}
            />
            <TextField
              value={lastName.value}
              name='user-last-name'
              onChange={onChangeLastName}
              onBlur={onBlurLastName}
              error={lastName.error}
              className={`${styles.inputs} ${styles.firstInputText}`}
              label='Last name'
              variant='standard'
              helperText={lastName.error ? 'No less than 3 characers' : ' '}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              type='email'
              name='email'
              label='Email'
              variant='standard'
              className={`${styles.inputs} ${styles.firstInputText}`}
              onChange={onChangeEmail}
              onBlur={onBlurEmail}
              value={email.value}
              error={email.error}
              helperText={email.error ? 'Set a valid email' : ' '}
              autoComplete='off'
            />
            <FormControl
              className={`${styles.inputs} ${styles.firstInputText}`}
              error={password.error}
            >
              <InputLabel htmlFor='standard-adornment-password'>
                Password
              </InputLabel>
              <Input
                id='standard-adornment-password'
                type={password.show ? 'text' : 'password'}
                value={password.value}
                onChange={onChangePassword}
                onBlur={onBlurPassword}
                autoComplete='new-password'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={showPassword}
                      type='button'
                      style={{ padding: 5 }}
                    >
                      {!password.show ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={copyPassword}
                      type='button'
                      style={{ padding: 5 }}
                    >
                      <ContentCopy />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {password.error ? 'Min length is 6 characters' : ' '}
              </FormHelperText>
            </FormControl>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControl
              className={`${styles.inputs} ${styles.firstInputText}`}
            >
              <InputLabel required id='user-status'>
                Status
              </InputLabel>
              <Select
                labelId='user-status'
                value={status}
                onChange={onChangeStatus}
              >
                {statusOptions.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              className={`${styles.inputs} ${styles.firstInputText}`}
            >
              <InputLabel required id='user-roles'>
                Roles
              </InputLabel>
              <Select labelId='user-roles' value={role} onChange={onChangeRole}>
                {rolesOptions.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{ display: 'flex', marginTop: '10px' }}>
            <div
              style={{
                width: '100px',
                height: '100px',
                backgroundColor: '#E1E1E1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              {Boolean(picture) && (
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={picture!.url}
                  alt='your-pictures'
                />
              )}
              {!Boolean(picture) && (
                <Button
                  type='button'
                  style={{
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    padding: 0,
                    minWidth: '30px',
                  }}
                  color='secondary'
                  variant='contained'
                  onClick={upload}
                >
                  +
                </Button>
              )}
              <input
                type='file'
                multiple={false}
                accept='image/png,image/jpg,image/jpeg'
                style={{ display: 'none' }}
                ref={fileUploader}
                onChange={onChangeFileUpload}
              ></input>
            </div>
            <div style={{ padding: '10px' }}>
              <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                Profile picture
              </Typography>
              <Typography variant='subtitle2'>
                Upload your profile pictuer.
              </Typography>
              <Typography variant='subtitle2'>
                Maximum size 500px x 500px.
              </Typography>
              <div style={{ display: 'flex' }}>
                {Boolean(picture) && (
                  <Typography
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    variant='subtitle2'
                    onClick={upload}
                    color='secondary'
                  >
                    Change picture.
                  </Typography>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1.25rem',
            }}
          >
            <Button
              onClick={deleteDialog}
              type='button'
              variant='text'
              color='primary'
              style={{ marginRight: '1.25rem' }}
              disabled={
                error ||
                userState.editLoading ||
                userState.editError ||
                userState.deleteUserLoading ||
                userState.deleteUserError
              }
            >
              <Delete style={{ marginRight: '0.3125rem' }} /> Delete
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={
                error ||
                userState.editLoading ||
                userState.editError ||
                userState.deleteUserLoading ||
                userState.deleteUserError
              }
            >
              {userState.editLoading ? <CircularProgress /> : 'Submit'}
            </Button>
          </div>
        </form>
      )}

      {userState.singleUserLoading && <EditUserSkeleton />}

      <Snackbar
        open={userState.editError || Boolean(userState.editUserInfo)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={userState.editError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {userState.editError ? userState.editErrorMessage : 'User edited!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(userState.deletedUser) || userState.deleteUserError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorDelete}
      >
        <Alert
          severity={userState.deleteUserError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorDelete}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {userState.deleteUserError
            ? userState.deleteUserErroMsg
            : 'User Deleted!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={userState.singleUserError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorSingleUser}
      >
        <Alert
          severity='error'
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorSingleUser}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {userState.singleUserError
            ? userState.singleUserErrorMsg
            : 'Cant find user!'}
        </Alert>
      </Snackbar>
      <DeleteDialog
        title='Delete User'
        bodyText='Do you want to delete this user?'
        open={openDeleteDialog}
        handleAcceptance={acceptDeleteDialog}
        handleRejection={rejectDeleteDialog}
      />
    </div>
  );
};

export default EditUser;
