import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './../store/storeTypes';
import { geosErrorClear, getGeos } from './geosActions';
import AppContainer from '../shared/AppContainer/AppContainer';
import PageTitle from '../shared/PageTitle/PageTitle';
import GeoHeader from './GeoHeader/GeoHeader';
import TablesMenu from './../shared/TablesMenu/TablesMenu';
import EditGeo from './EditGeo/EditGeos';
import ModalContainer from './../shared/Modal/ModalContainer';
import CreateGeo from './CreateGeo/CreateGeo';
import GeosTable from './GeosTable/GeosTable';

const GeosPage: React.FC = () => {
  //Authentication state
  const authState = useSelector((state: AppState) => state.auth);
  //Geo state
  const geoState = useSelector((state: AppState) => state.geos);
  //Dispatch hook to dispatch actions
  const dispatch = useDispatch();
  //Fetch data if any filter changes
  useEffect(() => {
    if (!geoState.searching) {
      dispatch(
        getGeos(
          authState.currentUser!.token,
          geoState.page,
          geoState.limit,
          geoState.search
        )
      );
    }
  }, [
    authState.currentUser,
    dispatch,
    geoState.search,
    geoState.page,
    geoState.searching,
    geoState.limit,
  ]);

  //State of the table menu
  const [openTableMenu, setOpenTableMenu] = useState<{
    id: number;
    element: null | HTMLInputElement;
  }>({ id: 0, element: null });

  //Handles the changes of the table checkbox
  const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setOpenTableMenu({
      element: event.target,
      id: parseInt(event.target.value),
    });
  };
  //Closes the menu of the table
  const onCloseTableMenu = () => {
    setOpenTableMenu({ id: 0, element: null });
  };
  //Clears error messages
  const onClearError = () => {
    dispatch(geosErrorClear());
  };
  //State of the modal for edit category
  const [openEditModal, setOpenEditModal] = useState(false);
  //Sets the edit modal to open
  const setOpenToEditModal = () => {
    setOpenEditModal(true);
  };
  //Sets the edit modal to close
  const setCloseToEditModal = () => {
    setOpenEditModal(false);
  };
  //Handles the geos create modal state
  const [createGeoOpen, setCreateGeoOpen] = useState(false);
  //Opens create modal
  const openCreateGeo = () => {
    setCreateGeoOpen(true);
  };
  //Closes create modal
  const closeCreateGeo = () => {
    setCreateGeoOpen(false);
  };

  return (
    <AppContainer>
      <>
        <GeoHeader loading={geoState.loading} search={geoState.search} />
        <main>
          <PageTitle
            buttonText='Create Geo'
            openCreate={openCreateGeo}
            title='Geos'
            userRoleId={authState.currentUser!.role.id}
          />
          <GeosTable
            onChangeCheckbox={onChangeCheckBox}
            onCloseMenu={onCloseTableMenu}
            openTableMenu={openTableMenu}
            setOpenEditModal={setOpenToEditModal}
          />
          <Snackbar
            open={geoState.error}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={onClearError}
          >
            <Alert
              severity='error'
              role='alert'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={onClearError}
                >
                  <Close />{' '}
                </IconButton>
              }
              variant='filled'
            >
              {geoState.errorMessage}
            </Alert>
          </Snackbar>
          <TablesMenu
            open={Boolean(openTableMenu.element)}
            anchorEl={openTableMenu.element}
            onClose={onCloseTableMenu}
            onClickButton={setOpenToEditModal}
          />
          <ModalContainer open={openEditModal} close={setCloseToEditModal}>
            {
              <EditGeo
                closeModal={setCloseToEditModal}
                closeMenu={onCloseTableMenu}
                geoId={openTableMenu.id}
              />
            }
          </ModalContainer>
          <ModalContainer open={createGeoOpen} close={closeCreateGeo}>
            <CreateGeo />
          </ModalContainer>
        </main>
      </>
    </AppContainer>
  );
};

export default GeosPage;
