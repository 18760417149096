import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { CampaignEvents, CampaignEvents2 } from '../../campaignsTypes';

const tableDefinition: {
  title: string;
  value: (campaignEvents: CampaignEvents2) => any;
  styles?: CSSProperties;
  alignment?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}[] = [
  {
    title: 'Date',
    value: (campaignEvents: CampaignEvents) =>
      new Date(campaignEvents.Date.split('-').join('/')).toLocaleDateString(
        'en-GB',
        {}
      ),
    alignment: 'center',
  },
  {
    title: 'Home',
    value: (campaignEvents: CampaignEvents) =>
      campaignEvents.home.toLocaleString(),
    alignment: 'center',
  },
  {
    title: 'Category',
    value: (campaignEvents: CampaignEvents) =>
      campaignEvents.category.toLocaleString(),
    alignment: 'center',
  },
  {
    title: 'Product',
    value: (campaignEvents: CampaignEvents) =>
      campaignEvents.product.toLocaleString(),
    alignment: 'center',
  },
  {
    title: 'Car',
    value: (campaignEvents: CampaignEvents) =>
      campaignEvents.car.toLocaleString(),
    alignment: 'center',
  },
  {
    title: 'Congrats',
    value: (campaignEvents: CampaignEvents) =>
      campaignEvents.congrats.toLocaleString(),
    alignment: 'center',
  },
  {
    title: 'Conversions',
    value: (campaignEvents: CampaignEvents) =>
      campaignEvents.conversions.toLocaleString(),
    alignment: 'center',
  },
  {
    title: 'User magic',
    value: (campaignEvents: CampaignEvents) =>
      campaignEvents.magic_users.toLocaleString(),
    styles: { whiteSpace: 'nowrap' },
    alignment: 'center',
  },
];

export default tableDefinition;
