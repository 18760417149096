import Axios from 'axios';
import { AccountTableApi, CreateAccountObject, DeleteAccountAnswer, EditAccountObject, SingleAccountApi } from './accountsTypes';

export const getTableAccountsApi = async (token: string, page: number, numberOffers: number, search: string) => {
  const { data } = await Axios.get<{ message: string; data: { accounts: AccountTableApi[]; amount: string } }>(
    `/api/accounts/?page=${page}&take=${numberOffers}&search=${search}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};


//Create account
export const createAccountApi = async (token: string, body: CreateAccountObject) => {
  const { data } = await Axios.post('/api/accounts', body, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Edit account

export const editAccountApi = async (token: string, body: EditAccountObject, accountId: number) => {
  const { data } = await Axios.patch<{ message: string; data: { account: SingleAccountApi } }>(
    `/api/accounts/${accountId}`,
    body,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

//Get one account

export const getOneAccountApi = async (token: string, accountId: number) => {
  const { data } = await Axios.get<{ message: string; data: { account: SingleAccountApi } }>(
    `/api/accounts/${accountId}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

//Delete account

export const deleteAccountApi = async (token: string, accountId: number) => {
  const { data } = await Axios.delete<DeleteAccountAnswer>('/api/accounts', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    data: { accountId },
  });
  return data.data
};

//Get accounts for AutocompleteFilter.
export const  getAccountsForFilterApi = async(token:string)=>{
  const { data}=await Axios.get<{message:string,data:{accounts:{id:number,name:string}[]}}>('/api/accounts/autocomplete',{
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
}
