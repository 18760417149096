import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const createAccountStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '3.678rem',
    paddingTop: '0',
    maxHeight: '50.25rem',
    overflowY: 'scroll',
    height: '62vh',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '1.5rem',
    marginBottom: '1.25rem',
  },
  form: { display: 'flex', flexDirection: 'column' },
  inputs: {
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
    },
  },
  firstInputText: {
    marginBottom: '0',
  },
  redirectWrapper: {
    paddingTop: '1rem',
  },
  accountUsers: { display: 'flex', flexDirection: 'column', marginTop: '53px' },
  button: {
    display: 'block',
    marginLeft: 'auto',
  },
  datesContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.625rem 0',
  },
  datPicker: {
    '& .react-datetime-picker__wrapper': {
      border: 'none',
      borderBottom: 'thin solid gray',
      margin: '0.625rem 0',
    },
  },
  blacklistInput: {
    marginBottom: '1.625rem',
    '& .MuiInputLabel-filled': {
      color: theme.palette.text.primary,
    },
  },
  errorMessageValidation: {
    '& .MuiAlert-message': {
      whiteSpace: 'break-spaces',
    },
    '& .MuiAlert-icon': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default createAccountStyles;
