import { AppActions, AppState, ThunkDispatchType } from '../store/storeTypes';
import { AccountTableApi, CreateAccountObject, DeletedAccount, EditAccountObject, SingleAccountApi } from './accountsTypes';
import { getTableAccountsApi, createAccountApi, getOneAccountApi, editAccountApi,deleteAccountApi } from './accountsApi';
import errorMessageBuilder from '../shared/errorMessageBuilder/errorMessageBuilder';
export const ACCOUNTS_GET_START = 'ACCOUNT_GET_START';
export const ACCOUNTS_GET_FAIL = 'ACCOUNT_GET_FAIL';
export const ACCOUNTS_GET_SUCCESS = 'ACCOUNTS_GET_SUCCESS';
export const ACCOUNTS_SEARCH = 'ACCOUNTS_SEARCH';
export const ACCOUNT_CHANGE_PAGE = 'ACCOUNT_CHANGE_PAGE';
export const ACCOUNT_LIMIT_CHANGE = 'ACCOUNT_LIMIT_CHANGE';
export const ACCOUNT_ERROR_CLEAR = 'ACCOUNT_ERROR_CLEAR';
export const ACCOUNT_CREATE_START = 'ACCOUNT_CREATE_START';
export const ACCOUNT_CREATE_FAIL = 'ACCOUNT_CREATE_FAIL';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_CLEAR_ERROR = 'ACCOUNTS_CREATE_CLEAR_ERROR';
export const ACCOUNT_EDIT_START = 'ACCOUNT_EDIT_START';
export const ACCOUNT_EDIT_SUCCESS = 'ACCOUNT_EDIT_SUCCESS';
export const ACCOUNT_EDIT_FAIL = 'ACCOUNT_EDIT_FAIL';
export const ACCOUNT_EDIT_ERROR_CLEAR = 'ACCOUNT_EDIT_ERROR_CLEAR';
export const SINGLE_ACCOUNT_GET_START = 'SINGLE_ACCOUNT_GET_START';
export const SINGLE_ACCOUNT_GET_SUCCESS = 'SINGLE_ACCOUNT_GET_SUCCESS';
export const SINGLE_ACCOUNT_GET_FAIL = 'SINGLE_ACCOUNT_GET_FAIL';
export const SINGLE_ACCOUNT_ERROR_CLEAR = 'SINGLE_ACCOUNT_ERROR_CLEAR';
export const ACCOUNT_DELETE_START = 'ACCOUNT_DELETE_START';
export const ACCOUNT_DELETE_SUCCESS='ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAIL='ACCOUNT_DELETE_FAIL';
export const ACCOUNT_DELETE_ERROR_CLEAR='ACCOUNT_DELETE_ERROR_CLEAR';

export const accountsGetStart = (): AppActions => {
  return {
    type: ACCOUNTS_GET_START,
  };
};

export const accountsGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: ACCOUNTS_GET_FAIL,
    payload: { error, errorMessage },
  };
};

export const accountsGetSuccess = (accounts: AccountTableApi[], amount: string): AppActions => {
  return {
    type: ACCOUNTS_GET_SUCCESS,
    payload: {
      accounts,
      amount: parseInt(amount),
    },
  };
};

export const accountsSearch = (search: string, searching: boolean): AppActions => {
  return {
    type: ACCOUNTS_SEARCH,
    payload: { search, searching },
  };
};

export const accountPageChange = (page: number): AppActions => {
  return {
    type: ACCOUNT_CHANGE_PAGE,
    payload: { page },
  };
};

export const accountLimitChange = (limit: number): AppActions => {
  return {
    type: ACCOUNT_LIMIT_CHANGE,
    payload: { limit },
  };
};

export const accountErrorClear = (): AppActions => {
  return {
    type: ACCOUNT_ERROR_CLEAR,
  };
};

export const getAccounts = (token: string, page: number, numberOffers: number, search: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(accountsGetStart());
    const accounts = await getTableAccountsApi(token, page, numberOffers, search);

    dispatch(accountsGetSuccess(accounts.accounts, accounts.amount));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(accountsGetFail(true, errorMessage ? errorMessage : err));
  }
};
//Create account actions;
export const accountCreatestart = (): AppActions => {
  return {
    type: ACCOUNT_CREATE_START,
  };
};

export const accountCreateFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: ACCOUNT_CREATE_FAIL,
    payload: { error, errorMessage },
  };
};

export const accountCreateSuccess = (account: { name: string }): AppActions => {
  return {
    type: ACCOUNT_CREATE_SUCCESS,
    payload: account,
  };
};

export const accountCreateErrorClear = (): AppActions => {
  return {
    type: ACCOUNT_CREATE_CLEAR_ERROR,
  };
};

export const createAccount = (token: string, account: CreateAccountObject) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(accountCreatestart());
    await createAccountApi(token, account);

    dispatch(accountCreateSuccess({ name: account.name }));
    dispatch(getAccounts(token, 0, 10, ''));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(accountCreateFail(true, errorMessage ? errorMessage : err));
  }
};

//Get Single Account
export const singleAccountGetStart = (): AppActions => {
  return {
    type: SINGLE_ACCOUNT_GET_START,
  };
};

export const singleAccountGetSuccess = (account: SingleAccountApi): AppActions => {
  return {
    type: SINGLE_ACCOUNT_GET_SUCCESS,
    payload: account,
  };
};

export const singleAccountGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: SINGLE_ACCOUNT_GET_FAIL,
    payload: { error, errorMessage },
  };
};
export const singleAccountErrorClear = () => {
  return {
    type: SINGLE_ACCOUNT_ERROR_CLEAR,
  };
};

export const getSingleAccount = (token: string, accountId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(singleAccountGetStart());
    const account = await getOneAccountApi(token, accountId);
    dispatch(singleAccountGetSuccess(account.account));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(singleAccountGetFail(true, errorMessage ? errorMessage : err));
  }
};

//Edit Account
export const editAccountStart = ():AppActions => {
  return {
    type: ACCOUNT_EDIT_START,
  };
};

export const editAccountFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: ACCOUNT_EDIT_FAIL,
    payload: { error, errorMessage },
  };
};
export const editAccountSuccess = (account: SingleAccountApi): AppActions => {
  return {
    type: ACCOUNT_EDIT_SUCCESS,
    payload: account,
  };
};

export const editAccountErrorClear = (): AppActions => {
  return {
    type: ACCOUNT_EDIT_ERROR_CLEAR,
  };
};

export const editAccount = (token: string, accountInfo:EditAccountObject ,accountId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(editAccountStart());
    const editAccount = await editAccountApi(token,accountInfo,accountId);
    dispatch(editAccountSuccess(editAccount.account));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(editAccountFail(true, errorMessage ? errorMessage : err));
  }
};

//Delete Account

export const deleteAccountStart=():AppActions=>{
  return{
    type:ACCOUNT_DELETE_START,
  }
}

export const deleteAccountFail=(error: boolean, errorMessage: string):AppActions=>{
  return{
    type:ACCOUNT_DELETE_FAIL,
    payload:{error,errorMessage}
  }
}

export const deleteAccountSuccess=(account:DeletedAccount):AppActions=>{
  return{
    type:ACCOUNT_DELETE_SUCCESS,
    payload:account
  }
}

export const deleteAccountErrorClear=():AppActions=>{
  return{
    type:ACCOUNT_DELETE_ERROR_CLEAR
  }
}
export const deleteAccount=(token:string,accountId:number)=>async(dispatch:ThunkDispatchType,getState:()=>AppState)=>{
  try {
    dispatch(deleteAccountStart());
    const account = await deleteAccountApi(token,accountId);
    dispatch(deleteAccountSuccess(account.account));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(deleteAccountFail(true, errorMessage ? errorMessage : err))
  }
}