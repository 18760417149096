import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React, { ChangeEvent } from 'react';
import TableSkeleton from '../TableSkeleton/TableSkeleton';

type element = { id: number; idd?: number };
interface Props<T> {
  name: string;
  items: T[] | null;
  tableDefinition: {
    title: string;
    value: (el: T) => any;
    styles?: CSSProperties;
    alignment?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  }[];
  loading: boolean;
  error: boolean;
  openMenuId?: number;
  page: number;
  limit: number;
  amount: number;
  onChangeCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangePage: (page: number) => void;
  onChangeLimit: (limit: number) => void;
}

export default function TableComponent<T extends element>(props: Props<T>) {
  let tableData: JSX.Element = <TableSkeleton />;
  if (!props.loading && props.items && !props.error) {
    tableData = (
      <Table>
        <TableHead>
          <TableRow>
            {props.onChangeCheckbox && <TableCell></TableCell>}
            {props.tableDefinition.map((el) => (
              <TableCell align={el.alignment} style={el.styles} key={el.title}>
                {el.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((element) => {
            const id = 'idd' in element ? element.idd! : element.id;
            return (
              <TableRow
                selected={props.openMenuId === id}
                key={id}
                id={id.toString()}
              >
                {props.onChangeCheckbox && (
                  <TableCell>
                    {
                      <Checkbox
                        value={id}
                        checked={props.openMenuId === id}
                        onChange={props.onChangeCheckbox}
                      />
                    }
                  </TableCell>
                )}
                {props.tableDefinition.map((el) => (
                  <TableCell style={el.styles} key={el.title}>
                    {el.value(element)}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 30]}
              page={props.page}
              rowsPerPage={props.limit}
              count={props.amount}
              labelRowsPerPage={`${props.name} per page:`}
              onPageChange={(_event, page) => props.onChangePage(page)}
              onRowsPerPageChange={(event) =>
                props.onChangeLimit(parseInt(event.target.value))
              }
              style={{ width: '100%' }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
  return (
    <TableContainer
      style={{ paddingRight: '6.25rem', paddingLeft: '1.9375rem' }}
    >
      {tableData}
    </TableContainer>
  );
}
