import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  campaignLimitChange,
  campaignPageChange,
  statusChange,
} from '../campaignsActions';
import TablesMenu from '../../shared/TablesMenu/TablesMenu';
import rolesDictionary from '../../shared/permissions/rolesDictionary';
import CampaignNameLink from './CampaignNameLink/CampaignNameLink';
import { CampaignTableApi } from '../campaignsTypes';
import TableComponent from '../../shared/TableComponent/TableComponent';
import { AppState } from '../../store/storeTypes';
import { CSSProperties } from '@material-ui/styles';
import ActiveCampaignSwitch from './ActiveCampaignSwitch/ActiveCampaign';
import TableChipts from './TableChips/TableChips';

interface Props {
  setOpenEditModal: () => void;
  openTableMenu: { id: number; element: null | HTMLInputElement };
  onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  onCloseTableMenu: () => void;
}
const CampaignsTable = (props: Props) => {
  //The campaign state
  const campaingState = useSelector((state: AppState) => state.campaigns);
  //Auth state
  const authState = useSelector((state: AppState) => state.auth);

  //Dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Search value changes when user types.

  //Changes the page from the table.
  const onChangePage = (page: number) => {
    dispatch(campaignPageChange(page));
  };
  //Changes the limit of the table.
  const onChangeLimit = (limit: number) => {
    dispatch(campaignLimitChange(limit));
  };

  //Handles the change of status of a campaign and dispatch the action that will handle sending it to the server.
  const onStatusChange = (newStatus: boolean, campaignId: number) => {
    const nextStatus = newStatus ? 1 : 0;
    dispatch(
      statusChange(authState.currentUser!.token, campaignId, nextStatus)
    );
  };
  //Definition for the table
  const tableDefinition: {
    title: string;
    value: (campaign: CampaignTableApi) => any;
    styles?: CSSProperties;
  }[] = [
    { title: 'Id', value: (campaign: CampaignTableApi) => campaign.id },
    {
      title: 'Name',
      value: (campaign: CampaignTableApi) => (
        <CampaignNameLink
          campaignID={campaign.id}
          campaignName={campaign.campaignName}
          roleID={authState.currentUser?.role.id!}
        />
      ),
    },
    { title: 'Domain', value: (campaign: CampaignTableApi) => campaign.domain },
    {
      title: 'Active',
      value: (campaign: CampaignTableApi) => (
        <ActiveCampaignSwitch
          loading={campaingState.statusChangeLoading.loading}
          loadingID={campaingState.statusChangeLoading.campId}
          campaignActive={campaign.active}
          campaignID={campaign.id}
          onStatusChange={onStatusChange}
          roleID={authState.currentUser?.role.id!}
        />
      ),
      styles: { minWidth: '130px' },
    },
    {
      title: 'Account',
      value: (campaign: CampaignTableApi) => campaign.account.name,
    },
    {
      title: 'Currency',
      value: (campaign: CampaignTableApi) => campaign.currency,
    },
  ];
  //If the user is no client we show the data for the tags, magic and strategy
  if (authState.currentUser?.role.id !== rolesDictionary.client) {
    tableDefinition.push({
      title: 'Prefix',
      value: (campaign: CampaignTableApi) => campaign.prefix,
    });
    tableDefinition.push({
      title: 'Home',
      value: (campaign: CampaignTableApi) => campaign.home.toLocaleString(),
      styles: { whiteSpace: 'nowrap' },
    });
    tableDefinition.push({
      title: 'Cateogry',
      value: (campaign: CampaignTableApi) => campaign.category.toLocaleString(),
      styles: { whiteSpace: 'nowrap' },
    });
    tableDefinition.push({
      title: 'Product',
      value: (campaign: CampaignTableApi) => campaign.product.toLocaleString(),
      styles: { whiteSpace: 'nowrap' },
    });
    tableDefinition.push({
      title: 'Car',
      value: (campaign: CampaignTableApi) => campaign.car.toLocaleString(),
      styles: { whiteSpace: 'nowrap' },
    });
    tableDefinition.push({
      title: 'Congrats',
      value: (campaign: CampaignTableApi) => campaign.congrats.toLocaleString(),
      styles: { whiteSpace: 'nowrap' },
    });
    tableDefinition.push({
      title: 'Conversions',
      value: (campaign: CampaignTableApi) =>
        campaign.conversions.toLocaleString(),
      styles: { whiteSpace: 'nowrap' },
    });
    tableDefinition.push({
      title: 'User Magic',
      value: (campaign: CampaignTableApi) =>
        campaign.magic_users.toLocaleString(),
      styles: { whiteSpace: 'nowrap' },
    });

    tableDefinition.push({
      title: 'Strategy',
      value: (campaign: CampaignTableApi) => campaign.strategy.name,
    });
  }
  //Add this on the last spot to keep the order
  tableDefinition.push({
    title: 'Categories',
    value: (campaign: CampaignTableApi) => (
      <TableChipts
        elements={campaign.campaignsCategories.map((cat) => ({
          id: cat.categories.id,
          label: cat.categories.name,
        }))}
      />
    ),
  });
  tableDefinition.push({
    title: 'Geos',
    value: (campaign: CampaignTableApi) => (
      <TableChipts
        elements={campaign.campaignsGeos.map((geo) => ({
          id: geo.geos.idd,
          label: geo.geos.name,
        }))}
      />
    ),
  });

  return (
    <>
      <TableComponent
        amount={campaingState.amount}
        error={campaingState.error}
        items={campaingState.campaigns}
        limit={campaingState.limit}
        loading={campaingState.loading}
        name='Campaigns'
        onChangeCheckbox={props.onChangeCheckbox}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
        openMenuId={props.openTableMenu.id}
        page={campaingState.page}
        tableDefinition={tableDefinition}
      />
      <TablesMenu
        open={Boolean(props.openTableMenu.element)}
        anchorEl={props.openTableMenu.element}
        onClose={props.onCloseTableMenu}
        onClickButton={props.setOpenEditModal}
      />
    </>
  );
};

export default CampaignsTable;
