import React, { ChangeEvent } from 'react';
import TableComponent from '../../shared/TableComponent/TableComponent';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import {
  categoriesLimitChange,
  categoriesPageChange,
} from '../categoriesActions';
import TablesMenu from '../../shared/TablesMenu/TablesMenu';
import { CategoriesApi } from '../categoriesTypes';

const tableDefinition = [
  { title: 'Id', value: (category: CategoriesApi) => category.id },
  { title: 'Name', value: (category: CategoriesApi) => category.name },
];

interface Props {
  setOpenEditModal: () => void;
  openTableMenu: { id: number; element: null | HTMLInputElement };
  onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  onCloseMenu: () => void;
}

const CategoriesTable: React.FC<Props> = (props) => {
  //Category state from redux
  const categoryState = useSelector((state: AppState) => state.categories);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //changes the page of the table
  const onChangePage = (page: number) => {
    //Sends the new page value to the reducer
    dispatch(categoriesPageChange(page));
  };
  //Chantes the limit of the table
  const onChangeLimit = (limit: number) => {
    //Sends the new limit value to the reducer
    dispatch(categoriesLimitChange(limit));
  };

  return (
    <>
      <TableComponent
        amount={categoryState.amount}
        error={categoryState.error}
        items={categoryState.categories}
        limit={categoryState.limit}
        loading={categoryState.loading}
        name='Categories'
        onChangeCheckbox={props.onChangeCheckbox}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
        openMenuId={props.openTableMenu.id}
        page={categoryState.page}
        tableDefinition={tableDefinition}
      />

      <TablesMenu
        open={Boolean(props.openTableMenu.element)}
        anchorEl={props.openTableMenu.element}
        onClose={props.onCloseMenu}
        onClickButton={props.setOpenEditModal}
      />
    </>
  );
};

export default CategoriesTable;
