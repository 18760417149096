import { AppBar, Typography } from '@material-ui/core';
import Avatar from '../Avatar/Avatar';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/storeTypes';

interface Props {
  search?: ReactNode;
  statusFilter?: ReactNode;
  countryFilter?: ReactNode;
  categoryFilter?: ReactNode;
  strategyFilter?: ReactNode;
  accountFilter?: ReactNode;
  title?: string;
}

const AppHeader: React.FC<Props> = (props) => {
  const authState = useSelector((state: AppState) => state.auth);
  const searchBar = props.search ? props.search : null;
  const statusFilter = props.statusFilter ? props.statusFilter : null;
  const countryFilter = props.countryFilter ? props.countryFilter : null;
  const categoryFilter = props.categoryFilter ? props.categoryFilter : null;
  const accountFilter = props.accountFilter ? props.accountFilter : null;
  const strategyFilter = props.strategyFilter ? props.strategyFilter : null;
  const title = props.title ? (
    <Typography variant='h2' component='h2'>
      {props.title}
    </Typography>
  ) : null;
  return (
    <AppBar position='static'>
      <Typography color='primary' variant='h2' component='h1'>
        {process.env.REACT_APP_TITLE}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        {searchBar} {statusFilter}
        {countryFilter}
        {categoryFilter}
        {title}
        {accountFilter}
        {strategyFilter}
      </div>
      <Avatar
        data={{
          name: authState.currentUser?.name,
          lastName: authState.currentUser?.lastName,
          url: authState.currentUser?.profileUrl,
        }}
      />
    </AppBar>
  );
};

export default AppHeader;
