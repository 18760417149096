import React, { ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import { geosLimitChange, geosPageChange } from '../geosActions';
import TablesMenu from '../../shared/TablesMenu/TablesMenu';
import TableComponent from '../../shared/TableComponent/TableComponent';
import { GeosApi } from '../geosTypes';

const tableDefinition = [
  { title: 'Id', value: (geos: GeosApi) => geos.idd },
  { title: 'Name', value: (geos: GeosApi) => geos.name },
  { title: 'Code', value: (geos: GeosApi) => geos.code },
];

interface Props {
  setOpenEditModal: () => void;
  openTableMenu: { id: number; element: null | HTMLInputElement };
  onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  onCloseMenu: () => void;
}

const GeosTable: React.FC<Props> = (props) => {
  //The account state from the reducer.
  const geoTableState = useSelector((state: AppState) => state.geos);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();

  //Changes the page from the table.
  const onChangePage = (page: number) => {
    //Sends the new page value to the reducer.
    dispatch(geosPageChange(page));
  };
  //Changes the limit of the table.
  const onChangeLimit = (limit: number) => {
    //Sends the limit to the reducer.
    dispatch(geosLimitChange(limit));
  };

  return (
    <>
      <TableComponent
        amount={geoTableState.amount}
        error={geoTableState.error}
        items={geoTableState.geos}
        limit={geoTableState.limit}
        loading={geoTableState.loading}
        name='Geos'
        onChangeCheckbox={props.onChangeCheckbox}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
        openMenuId={props.openTableMenu.id}
        page={geoTableState.page}
        tableDefinition={tableDefinition}
      />

      <TablesMenu
        open={Boolean(props.openTableMenu.element)}
        anchorEl={props.openTableMenu.element}
        onClose={props.onCloseMenu}
        onClickButton={props.setOpenEditModal}
      />
    </>
  );
};

export default GeosTable;
