import { Chip } from '@material-ui/core';
interface Props {
  elements: { id: number; label: string }[];
}

const TableChips = (props: Props) => {
  return (
    <div>
      {props.elements.map((element, ind) => {
        if (ind < 2) {
          return (
            <Chip
              style={{ margin: '1px' }}
              key={element.id}
              label={element.label}
            />
          );
        } else {
          return false;
        }
      })}
      {props.elements.length > 2 && (
        <Chip color='secondary' label={`${props.elements.length - 2}+`} />
      )}
    </div>
  );
};

export default TableChips;
