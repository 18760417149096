import SwitchInput from '../../Inputs/SwitchInput/SwitchInput';
import { CircularProgress } from '@material-ui/core';
import rolesDictionary from '../../../shared/permissions/rolesDictionary';
import activeCampaignStyles from './activeCampaignStyles';
interface Props {
  loading: boolean;
  loadingID: number | null;
  roleID: number;
  campaignID: number;
  campaignActive: 0 | 1;
  onStatusChange: (newStatus: boolean, campaignId: number) => void;
}
const useStyles = activeCampaignStyles;

const ActiveCampaignSwitch = (props: Props) => {
  const styles = useStyles();
  let element = props.campaignActive ? <p>Yes</p> : <p>No</p>;
  if (props.campaignID !== rolesDictionary.client) {
    if (props.loading && props.campaignID === props.loadingID) {
      element = <CircularProgress style={{ width: '25px', height: '25px' }} />;
    } else {
      element = (
        <SwitchInput
          elementId={props.campaignID}
          buttonClass={styles.switchBase}
          trackClass={styles.track}
          offText='Off'
          onText='On'
          checked={props.campaignActive ? true : false}
          onChangeSwitch={props.onStatusChange}
        />
      );
    }
  }
  return element;
};

export default ActiveCampaignSwitch;
