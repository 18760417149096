import React, {
  ChangeEvent,
  useState,
  FocusEvent,
  FormEvent,
  useEffect,
} from 'react';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createGeoStyles from '../CreateGeo/createGeoStyles';
import { GeoCreate } from '../geosTypes';
import {
  deleteGeo,
  deleteGeoErrorClear,
  editGeo,
  editGeoErrorClear,
  getGeos,
  getSingleGeo,
  singleGeoErrorClear,
} from '../geosActions';
import { Close } from '@material-ui/icons';
import EditGeoSkeleton from './EditGeoSkeleton';
import DeleteDialog from '../../shared/DeleteDialog/DeleteDialog';

const useStyles = createGeoStyles;

interface Props {
  geoId: number;
  closeModal: () => void;
  closeMenu: () => void;
}
const EditGeo: React.FC<Props> = (props) => {
  const authState = useSelector((state: AppState) => state.auth);
  const geoState = useSelector((state: AppState) => state.geos);
  const dispatch = useDispatch();
  const styles = useStyles();
  useEffect(() => {
    dispatch(getSingleGeo(authState.currentUser!.token, props.geoId));
  }, [dispatch, authState.currentUser, props.geoId]);

  useEffect(() => {
    setGeoName({ error: false, value: geoState.singleGeo.name });
    setCode({ error: false, value: geoState.singleGeo.code });
  }, [props.geoId, geoState.singleGeo]);

  const [geoName, setGeoName] = useState({
    value: geoState.singleGeo.name,
    error: false,
  });
  const onChangeGeoName = (event: ChangeEvent<HTMLInputElement>) => {
    setGeoName({ value: event.target.value, error: false });
  };
  const onBlurGeoName = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setGeoName((prev) => ({ ...prev, error: true }));
    }
  };

  const [code, setCode] = useState({
    value: geoState.singleGeo.code,
    error: false,
  });
  const onChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setCode({ value: event.target.value, error: false });
  };
  const onBlurCode = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length !== 2) {
      setCode((prev) => ({ ...prev, error: true }));
    }
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    const geoObject: GeoCreate = {
      name: geoName.value,
      code: code.value,
    };
    dispatch(
      editGeo(authState.currentUser!.token, geoObject, geoState.singleGeo.idd)
    );
  };

  const error = geoName.error ? true : code.error ? true : false;
  const onClearError = () => {
    dispatch(editGeoErrorClear());
    if (!geoState.editError) {
      props.closeMenu();
      props.closeModal();
      dispatch(
        getGeos(
          authState.currentUser!.token,
          geoState.page,
          geoState.limit,
          geoState.search
        )
      );
    }
  };

  const deleteGeos = () => {
    dispatch(deleteGeo(authState.currentUser!.token, geoState.singleGeo.idd));
  };
  const clearErrorDelete = () => {
    dispatch(deleteGeoErrorClear());
    props.closeMenu();
    props.closeModal();
    dispatch(
      getGeos(
        authState.currentUser!.token,
        geoState.page,
        geoState.limit,
        geoState.search
      )
    );
  };
  const clearErrorSingleGeo = () => {
    dispatch(singleGeoErrorClear());
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const acceptDeleteDialog = () => {
    setOpenDeleteDialog(false);
    deleteGeos();
  };
  const rejectDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Edit Geo
        </Typography>
      </div>

      {!geoState.singleGeoLoading && (
        <form className={styles.form} onSubmit={formSubmit}>
          <TextField
            value={geoName.value}
            name='geo-name'
            onChange={onChangeGeoName}
            onBlur={onBlurGeoName}
            error={geoName.error}
            className={`${styles.inputs} ${styles.firstInputText}`}
            label='Geo name'
            variant='standard'
            helperText={geoName.error ? 'No less than 3 characers' : ' '}
            required
          />
          <TextField
            name='code'
            value={code.value}
            onChange={onChangeCode}
            onBlur={onBlurCode}
            error={code.error}
            className={styles.inputs}
            label='Country short name'
            variant='standard'
            helperText={code.error ? 'Country code is 2 characters EX:CO' : ' '}
            required
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1.25rem',
            }}
          >
            <Button
              onClick={deleteDialog}
              type='button'
              variant='text'
              color='primary'
              style={{ marginRight: '1.25rem' }}
              disabled={
                error ||
                geoState.editLoading ||
                geoState.editError ||
                geoState.deleteGeoLoading ||
                geoState.deleteGeoError
              }
            >
              <Delete style={{ marginRight: '0.3125rem' }} /> Delete
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={
                error ||
                geoState.editLoading ||
                geoState.editError ||
                geoState.deleteGeoLoading ||
                geoState.deleteGeoError
              }
            >
              {geoState.editLoading ? <CircularProgress /> : 'Submit'}
            </Button>
          </div>
        </form>
      )}
      {geoState.singleGeoLoading && <EditGeoSkeleton />}

      <Snackbar
        open={geoState.editError || Boolean(geoState.editGeoInfo)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={geoState.editError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {geoState.editError ? geoState.editErrorMessage : 'Geo edited!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(geoState.deletedGeo) || geoState.deleteGeoError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorDelete}
      >
        <Alert
          severity={geoState.deleteGeoError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorDelete}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {geoState.deleteGeoError ? geoState.deleteGeoErroMsg : 'Geo Deleted!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={geoState.singleGeoError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorSingleGeo}
      >
        <Alert
          severity='error'
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorSingleGeo}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {geoState.singleGeoError
            ? geoState.singleGeoErrorMsg
            : 'Cant find geo!'}
        </Alert>
      </Snackbar>
      <DeleteDialog
        title='Delete Geo'
        bodyText='Do you want to delete this geo?'
        open={openDeleteDialog}
        handleAcceptance={acceptDeleteDialog}
        handleRejection={rejectDeleteDialog}
      />
    </div>
  );
};

export default EditGeo;
