import React, { ChangeEvent } from 'react';
import TableComponent from '../../shared/TableComponent/TableComponent';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import { accountLimitChange, accountPageChange } from '../accountsActions';
import TablesMenu from '../../shared/TablesMenu/TablesMenu';
import { AccountTableApi } from '../accountsTypes';

const tableDefinition = [
  { title: 'Id', value: (account: AccountTableApi) => account.id },
  { title: 'Name', value: (account: AccountTableApi) => account.name },
  { title: 'Domain', value: (account: AccountTableApi) => account.domain },
  {
    title: 'Affiliate Network',
    value: (account: AccountTableApi) =>
      account.affiliateNetwork ? 'Yes' : 'No',
  },
  { title: 'Voluum Id', value: (account: AccountTableApi) => account.voluumId },
  {
    title: 'Voluum Click Param',
    value: (account: AccountTableApi) => account.voluumParam,
  },
  {
    title: 'Active Campaigns',
    value: (account: AccountTableApi) => account.activeCampaigns,
  },
  {
    title: 'Inactive Campaigns',
    value: (account: AccountTableApi) => account.inactiveCampaigns,
  },
];

interface Props {
  setOpenEditModal: () => void;
  openTableMenu: { id: number; element: null | HTMLInputElement };
  onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  onCloseTableMenu: () => void;
}

const AccountsTable: React.FC<Props> = (props) => {
  //The account state from the reducer.
  const accountState = useSelector((state: AppState) => state.accounts);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();

  //Changes the page from the table.
  const onChangePage = (page: number) => {
    //Sends the new page value to the reducer.
    dispatch(accountPageChange(page));
  };
  //Changes the limit of the table.
  const onChangeLimit = (limit: number) => {
    //Sends the limit to the reducer.
    dispatch(accountLimitChange(limit));
  };

  return (
    <>
      <TableComponent
        name='Accounts'
        tableDefinition={tableDefinition}
        items={accountState.accounts}
        amount={accountState.amount}
        limit={accountState.limit}
        loading={accountState.loading}
        error={accountState.error}
        page={accountState.page}
        openMenuId={props.openTableMenu.id}
        onChangeCheckbox={props.onChangeCheckbox}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
      />

      <TablesMenu
        open={Boolean(props.openTableMenu.element)}
        anchorEl={props.openTableMenu.element}
        onClose={props.onCloseTableMenu}
        onClickButton={props.setOpenEditModal}
      />
    </>
  );
};

export default AccountsTable;
