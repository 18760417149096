import { AppActions, AppState, ThunkDispatchType } from '../store/storeTypes';
import {
  CampaignCreateObject,
  CampaignTableApi,
  DateOptions,
  DeleteCampaign,
  EditCampaignObject,
  FiltersApartFromGeo,
  OrderBy,
  OrderDirection,
  SingleCampaignApi,
  SingleCampaignEventApi,
} from './campaignsTypes';
import {
  getTableCampaignsApi,
  createCampaignApi,
  getOneCampaignApi,
  editCampaignApi,
  deleteCampaignApi,
  statusChangeApi,
  getEventsCampaignsApi,
} from './campaignsApi';
import errorMessageBuilder from '../shared/errorMessageBuilder/errorMessageBuilder';
import { GeosApi } from '../Geos/geosTypes';
export const CAMPAIGNS_GET_START = 'CAMPAIGNS_GET_START';
export const CAMPAIGNS_GET_FAIL = 'CAMPAIGNS_GET_FAIL';
export const CAMPAIGNS_GET_SUCCESS = 'CAMPAIGNS_GET_SUCCESS';
export const CAMPAIGNS_SEARCH = 'CAMPAIGNS_SEARCH';
export const CAMPAIGNS_CHANGE_PAGE = 'CAMPAIGNS_CHANGE_PAGE';
export const CAMPAIGNS_LIMIT_CHANGE = 'CAMPAIGNS_LIMIT_CHANGE';
export const CAMPAIGNS_DATE_FILTER_CHANGE = 'CAMPAIGNS_DATE_FILTER_CHANGE';
export const CAMPAIGNS_STATUS_FILTER_CHANGE = 'CAMPAIGN_STATUS_FILTER_CHANGE';
export const CAMPAIGNS_GEOS_FILTER_CHANGE = 'CAMPAIGN_GEOS_FILTER_CHANGE';
export const CAMPAIGNS_CATEGORIES_FILTER_CHANGE = 'CAMPAIGN_CATEGORIES_FILTER_CHANGE';
export const CAMPAIGNS_ACCOUNTS_FILTER_CHANGE = 'CAMPAIGN_ACCOUNTS_FILTER_CHANGE';
export const CAMPAIGNS_STRATEGIES_FILTER_CHANGE = 'CAMPAIGN_STRATEGIES_FILTER_CHANGE';
export const CAMPAIGNS_ERROR_CLEAR = 'CAMPAIGNS_ERROR_CLEAR';
export const CAMPAIGN_CREATE_START = 'CAMPAIGN_CREATE_START';
export const CAMPAIGN_CREATE_FAIL = 'CAMPAIGN_CREATE_FAIL';
export const CAMPAIGN_CREATE_SUCCESS = 'CAMPAIGN_CREATE_SUCCESS';
export const CAMPAIGN_CREATE_CLEAR_ERROR = 'CAMPAIGN_CREATE_CLEAR_ERROR';
export const CAMPAIGN_EDIT_START = 'CAMPAIGN_EDIT_START';
export const CAMPAIGN_EDIT_SUCCESS = 'CAMPAIGN_EDIT_SUCCESS';
export const CAMPAIGN_EDIT_FAIL = 'CAMPAIGN_EDIT_FAIL';
export const CAMPAIGN_EDIT_ERROR_CLEAR = 'CAMPAIGNS_EDIT_ERROR_CLEAR';
export const SINGLE_CAMPAIGN_GET_START = 'SINGLE_CAMPAIGN_GET_START';
export const SINGLE_CAMPAIGN_GET_SUCCESS = 'SINGLE_CAMPAIGN_GET_SUCCESS';
export const SINGLE_CAMPAIGN_GET_FAIL = 'SINGLE_CAMPAIGN_GET_FAIL';
export const SINGLE_CAMPAIGN_ERROR_CLEAR = 'SINGLE_CAMPAIGN_ERROR_CLEAR';
export const CAMPAIGN_DELETE_START = 'CAMPAIGN_DELETE_START';
export const CAMPAIGN_DELETE_SUCCESS = 'CAMPAIGN_DELETE_SUCCESS';
export const CAMPAIGN_DELETE_FAIL = 'CAMPAIGN_DELETE_FAIL';
export const CAMPAIGN_DELETE_ERROR_CLEAR = 'CAMPAIGN_DELETE_ERROR_CLEAR';
export const CAMPAIGN_STATUS_CHANGE_START = 'CAMPAIGN_STATUS_CHANGE_START';
export const CAMPAIGN_STATUS_CHANGE_SUCCESS = 'CAMPAIGN_STATUS_CHANGE_SUCCESS';
export const CAMPAIGN_STATUS_CHANGE_FAIL = 'CAMPAIGN_STATUS_CHANGE_FAIL';
export const CAMPAIGN_STATUS_CHANGE_ERROR_CLEAR = 'CAMPAIGN_STATUS_CHANGE_ERROR_CLEAR';
export const SINGLE_CAMPAIGN_EVENTS_START = 'SINGLE_CAMPAIGN_EVENTS_START';
export const SINGLE_CAMPAIGN_EVENTS_FAIL = 'SINGLE_CAMPAIGN_EVENTS_FAIL';
export const SINGLE_CAMPAIGN_EVENTS_SUCCESS = 'SINGLE_CAMPAIGN_EVENTS_SUCCESS';
export const SINGLE_CAMPAIGN_EVENTS_ERROR_CLEAR = 'SINGLE_CAMPAIGN_EVENTS_ERROR_CLEAR';
export const SINGLE_CAMPAIGN_EVENT_PAGE_CHANGE = 'SINGLE_CAMPAIGN_EVENT_PAGE_CHANGE';
export const SINGLE_CAMPAIGN_EVENT_LIMIT_CHANGE = 'SINGLE_CAMPAIGN_EVENT_LIMIT_CHANGE';

export const campaignsGetStart = (): AppActions => {
  return {
    type: CAMPAIGNS_GET_START,
  };
};

export const campaignsGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: CAMPAIGNS_GET_FAIL,
    payload: { error, errorMessage },
  };
};

export const campaignsGetSuccess = (campaigns: CampaignTableApi[], amount: string): AppActions => {
  return {
    type: CAMPAIGNS_GET_SUCCESS,
    payload: {
      campaigns,
      amount: parseInt(amount),
    },
  };
};

export const campaignsSearch = (search: string, searching: boolean): AppActions => {
  return {
    type: CAMPAIGNS_SEARCH,
    payload: { search, searching },
  };
};

export const campaignPageChange = (page: number): AppActions => {
  return {
    type: CAMPAIGNS_CHANGE_PAGE,
    payload: { page },
  };
};

export const campaignLimitChange = (limit: number): AppActions => {
  return {
    type: CAMPAIGNS_LIMIT_CHANGE,
    payload: { limit },
  };
};

export const campaignDateFilterChange = (dates: Date[]): AppActions => {
  const dateOptions:DateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const newDateFormat = [
    dates[0].toLocaleDateString('ja-JP', dateOptions),
    dates[1].toLocaleDateString('ja-JP', dateOptions),
  ];
  return {
    type: CAMPAIGNS_DATE_FILTER_CHANGE,
    payload: { dates: newDateFormat },
  };
};

export const campaignErrorClear = (): AppActions => {
  return {
    type: CAMPAIGNS_ERROR_CLEAR,
  };
};

export const campaignsStatusFilterChange = (status: 1 | 0 | ''): AppActions => {
  return {
    type: CAMPAIGNS_STATUS_FILTER_CHANGE,
    payload: { status },
  };
};
export const campaignGeosFilterChange = (geos: GeosApi[]): AppActions => {
  return {
    type: CAMPAIGNS_GEOS_FILTER_CHANGE,
    payload: { geos },
  };
};
export const campaignAccountsFilterChange = (accounts: FiltersApartFromGeo[]): AppActions => {
  return {
    type: CAMPAIGNS_ACCOUNTS_FILTER_CHANGE,
    payload: { accounts },
  };
};
export const campaignCategoriesFilterChange = (categories: FiltersApartFromGeo[]): AppActions => {
  return {
    type: CAMPAIGNS_CATEGORIES_FILTER_CHANGE,
    payload: { categories },
  };
};
export const campaignStrategiesFilterChange = (strategies: FiltersApartFromGeo[]): AppActions => {
  return {
    type: CAMPAIGNS_STRATEGIES_FILTER_CHANGE,
    payload: { strategies },
  };
};

export const getCampaigns = (
  token: string,
  page: number,
  numberOffers: number,
  orderBy: OrderBy,
  orderDirection: OrderDirection,
  dateFrom: string,
  dateTo: string,
  search: string,
  status: 1 | 0 | '',
  categories: FiltersApartFromGeo[],
  geos: GeosApi[],
  accounts: FiltersApartFromGeo[],
  strategies: FiltersApartFromGeo[]
) => async (dispatch: ThunkDispatchType, getState: () => AppState) => {
  try {
    dispatch(campaignsGetStart());
    const token = getState().auth.currentUser!.token;
    const geosIds = geos.map((geo) => geo.idd);
    const categoriesIds = categories.map((cat) => cat.id);
    const accountIds = accounts.map((account) => account.id);
    const strategyIds = strategies.map((strat) => strat.id);
    const campaigns = await getTableCampaignsApi(
      token,
      page,
      numberOffers,
      orderBy,
      orderDirection,
      dateFrom,
      dateTo,
      search,
      status,
      categoriesIds,
      geosIds,
      accountIds,
      strategyIds
    );

    dispatch(campaignsGetSuccess(campaigns.campaigns, campaigns.amount));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(campaignsGetFail(true, errorMessage ? errorMessage : err));
  }
};

//Create account actions;
export const campaignCreatestart = (): AppActions => {
  return {
    type: CAMPAIGN_CREATE_START,
  };
};

export const campaignCreateFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: CAMPAIGN_CREATE_FAIL,
    payload: { error, errorMessage },
  };
};

export const campaignCreateSuccess = (campaign: { name: string }): AppActions => {
  return {
    type: CAMPAIGN_CREATE_SUCCESS,
    payload: campaign,
  };
};

export const campaignCreateErrorClear = (): AppActions => {
  return {
    type: CAMPAIGN_CREATE_CLEAR_ERROR,
  };
};

export const createCampaign = (token: string, campaign: CampaignCreateObject) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(campaignCreatestart());
    await createCampaignApi(token, campaign);
    const campaignState = getState().campaigns;
    dispatch(campaignCreateSuccess({ name: campaign.campaignName }));
    dispatch(
      getCampaigns(
        token,
        campaignState.page,
        campaignState.limit,
        campaignState.orderBy,
        campaignState.orderDirection,
        campaignState.dateFrom,
        campaignState.dateTo,
        campaignState.search,
        campaignState.active,
        campaignState.categories,
        campaignState.geos,
        campaignState.accounts,
        campaignState.strategies
      )
    );
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(campaignCreateFail(true, errorMessage ? errorMessage : err));
  }
};

//Get Single Account
export const singleCampaignGetStart = (): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_GET_START,
  };
};

export const singleCampaignGetSuccess = (campaign: SingleCampaignApi): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_GET_SUCCESS,
    payload: campaign,
  };
};

export const singleCampaignGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_GET_FAIL,
    payload: { error, errorMessage },
  };
};
export const singleCampaignErrorClear = () => {
  return {
    type: SINGLE_CAMPAIGN_ERROR_CLEAR,
  };
};

export const getSingleCampaign = (token: string, campaignId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(singleCampaignGetStart());
    const campaign = await getOneCampaignApi(token, campaignId);

    dispatch(singleCampaignGetSuccess(campaign.campaign));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(singleCampaignGetFail(true, errorMessage ? errorMessage : err));
  }
};

//Edit Account
export const editCampaignStart = (): AppActions => {
  return {
    type: CAMPAIGN_EDIT_START,
  };
};

export const editCampaignFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: CAMPAIGN_EDIT_FAIL,
    payload: { error, errorMessage },
  };
};
export const editCampaignSuccess = (account: SingleCampaignApi): AppActions => {
  return {
    type: CAMPAIGN_EDIT_SUCCESS,
    payload: account,
  };
};

export const editCampaignErrorClear = (): AppActions => {
  return {
    type: CAMPAIGN_EDIT_ERROR_CLEAR,
  };
};

export const editCampaign = (token: string, campaignInfo: EditCampaignObject, campaignId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(editCampaignStart());
    const editCampaign = await editCampaignApi(token, campaignInfo, campaignId);
    dispatch(editCampaignSuccess(editCampaign.campaign));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(editCampaignFail(true, errorMessage ? errorMessage : err));
  }
};

//Delete Account

export const deleteCampaignStart = (): AppActions => {
  return {
    type: CAMPAIGN_DELETE_START,
  };
};

export const deleteCampaignFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: CAMPAIGN_DELETE_FAIL,
    payload: { error, errorMessage },
  };
};

export const deleteCampaignSuccess = (account: DeleteCampaign): AppActions => {
  return {
    type: CAMPAIGN_DELETE_SUCCESS,
    payload: account,
  };
};

export const deleteCampaignErrorClear = (): AppActions => {
  return {
    type: CAMPAIGN_DELETE_ERROR_CLEAR,
  };
};
export const deleteCampaign = (token: string, campaignId: number) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(deleteCampaignStart());
    const campaign = await deleteCampaignApi(token, campaignId);
    dispatch(deleteCampaignSuccess(campaign.campaign));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(deleteCampaignFail(true, errorMessage ? errorMessage : err));
  }
};

//status change
export const statusChangeStart = (campaignId: number): AppActions => {
  return {
    type: CAMPAIGN_STATUS_CHANGE_START,
    payload: { campaignId, loading: true },
  };
};
export const statusChangeSuccess = (campaigns: CampaignTableApi[]): AppActions => {
  return {
    type: CAMPAIGN_STATUS_CHANGE_SUCCESS,
    payload: { campaigns },
  };
};
export const statusChangeFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: CAMPAIGN_STATUS_CHANGE_FAIL,
    payload: { error, errorMessage },
  };
};
export const statusChange = (token: string, campaignId: number, status: 1 | 0) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(statusChangeStart(campaignId));
    const changeServerStatus = await statusChangeApi(token, { status }, campaignId);
    if (changeServerStatus.campaign) {
      const campaigns = getState().campaigns.campaigns;
      if (campaigns) {
        const newCampObject = campaigns.map((camp) => {
          if (camp.id === campaignId) {
            camp.active = status;
            return camp;
          }
          return camp;
        });
        dispatch(statusChangeSuccess(newCampObject));
      }
    }
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(statusChangeFail(true, errorMessage ? errorMessage : err));
  }
};

export const singleCampaignEventStart = (): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_EVENTS_START,
  };
};
export const singleCampaignEventSucces = (campaign: SingleCampaignEventApi, amount: number): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_EVENTS_SUCCESS,
    payload: { campaign, amount },
  };
};
export const singleCampaignEventFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_EVENTS_FAIL,
    payload: { error, errorMessage },
  };
};
export const singleCampaignEventErrorClear = (): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_EVENTS_ERROR_CLEAR,
  };
};

export const getSingleEventCampaign = (
  token: string,
  campaignId: number,
  page: number,
  numberOffers: number,
  orderBy: 'Date',
  orderDirection: OrderDirection,
  dateFrom: string,
  dateTo: string
) => async (dispatch: ThunkDispatchType, getState: () => AppState) => {
  try {
    dispatch(singleCampaignEventStart());
    const campaign = await getEventsCampaignsApi(
      token,
      campaignId,
      page,
      numberOffers,
      orderBy,
      orderDirection,
      dateFrom,
      dateTo
    );
    dispatch(singleCampaignEventSucces(campaign.campaign, parseInt(campaign.amount)));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(singleCampaignEventFail(true, errorMessage ? errorMessage : err));
  }
};

export const singleCampaignEventPageChange = (page: number): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_EVENT_PAGE_CHANGE,
    payload: { page },
  };
};

export const singleCampaignEventLimitChange = (limit: number): AppActions => {
  return {
    type: SINGLE_CAMPAIGN_EVENT_LIMIT_CHANGE,
    payload: { limit },
  };
};
