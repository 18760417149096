import * as campaignActions from './campaignsActions';
import * as campaignsTypes from './campaignsTypes';
import { DateOptions } from './campaignsTypes';
const initialSingleAccount: campaignsTypes.SingleCampaignApi = {
  id: 0,
  campaignName: '',
  active: 0,
  campaignID: '',
  capConv: null,
  capClicks: null,
  mobileOnly: 0,
  nextSession: 0,
  tracking_url: '',
  createdAt: '',
  domain: '',
  currency: '',
  prefix: '',
  startDate: '',
  endDate: '',
  redirectPercent: 0,
  urlpartsBlacklist: [],
  geosBlacklist: [],
  regexUrls: '',
  voluumOfferId: '',
  updatedAt: '',
  accountId: 0,
  account: {
    id: 0,
    name: '',
    domain: '',
    createdAt: '',
    isAffiliate: false,
    updatedAt: '',
    voluumClickIdParam: '',
    voluumId: '',
  },
  strategyId: 0,
  strategy: { id: 0, name: '' },
  campaignsCategories: [],
  campaignsGeos: [],
  tags: { home: '', category: '', product: '', car: '', congrats: '' },
};
const dateOptions: DateOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
const initialState: campaignsTypes.CampaignsState = {
  campaigns: null,
  page: 0,
  limit: 10,
  amount: 0,
  orderBy: 'campaignName',
  orderDirection: 'asc',
  search: '',
  searching: false,
  active: '',
  dateFrom: new Date(year, month, 1).toLocaleDateString('ja-JP', dateOptions),
  dateTo: new Date(year, month + 1, 0).toLocaleDateString('ja-JP', dateOptions),
  accounts: [],
  categories: [],
  geos: [],
  strategies: [],
  singleCampaign: initialSingleAccount,
  singleCampaignError: false,
  singleCampaignErrorMsg: null,
  singleCampaignLoading: false,
  loading: false,
  statusChangeLoading: { campId: null, loading: false },
  error: false,
  errorMessage: null,
  createLoading: false,
  created: null,
  createError: false,
  createErrorMessage: null,
  editLoading: false,
  editError: false,
  editErrorMessage: null,
  editCampaignInfo: null,
  deleteCampaignLoading: false,
  deletedCampaign: null,
  deleteCampaignError: false,
  deletedCampaignErrorMessage: null,
  singleCampaignEvent: null,
  singleCampaignEventAmount: 0,
  singleCampaignEventPage: 0,
  singleCampaignEventLoading: false,
  singleCampaignEventError: false,
  singleCampaignEventErrorMsg: null,
  singleCampaignEventLimit: 10,
};

const campaignsGetStart = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    loading: true,
  };
};

const campaignsGetSuccess = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignsGetSuccess
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    loading: false,
    campaigns: action.payload.campaigns,
    amount: action.payload.amount,
    searching: false,
    error: false,
    errorMessage: null,
  };
};

const campaignsGetFail = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignsGetFail
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};

const campaignsSearch = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignsSearch
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    search: action.payload.search,
    searching: action.payload.searching,
    error: false,
    errorMessage: null,
    loading: false,
    page: 0,
  };
};
const campaignChangePage = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignsChangePage
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    page: action.payload.page,
  };
};

const campaignLimitChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignsLimitChage
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    limit: action.payload.limit,
  };
};
const campaignDateFilterChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignsDateFilterChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    dateFrom: action.payload.dates[0],
    dateTo: action.payload.dates[1],
  };
};
const campaignStatusFilterChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignStatusFilterChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    active: action.payload.status,
    page: 0,
  };
};
const campaignGeosFilterChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignGeosFilterChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    geos: action.payload.geos,
    page: 0,
  };
};
const campaignCategoriesFilterChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignCategoriesFilterChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    categories: action.payload.categories,
    page: 0,
  };
};
const campaignAccountsFilterChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignAccountsFilterChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    accounts: action.payload.accounts,
    page: 0,
  };
};
const campaignStrategiesFilterChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignStrategiesFilterChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    strategies: action.payload.strategies,
    page: 0,
  };
};

const campaignErrorClear = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    error: false,
    errorMessage: null,
  };
};

const campaignCreateStart = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    createLoading: true,
  };
};
const campaignCreateFail = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignCreateFail
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    createLoading: false,
    createError: action.payload.error,
    createErrorMessage: action.payload.errorMessage,
  };
};

const campaignCreateSuccess = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignCreateSuccess
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    created: action.payload,
    createLoading: false,
    createError: false,
    createErrorMessage: null,
  };
};

const campaignCreateErrorClear = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    createError: false,
    createErrorMessage: null,
    created: null,
  };
};

const singleCampaignGetStart = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignLoading: true,
  };
};
const singleCampaignGetSuccess = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.SingleCampaignGetSuccess
): campaignsTypes.CampaignsState => {
  const { regexUrls, mobileOnly, nextSession, capClicks, capConv } =
    action.payload;
  return {
    ...state,
    singleCampaign: {
      ...action.payload,
      regexUrls: regexUrls && regexUrls.length > 0 ? regexUrls : '',
      mobileOnly: mobileOnly ? 1 : 0,
      nextSession: nextSession ? 1 : 0,
      capClicks: capClicks ? capClicks : null,
      capConv: capConv ? capConv : null,
    },
    singleCampaignLoading: false,
    singleCampaignError: false,
  };
};
const singleCampaignGetFail = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.SingleCampaignGetFail
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignError: action.payload.error,
    singleCampaignErrorMsg: action.payload.errorMessage,
    singleCampaign: initialSingleAccount,
  };
};
const singleCampaignErrorClear = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignError: false,
    singleCampaignErrorMsg: null,
  };
};

const campaignEditStart = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    editLoading: true,
  };
};
const campaignEditSuccess = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignEditSuccess
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    editCampaignInfo: action.payload,
    editError: false,
    editErrorMessage: null,
    editLoading: false,
  };
};

const campaignEditFail = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignEditFail
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    editCampaignInfo: null,
    editError: action.payload.error,
    editErrorMessage: action.payload.errorMessage,
    editLoading: false,
  };
};
const campaignEditErrorClear = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    editError: false,
    editErrorMessage: null,
    editCampaignInfo: null,
  };
};

const campaignDeleteStart = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    deleteCampaignLoading: true,
  };
};
const campaignDeleteFail = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignDeleteFail
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    deleteCampaignLoading: false,
    deleteCampaignError: action.payload.error,
    deletedCampaignErrorMessage: action.payload.errorMessage,
  };
};

const campaignDeleteSuccess = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignDeleteSuccess
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    deletedCampaign: action.payload,
    deleteCampaignLoading: false,
    deleteCampaignError: false,
    deletedCampaignErrorMessage: null,
  };
};
const campaignDeleteClearError = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    deleteCampaignError: false,
    deletedCampaignErrorMessage: null,
    deletedCampaign: null,
  };
};
const campaignStatusChangeStart = (
  state: campaignsTypes.CampaignsState,
  actions: campaignsTypes.CampaignStatusChangeStart
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    statusChangeLoading: {
      campId: actions.payload.campaignId,
      loading: actions.payload.loading,
    },
  };
};

const campaignStatusChangeSuccess = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignStatusChangeSuccess
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    statusChangeLoading: { campId: null, loading: false },
    error: false,
    errorMessage: null,
  };
};
const campaignStatusChangeFail = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.CampaignStatusChangeFail
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    statusChangeLoading: { campId: null, loading: false },
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};

const campaignStatusChangeErrorClear = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    error: false,
    errorMessage: null,
  };
};
const singleCampaignEventStart = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignEventLoading: true,
  };
};
const singleCampaignEventSucces = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.SingleCampaignEventSucces
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignEventError: false,
    singleCampaignEventErrorMsg: null,
    singleCampaignEvent: action.payload.campaign,
    singleCampaignEventAmount: action.payload.amount,
    singleCampaignEventLoading: false,
  };
};
const singleCampaignEventFail = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.SingleCampaignEventFail
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignEventError: action.payload.error,
    singleCampaignEventErrorMsg: action.payload.errorMessage,
    singleCampaignEventLoading: false,
  };
};
const singleCampaignEventErrorClear = (
  state: campaignsTypes.CampaignsState
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignEventError: false,
    singleCampaignEventErrorMsg: null,
  };
};
const singleCampaignEventLimitChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.SingleCampaignEventLimitChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignEventLimit: action.payload.limit,
  };
};
const singleCampaignEventPageChange = (
  state: campaignsTypes.CampaignsState,
  action: campaignsTypes.SingleCampaignEventPageChange
): campaignsTypes.CampaignsState => {
  return {
    ...state,
    singleCampaignEventPage: action.payload.page,
  };
};

const reducer = (
  state: campaignsTypes.CampaignsState = initialState,
  action: campaignsTypes.CampaignActionTypes
) => {
  switch (action.type) {
    case campaignActions.CAMPAIGNS_GET_START:
      return campaignsGetStart(state);
    case campaignActions.CAMPAIGNS_GET_FAIL:
      return campaignsGetFail(state, action);
    case campaignActions.CAMPAIGNS_GET_SUCCESS:
      return campaignsGetSuccess(state, action);
    case campaignActions.CAMPAIGNS_SEARCH:
      return campaignsSearch(state, action);
    case campaignActions.CAMPAIGNS_CHANGE_PAGE:
      return campaignChangePage(state, action);
    case campaignActions.CAMPAIGNS_LIMIT_CHANGE:
      return campaignLimitChange(state, action);
    case campaignActions.CAMPAIGNS_DATE_FILTER_CHANGE:
      return campaignDateFilterChange(state, action);
    case campaignActions.CAMPAIGNS_STATUS_FILTER_CHANGE:
      return campaignStatusFilterChange(state, action);
    case campaignActions.CAMPAIGNS_GEOS_FILTER_CHANGE:
      return campaignGeosFilterChange(state, action);
    case campaignActions.CAMPAIGNS_CATEGORIES_FILTER_CHANGE:
      return campaignCategoriesFilterChange(state, action);
    case campaignActions.CAMPAIGNS_ACCOUNTS_FILTER_CHANGE:
      return campaignAccountsFilterChange(state, action);
    case campaignActions.CAMPAIGNS_STRATEGIES_FILTER_CHANGE:
      return campaignStrategiesFilterChange(state, action);
    case campaignActions.CAMPAIGNS_ERROR_CLEAR:
      return campaignErrorClear(state);
    case campaignActions.CAMPAIGN_CREATE_START:
      return campaignCreateStart(state);
    case campaignActions.CAMPAIGN_CREATE_FAIL:
      return campaignCreateFail(state, action);
    case campaignActions.CAMPAIGN_CREATE_SUCCESS:
      return campaignCreateSuccess(state, action);
    case campaignActions.CAMPAIGN_CREATE_CLEAR_ERROR:
      return campaignCreateErrorClear(state);
    case campaignActions.SINGLE_CAMPAIGN_GET_START:
      return singleCampaignGetStart(state);
    case campaignActions.SINGLE_CAMPAIGN_GET_SUCCESS:
      return singleCampaignGetSuccess(state, action);
    case campaignActions.SINGLE_CAMPAIGN_GET_FAIL:
      return singleCampaignGetFail(state, action);
    case campaignActions.SINGLE_CAMPAIGN_ERROR_CLEAR:
      return singleCampaignErrorClear(state);
    case campaignActions.CAMPAIGN_EDIT_START:
      return campaignEditStart(state);
    case campaignActions.CAMPAIGN_EDIT_SUCCESS:
      return campaignEditSuccess(state, action);
    case campaignActions.CAMPAIGN_EDIT_FAIL:
      return campaignEditFail(state, action);
    case campaignActions.CAMPAIGN_EDIT_ERROR_CLEAR:
      return campaignEditErrorClear(state);
    case campaignActions.CAMPAIGN_DELETE_START:
      return campaignDeleteStart(state);
    case campaignActions.CAMPAIGN_DELETE_FAIL:
      return campaignDeleteFail(state, action);
    case campaignActions.CAMPAIGN_DELETE_SUCCESS:
      return campaignDeleteSuccess(state, action);
    case campaignActions.CAMPAIGN_DELETE_ERROR_CLEAR:
      return campaignDeleteClearError(state);
    case campaignActions.CAMPAIGN_STATUS_CHANGE_START:
      return campaignStatusChangeStart(state, action);
    case campaignActions.CAMPAIGN_STATUS_CHANGE_SUCCESS:
      return campaignStatusChangeSuccess(state, action);
    case campaignActions.CAMPAIGN_STATUS_CHANGE_FAIL:
      return campaignStatusChangeFail(state, action);
    case campaignActions.CAMPAIGN_STATUS_CHANGE_ERROR_CLEAR:
      return campaignStatusChangeErrorClear(state);
    case campaignActions.SINGLE_CAMPAIGN_EVENTS_START:
      return singleCampaignEventStart(state);
    case campaignActions.SINGLE_CAMPAIGN_EVENTS_FAIL:
      return singleCampaignEventFail(state, action);
    case campaignActions.SINGLE_CAMPAIGN_EVENTS_SUCCESS:
      return singleCampaignEventSucces(state, action);
    case campaignActions.SINGLE_CAMPAIGN_EVENTS_ERROR_CLEAR:
      return singleCampaignEventErrorClear(state);
    case campaignActions.SINGLE_CAMPAIGN_EVENT_LIMIT_CHANGE:
      return singleCampaignEventLimitChange(state, action);
    case campaignActions.SINGLE_CAMPAIGN_EVENT_PAGE_CHANGE:
      return singleCampaignEventPageChange(state, action);
    default:
      return state;
  }
};

export default reducer;
