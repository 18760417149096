import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';

interface Props {
  open: boolean;
  title: string;
  bodyText: string;
  handleAcceptance: () => void;
  handleRejection: () => void;
}
const DeleteDialog: React.FC<Props> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleRejection}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleAcceptance}>Delete</Button>
        <Button
          onClick={props.handleRejection}
          variant='contained'
          color='primary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
