import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './../store/storeTypes';
import { categoriesErrorClear, getCategories } from './categoriesActions';
import AppContainer from '../shared/AppContainer/AppContainer';
import PageTitle from '../shared/PageTitle/PageTitle';
import CategoryHeader from './CategoryHeader/CategoryHeader';
import EditCategory from './EditCategory/EditCategory';
import ModalContainer from './../shared/Modal/ModalContainer';
import CreateCategory from './CreateCategory/CreateCategory';
import CategoriesTable from './CategoriesTable/CategoriesTable';

const CategoriesPage: React.FC = () => {
  //Authentication state
  const authState = useSelector((state: AppState) => state.auth);
  //Category state
  const categoryState = useSelector((state: AppState) => state.categories);
  //Dispatch hook to dispatch actions
  const dispatch = useDispatch();
  //Fetch data if any filter changes
  useEffect(() => {
    if (!categoryState.searching) {
      dispatch(
        getCategories(
          authState.currentUser!.token,
          categoryState.page,
          categoryState.limit,
          categoryState.search
        )
      );
    }
  }, [
    authState.currentUser,
    dispatch,
    categoryState.search,
    categoryState.page,
    categoryState.searching,
    categoryState.limit,
  ]);

  //State of the table menu
  const [openTableMenu, setOpenTableMenu] = useState<{
    id: number;
    element: null | HTMLInputElement;
  }>({
    id: 0,
    element: null,
  });
  //Handles the changes of the table checkbox
  const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setOpenTableMenu({
      element: event.target,
      id: parseInt(event.target.value),
    });
  };
  //Closes the menu of the table
  const onCloseTableMenu = () => {
    setOpenTableMenu({ id: 0, element: null });
  };
  //Clears error messages
  const onClearError = () => {
    dispatch(categoriesErrorClear());
  };
  //State of the modal for edit category
  const [openEditModal, setOpenEditModal] = useState(false);
  //Sets the edit modal to open
  const setOpenToEditModal = () => {
    setOpenEditModal(true);
  };
  //Sets the edit modal to close
  const setCloseToEditModal = () => {
    setOpenEditModal(false);
  };
  //Handles the category create modal state
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
  //Opens create modal
  const openCreateCategory = () => {
    setCreateCategoryOpen(true);
  };
  //Closes create modal
  const closeCreateCategory = () => {
    setCreateCategoryOpen(false);
  };

  return (
    <AppContainer>
      <>
        <CategoryHeader
          loading={categoryState.loading}
          search={categoryState.search}
        />
        <main>
          <PageTitle
            buttonText='Create Category'
            openCreate={openCreateCategory}
            title='Categories'
            userRoleId={authState.currentUser!.role.id}
          />
          <CategoriesTable
            onChangeCheckbox={onChangeCheckBox}
            onCloseMenu={onCloseTableMenu}
            openTableMenu={openTableMenu}
            setOpenEditModal={setOpenToEditModal}
          />
          <Snackbar
            open={categoryState.error}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={onClearError}
          >
            <Alert
              severity='error'
              role='alert'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={onClearError}
                >
                  <Close />{' '}
                </IconButton>
              }
              variant='filled'
            >
              {categoryState.errorMessage}
            </Alert>
          </Snackbar>
          <ModalContainer open={openEditModal} close={setCloseToEditModal}>
            {
              <EditCategory
                closeModal={setCloseToEditModal}
                closeMenu={onCloseTableMenu}
                categoryId={openTableMenu.id}
              />
            }
          </ModalContainer>
          <ModalContainer open={createCategoryOpen} close={closeCreateCategory}>
            <CreateCategory />
          </ModalContainer>
        </main>
      </>
    </AppContainer>
  );
};

export default CategoriesPage;
