import errorMessageBuilder from '../shared/errorMessageBuilder/errorMessageBuilder';
import { AppActions, AppState, ThunkDispatchType } from '../store/storeTypes';
import { createGeoApi, getTableGeosApi,getOneGeoApi, editGeoApi, deleteGeoApi } from './geosApi';
import * as geosTypes from './geosTypes';
export const GEOS_GET_START = 'GEOS_GET_START';
export const GEOS_GET_FAIL = 'GEOS_GET_FAIL';
export const GEOS_GET_SUCCESS = 'GEOS_GET_SUCCESS';
export const GEOS_SEARCH='GEOS_SEARCH';
export const GEOS_LIMIT_CHANGE='GEOS_LIMIT_CHANGE';
export const GEOS_CHANGE_PAGE='GEOS_CHANGE_PAGE';
export const GEOS_ERROR_CLEAR='GEOS_ERROR_CLEAR';
export const GEO_CREATE_START='GEO_CREATE_START';
export const GEO_CREATE_FAIL='GEO_CREATE_FAIL';
export const GEO_CREATE_SUCCESS='GEO_CREATE_SUCCESS';
export const GEO_CREATE_CLEAR_ERROR ='GEO_CREATE_CLEAR_ERROR';
export const SINGLE_GEO_GET_START = 'SINGLE_GEO_GET_START';
export const SINGLE_GEO_GET_SUCCESS = 'SINGLE_GEO_GET_SUCCESS';
export const SINGLE_GEO_GET_FAIL = 'SINGLE_GEO_GET_FAIL';
export const SINGLE_GEO_ERROR_CLEAR = 'SINGLE_GEO_ERROR_CLEAR';
export const GEO_EDIT_START = 'GEO_EDIT_START';
export const GEO_EDIT_SUCCESS = 'GEO_EDIT_SUCCESS';
export const GEO_EDIT_FAIL = 'GEO_EDIT_FAIL';
export const GEO_EDIT_ERROR_CLEAR = 'GEO_EDIT_ERROR_CLEAR';
export const GEO_DELETE_START = 'GEO_DELETE_START';
export const GEO_DELETE_SUCCESS='GEO_DELETE_SUCCESS';
export const GEO_DELETE_FAIL='GEO_DELETE_FAIL';
export const GEO_DELETE_ERROR_CLEAR='GEO_DELETE_ERROR_CLEAR';

export const geosGetStart = (): AppActions => {
  return {
    type: GEOS_GET_START,
  };
};
export const geosGetFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: GEOS_GET_FAIL,
    payload: { error, errorMessage },
  };
};

export const geosGetSuccess = (geos: geosTypes.GeosApi[], amount: string): AppActions => {
  return {
    type: GEOS_GET_SUCCESS,
    payload: { geos, amount: parseInt(amount) },
  };
};

export const geosSearch = (search: string, searching: boolean): AppActions => {
    return {
      type: GEOS_SEARCH,
      payload: { search, searching },
    };
  };
  
  export const geosPageChange = (page: number): AppActions => {
    return {
      type: GEOS_CHANGE_PAGE,
      payload: { page },
    };
  };
  
  export const geosLimitChange = (limit: number): AppActions => {
    return {
      type: GEOS_LIMIT_CHANGE,
      payload: { limit },
    };
  };
  
  export const geosErrorClear = (): AppActions => {
    return {
      type: GEOS_ERROR_CLEAR,
    };
  };

export const getGeos = (token: string, page: number, numberOffers: number, search: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
    dispatch(geosGetStart())
    const geos =await getTableGeosApi(token,page,numberOffers,search);
    dispatch(geosGetSuccess(geos.geos,geos.amount));
  try {
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(geosGetFail(true, errorMessage ? errorMessage : err));
  }
};


//Create geo actions;
export const geoCreatestart = (): AppActions => {
    return {
      type: GEO_CREATE_START,
    };
  };
  
  export const geoCreateFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: GEO_CREATE_FAIL,
      payload: { error, errorMessage },
    };
  };
  
  export const geoCreateSuccess = (geo: geosTypes.GeosApi): AppActions => {
    return {
      type: GEO_CREATE_SUCCESS,
      payload:geo,
    };
  };
  
  export const geoCreateErrorClear = (): AppActions => {
    return {
      type: GEO_CREATE_CLEAR_ERROR,
    };
  };
  
  export const createGeo = (token: string, geo: geosTypes.GeoCreate) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(geoCreatestart());
       const createdGeo= await createGeoApi(token, geo);
  
      dispatch(geoCreateSuccess(createdGeo.geo));
      dispatch(getGeos(token, 0, 10, ''));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(geoCreateFail(true, errorMessage ? errorMessage : err));
    }
  };


  //Get Single Geo
export const singleGeoGetStart = (): AppActions => {
    return {
      type: SINGLE_GEO_GET_START,
    };
  };
  
  export const singleGeoGetSuccess = (account: geosTypes.GeosApi): AppActions => {
    return {
      type: SINGLE_GEO_GET_SUCCESS,
      payload: account,
    };
  };
  
  export const singleGeoGetFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: SINGLE_GEO_GET_FAIL,
      payload: { error, errorMessage },
    };
  };
  export const singleGeoErrorClear = () => {
    return {
      type: SINGLE_GEO_ERROR_CLEAR,
    };
  };
  
  export const getSingleGeo = (token: string, geoId: number) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(singleGeoGetStart());
      const geo = await getOneGeoApi(token, geoId);
      dispatch(singleGeoGetSuccess(geo.geo));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(singleGeoGetFail(true, errorMessage ? errorMessage : err));
    }
  };

  //Edit Geos
export const editGeoStart = ():AppActions => {
    return {
      type: GEO_EDIT_START,
    };
  };
  
  export const editGeoFail = (error: boolean, errorMessage: string): AppActions => {
    return {
      type: GEO_EDIT_FAIL,
      payload: { error, errorMessage },
    };
  };
  export const editGeoSuccess = (geo: geosTypes.GeosApi): AppActions => {
    return {
      type: GEO_EDIT_SUCCESS,
      payload: geo,
    };
  };
  
  export const editGeoErrorClear = (): AppActions => {
    return {
      type: GEO_EDIT_ERROR_CLEAR,
    };
  };
  
  export const editGeo = (token: string, geoInfo:geosTypes.GeoCreate ,geoId: number) => async (
    dispatch: ThunkDispatchType,
    getState: () => AppState
  ) => {
    try {
      dispatch(editGeoStart());
      const editGeo = await editGeoApi(token,geoInfo,geoId);
      dispatch(editGeoSuccess(editGeo.geo));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(editGeoFail(true, errorMessage ? errorMessage : err));
    }
  };
  
  //Delete Geos
  
  export const deleteGeoStart=():AppActions=>{
    return{
      type:GEO_DELETE_START,
    }
  }
  
  export const deleteGeoFail=(error: boolean, errorMessage: string):AppActions=>{
    return{
      type:GEO_DELETE_FAIL,
      payload:{error,errorMessage}
    }
  }
  
  export const deleteGeoSuccess=(account:geosTypes.DeletedGeo):AppActions=>{
    return{
      type:GEO_DELETE_SUCCESS,
      payload:account
    }
  }
  
  export const deleteGeoErrorClear=():AppActions=>{
    return{
      type:GEO_DELETE_ERROR_CLEAR
    }
  }
  export const deleteGeo=(token:string,geoId:number)=>async(dispatch:ThunkDispatchType,getState:()=>AppState)=>{
    try {
      dispatch(deleteGeoStart());
      const geo = await deleteGeoApi(token,geoId);
      dispatch(deleteGeoSuccess(geo.geo));
    } catch (err) {
      const errorMessage = errorMessageBuilder(err);
      dispatch(deleteGeoFail(true, errorMessage ? errorMessage : err))
    }
  }