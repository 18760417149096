import { Button, Skeleton } from "@material-ui/core";
import React from "react";


export default function EditGeoSkeleton() {

    return (
        <div>
            <Skeleton />
            <Skeleton />
            
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Skeleton style={{ marginRight: '1.25rem' }} ><Button variant='contained' color='primary' >Submit</Button></Skeleton>
                <Skeleton><Button variant='contained' color='primary' >Submit</Button></Skeleton>
            </div>

        </div>
    )
}