import { Button, Popover } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import rolesDictionary from '../permissions/rolesDictionary';
import tableMenuStyles from './tableMenusStyles';

const useStyles = tableMenuStyles;

interface Props {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLInputElement;
  onClickButton: () => void;
}
const TablesMenu: React.FC<Props> = ({
  open,
  onClose,
  anchorEl,
  onClickButton,
}) => {
  const authState = useSelector((state: AppState) => state.auth);

  const styles = useStyles();
  return (
    <Popover
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      className={styles.tableMenu}
      style={{ zIndex: 1200 }}
    >
      <Button
        className={styles.menuButtons}
        variant='contained'
        color='primary'
        onClick={onClickButton}
      >
        {authState.currentUser!.role.id !== rolesDictionary.client
          ? 'Edit'
          : 'View'}
      </Button>
    </Popover>
  );
};

export default TablesMenu;
