import { makeStyles, Theme } from '@material-ui/core';
const drawerWidth = 240;

const appMenuStyles = makeStyles((theme: Theme) => {
  return {
    drawerMenuClose: {
      height: '6.25rem',
      backgroundColor: '#fff',
      transition: 'background-color 300ms linear',
    },
    drawerMenuOpen: {
      height: '6.25rem',
      backgroundColor: theme.palette.secondary.main,
      transition: 'background-color 200ms linear',
    },
    drawerMenuInner: {
      height: '6.25rem',
    },

    drawer: {
      backgroundColor: theme.palette.secondary.main,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        border: 'none',
      },
      color: theme.palette.background.default,
      overflowX: 'hidden',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `5rem`,
    },
    iconsWrapper: {
      marginTop: '3.125rem',
      paddingLeft: '1.5625rem',
    },
    menuItem: {
      paddingLeft: 0,
      paddingBottom: '2.5rem',
      flexWrap: 'nowrap',
    },
    menuLink: {
      display: 'flex',
      textDecoration: 'none',
      color: theme.palette.background.default,
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    menuLinkActive: {
      fontSize: '24px',
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        width: '24px',
        height: '24px',
      },
    },
    menuItemIcon: {
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
      '&.MuiListItemIcon-root': {
        color: theme.palette.background.default,
      },
      
    },
    userWrapper: {
      paddingLeft: '1.5625rem',
      marginTop: 'auto',
    },
    buttons: {
      padding: 0,
      color: theme.palette.background.default,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
});

export default appMenuStyles;
