import { makeStyles,Theme } from "@material-ui/core";



const campaignTableStyles = makeStyles((theme: Theme) => ({
    switchBase: {
        color: theme.palette.error.main,
        '&.Mui-checked': {
            color: theme.palette.success.main
        },
        '&.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.success.main
        }
    },
    track: {
        backgroundColor: theme.palette.error.main,

    }

}));
export default campaignTableStyles;