import * as stratTypes from './strategiesTypes';
import * as startActions from './strategiesActions';
const initialSingleStrategy: stratTypes.StrategiesApi = { id: 0, name: '' };
const initialState: stratTypes.StrategiesState = {
  strategies: null,
  page: 0,
  limit: 10,
  amount: 0,
  search: '',
  searching: false,
  loading: false,
  error: false,
  errorMessage: null,
  singleStrategy: initialSingleStrategy,
  singleStrategyError: false,
  singleStrategyErrorMsg: null,
  singleStrategyLoading: false,
  createError: false,
  createErrorMsg: null,
  createLoading: false,
  created: null,
  editError: false,
  editErrorMessage: null,
  editStrategyInfo: null,
  editLoading: false,
  deleteStrategyLoading: false,
  deletedStrategy: null,
  deleteStrategyError: false,
  deleteStrategyErroMsg: null,
};

const strategiesGetStart = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    loading: true,
  };
};

const strategiesGetSuccess = (state: stratTypes.StrategiesState, action: stratTypes.GetStrategiesSucces): stratTypes.StrategiesState => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
    strategies: action.payload.strategies,
    amount: action.payload.amount,
    searching: false,
  };
};
const strategiesGetFail = (state: stratTypes.StrategiesState, action: stratTypes.GetStrategiesFail): stratTypes.StrategiesState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};

const strategiesSearch = (state: stratTypes.StrategiesState, action: stratTypes.StrategiesSearch): stratTypes.StrategiesState => {
  return {
    ...state,
    search: action.payload.search,
    searching: action.payload.searching,
    error: false,
    errorMessage: null,
    loading: false,
    page:0
  };
};
const strategiesChangePage = (state: stratTypes.StrategiesState, action: stratTypes.StrategiesChangePage): stratTypes.StrategiesState => {
  return {
    ...state,
    page: action.payload.page,
  };
};

const strategiesLimitChange = (state: stratTypes.StrategiesState, action: stratTypes.StrategiesLimitChage): stratTypes.StrategiesState => {
  return {
    ...state,
    limit: action.payload.limit,
  };
};

const strategiesErrorClear = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    error: false,
    errorMessage: null,
  };
};

const strategyCreateStart = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    createLoading: true,
  };
};
const strategyCreateFail = (state: stratTypes.StrategiesState, action: stratTypes.StrategyCreateFail): stratTypes.StrategiesState => {
  return {
    ...state,
    createLoading: false,
    createError: action.payload.error,
    createErrorMsg: action.payload.errorMessage,
  };
};

const strategyCreateSuccess = (state: stratTypes.StrategiesState, action: stratTypes.StrategyCreateSuccess): stratTypes.StrategiesState => {
  return {
    ...state,
    created: action.payload,
    createLoading: false,
    createError: false,
    createErrorMsg: null,
  };
};

const strategyCreateErrorClear = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    createError: false,
    createErrorMsg: null,
    created: null,
  };
};

const singleStrategyGetStart = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
    return {
      ...state,
      singleStrategyLoading: true,
    };
  };
  const singleStrategyGetSuccess = (
    state: stratTypes.StrategiesState,
    action: stratTypes.SingleStrategyGetSuccess
  ): stratTypes.StrategiesState => {
    return {
      ...state,
      singleStrategy: action.payload,
      singleStrategyLoading: false,
      singleStrategyError: false,
    };
  };
  const singleStrategyGetFail = (
    state: stratTypes.StrategiesState,
    action: stratTypes.SingleStrategyGetFail
  ): stratTypes.StrategiesState => {
    return {
      ...state,
      singleStrategyError: action.payload.error,
      singleStrategyErrorMsg: action.payload.errorMessage,
      singleStrategy: initialSingleStrategy,
    };
  };
  const singleStrategyErrorClear = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
    return {
      ...state,
      singleStrategyError: false,
      singleStrategyErrorMsg: null,
    };
  };

const strategyEditStart = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    editLoading: true,
  };
};
const strategyEditSuccess = (state: stratTypes.StrategiesState, action: stratTypes.StrategyEditSuccess): stratTypes.StrategiesState => {
  return {
    ...state,
    editStrategyInfo: action.payload,
    editError: false,
    editErrorMessage: null,
    editLoading: false,
  };
};

const strategyEditFail = (state: stratTypes.StrategiesState, action: stratTypes.StrategyEditFail): stratTypes.StrategiesState => {
  return {
    ...state,
    editStrategyInfo: null,
    editError: action.payload.error,
    editErrorMessage: action.payload.errorMessage,
    editLoading: false,
  };
};
const strategyEditErrorClear = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    editError: false,
    editErrorMessage: null,
    editStrategyInfo: null,
  };
};

const strategyDeleteStart = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    deleteStrategyLoading: true,
  };
};
const strategyDeleteFail = (state: stratTypes.StrategiesState, action: stratTypes.StrategyDeleteFail): stratTypes.StrategiesState => {
  return {
    ...state,
    deleteStrategyLoading: false,
    deleteStrategyError: action.payload.error,
    deleteStrategyErroMsg: action.payload.errorMessage,
  };
};

const strategyDeleteSuccess = (state: stratTypes.StrategiesState, action: stratTypes.StrategyDeleteSuccess): stratTypes.StrategiesState => {
  return {
    ...state,
    deletedStrategy: action.payload,
    deleteStrategyLoading: false,
    deleteStrategyError: false,
    deleteStrategyErroMsg: null,
  };
};
const strategyDeleteClearError = (state: stratTypes.StrategiesState): stratTypes.StrategiesState => {
  return {
    ...state,
    deleteStrategyError: false,
    deleteStrategyErroMsg: null,
    deletedStrategy: null,
  };
};

const reducer = (state: stratTypes.StrategiesState = initialState, action: stratTypes.StrategiesActionsTypes) => {
  switch (action.type) {
    case startActions.STRATEGIES_GET_START:
      return strategiesGetStart(state);
    case startActions.STRATEGIES_GET_SUCCESS:
      return strategiesGetSuccess(state, action);
    case startActions.STRATEGIES_GET_FAIL:
      return strategiesGetFail(state, action);
    case startActions.STRATEGIES_SEARCH:
      return strategiesSearch(state, action);
    case startActions.STRATEGIES_CHANGE_PAGE:
      return strategiesChangePage(state, action);
    case startActions.STRATEGIES_LIMIT_CHANGE:
      return strategiesLimitChange(state, action);
    case startActions.STRATEGIES_ERROR_CLEAR:
      return strategiesErrorClear(state);
    case startActions.STRATEGY_CREATE_START:
      return strategyCreateStart(state);
    case startActions.STRATEGY_CREATE_SUCCESS:
      return strategyCreateSuccess(state, action);
    case startActions.STRATEGY_CREATE_FAIL:
      return strategyCreateFail(state, action);
    case startActions.STRATEGY_CREATE_CLEAR_ERROR:
      return strategyCreateErrorClear(state);
    case startActions.SINGLE_STRATEGY_GET_START:
      return singleStrategyGetStart(state);
    case startActions.SINGLE_STRATEGY_GET_SUCCESS:
      return singleStrategyGetSuccess(state, action);
    case startActions.SINGLE_STRATEGY_GET_FAIL:
      return singleStrategyGetFail(state, action);
    case startActions.SINGLE_STRATEGY_ERROR_CLEAR:
        return singleStrategyErrorClear(state)
    case startActions.STRATEGY_EDIT_START:
      return strategyEditStart(state);
    case startActions.STRATEGY_EDIT_SUCCESS:
      return strategyEditSuccess(state, action);
    case startActions.STRATEGY_EDIT_FAIL:
      return strategyEditFail(state, action);
    case startActions.STRATEGY_EDIT_ERROR_CLEAR:
      return strategyEditErrorClear(state);
    case startActions.STRATEGY_DELETE_START:
      return strategyDeleteStart(state);
    case startActions.STRATEGY_DELETE_FAIL:
      return strategyDeleteFail(state, action);
    case startActions.STRATEGY_DELETE_SUCCESS:
      return strategyDeleteSuccess(state, action);
    case startActions.STRATEGY_DELETE_ERROR_CLEAR:
      return strategyDeleteClearError(state);
    default:
      return state;
  }
};

export default reducer;
