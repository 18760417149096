import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { checkTokenUser } from '../authApi';

//Checks the token for reset password.
const AuthTokenCheck: React.FC = (props) => {
  //Gets the uuid from the parameter of the link.
  const { id, token }: { id: string; token: string } = useParams();
  //Store the state if the server responds with an error or not.
  const [tokenCheckError, setTokenCheckError] = useState(false);
  //Function to check on the server if the token is active or not.
  const checkToken = useCallback(async () => {
    try {
      await checkTokenUser(id, token);
      setTokenCheckError(false);
    } catch (err) {
      if (err.response.status === 400) {
        setTokenCheckError(true);
      }
    }
  }, [id, token]);
  useEffect(() => {
    checkToken();
  }, [checkToken]);
  //If there is an error it will redirect to sign in.
  let child: React.ReactNode = <Redirect to='/signIn' />;
  //Else it will render the child.
  if (!tokenCheckError) {
    child = props.children;
  }
  return <>{child}</>;
};

export default AuthTokenCheck;
