import { makeStyles,Theme } from "@material-ui/core";


const tableMenuStyles = makeStyles((theme: Theme) => ({
    tableMenu: {
            
        '& .MuiPopover-paper': {
            width: 'calc(100vw - 18.75rem)',
            padding:'0.3125rem',
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor:'rgba(169, 172, 185,0)',
        },
    },
    menuButtons:{
        margin:'0 0.625rem'
    }
}));

export default tableMenuStyles;