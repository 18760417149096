import React, {
  ChangeEvent,
  useState,
  FocusEvent,
  FormEvent,
  SyntheticEvent,
} from 'react';
import {
  TextField,
  Typography,
  Button,
  Checkbox,
  FormLabel,
  AutocompleteChangeReason,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import AutoCompleteUsers from './AutoCompleteUsers/AutoCompleteUsers';
import rolesDictionary from '../../shared/permissions/rolesDictionary';
import createAccountStyles from './createAccountStyles';
import { CreateAccountObject } from '../accountsTypes';
import { accountCreateErrorClear, createAccount } from '../accountsActions';
import { Close } from '@material-ui/icons';

const useStyles = createAccountStyles;

const CreateAccount: React.FC = () => {
  //The authenticated user state from the reducer.
  const authState = useSelector((state: AppState) => state.auth);
  //The account state from the reducer.
  const accountState = useSelector((state: AppState) => state.accounts);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Component styles.
  const styles = useStyles();
  // State to store the account name value and if it has an error.
  const [accountName, setAccountName] = useState({ value: '', error: false });
  //Manages changes on the controlled account name input.
  const onChangeAccountName = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountName({ value: event.target.value, error: false });
  };
  // Check if there is any error on the account input name.
  const onBlurAccount = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setAccountName((prev) => ({ ...prev, error: true }));
    }
  };
  //State to store the domain input
  const [domain, setDomain] = useState({
    value: '',
    error: false,
    errorMessage: '',
  });
  //Sets the value of the domain when changed
  const onChangeDomain = (event: ChangeEvent<HTMLInputElement>) => {
    setDomain({ value: event.target.value, error: false, errorMessage: '' });
  };
  //Checks if the domain is a valid value.
  const onBlurDomain = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    try {
      const url = new URL(event.target.value);
      if (url.protocol !== 'https:') {
        setDomain((prev) => ({
          ...prev,
          error: true,
          errorMessage: 'Use https',
        }));
      }
    } catch (err) {
      setDomain((prev) => ({
        ...prev,
        error: true,
        errorMessage: 'Enter a valid URL',
      }));
    }
  };

  // State to store the volumm click id param.
  const [volummParam, setVolummParam] = useState('');
  //Manages changes on the controlled volumm param input.
  const onChangeVolummParam = (event: ChangeEvent<HTMLInputElement>) => {
    setVolummParam(event.target.value);
  };
  //Store the affiliate checkbox value.
  const [isAffiliate, setIsAffiliate] = useState(false);
  //Manage the change of value of the  affiliate value.
  const onChangeAffiliate = () => {
    setIsAffiliate((prev) => !prev);
  };

  //Store the state of the accountManagers for this account.
  const [accountManagers, setAccountManagers] = useState<number[]>([]);
  //manage the change of value for the account managers.
  const changeAccountManagers = (
    event: SyntheticEvent,
    value: { name: string; id: number; lastName: string }[],
    reason: AutocompleteChangeReason
  ) => {
    setAccountManagers(value.map((el) => el.id));
  };
  //Store the state of the tech accountManagers for this account.
  const [techAccManagers, setTechAccManagers] = useState<number[]>([]);
  //manage the change of value for the tech account managers.
  const changeTechAccManagers = (
    event: SyntheticEvent,
    value: { name: string; id: number; lastName: string }[],
    reason: AutocompleteChangeReason
  ) => {
    setTechAccManagers(value.map((el) => el.id));
  };
  //Store the state of the clients for this account.
  const [clients, setClients] = useState<number[]>([]);
  //manage the change of value for the clients.
  const changeClients = (
    event: SyntheticEvent,
    value: { name: string; id: number; lastName: string }[],
    reason: AutocompleteChangeReason
  ) => {
    setClients(value.map((el) => el.id));
  };
  //Manages the submisssion for the account form.
  const formSubmit = (event: FormEvent) => {
    //Prevents the form for posting to the server.
    event.preventDefault();
    //Build the account object.
    const accountObject: CreateAccountObject = {
      name: accountName.value,
      domain: domain.value,
      voluumClickId: volummParam,
      isAffiliate: isAffiliate,
      accountManagers: accountManagers,
      techAccManagers: techAccManagers,
      users: clients,
    };
    //Dispatch the action so the reducer sends the data to the server.
    dispatch(createAccount(authState.currentUser!.token, accountObject));
  };
  //If there is any error the button will be disable.
  const error =
    accountName.error ||
    domain.error ||
    accountManagers.length === 0 ||
    techAccManagers.length === 0;
  //Clears the error pop up.
  const onClearError = () => {
    dispatch(accountCreateErrorClear());
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Create new Account
        </Typography>
      </div>

      <form className={styles.form} onSubmit={formSubmit}>
        <TextField
          value={accountName.value}
          name='account-name'
          onChange={onChangeAccountName}
          onBlur={onBlurAccount}
          error={accountName.error}
          className={`${styles.inputs} ${styles.firstInputText}`}
          label='Acount name'
          variant='standard'
          helperText={accountName.error ? 'No less than 3 characers' : ' '}
          required
        />
        <TextField
          type='url'
          name='domain'
          value={domain.value}
          onChange={onChangeDomain}
          onBlur={onBlurDomain}
          error={domain.error}
          className={styles.inputs}
          label='Account website'
          variant='standard'
          helperText={domain.error ? domain.errorMessage : ' '}
          required
        />
        <TextField
          type='text'
          name='clickid'
          value={volummParam}
          onChange={onChangeVolummParam}
          className={styles.inputs}
          label='Voluum Click id param'
          variant='standard'
          helperText={'Without {} it will be added on the server'}
        />

        <div className={styles.affiliateWrapper}>
          <FormLabel component='label'>
            Is it an affiliate network?
            <Checkbox checked={isAffiliate} onChange={onChangeAffiliate} />
          </FormLabel>
        </div>

        {authState.currentUser!.role.id !== rolesDictionary.client && (
          <div className={styles.accountUsers}>
            <Typography>Assigned to:</Typography>
            <AutoCompleteUsers
              token={authState.currentUser!.token}
              roleId={rolesDictionary.accountManager}
              label={'Account Managers'}
              changeValue={changeAccountManagers}
            />
            <AutoCompleteUsers
              token={authState.currentUser!.token}
              roleId={rolesDictionary.techAccountManager}
              label={'Tech Account Managers'}
              changeValue={changeTechAccManagers}
            />
            <AutoCompleteUsers
              token={authState.currentUser!.token}
              roleId={rolesDictionary.client}
              label={'Client'}
              changeValue={changeClients}
            />
          </div>
        )}

        <Button
          className={styles.button}
          variant='contained'
          color='primary'
          disabled={
            error || accountState.createLoading || accountState.createError
          }
        >
          {accountState.createLoading ? <CircularProgress /> : 'Submit'}
        </Button>
      </form>
      <Snackbar
        open={accountState.createError || Boolean(accountState.created)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={accountState.createError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {accountState.createError
            ? accountState.createErrorMessage
            : 'Account created!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateAccount;
