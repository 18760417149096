import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, {
  ChangeEvent,
  useState,
  FocusEvent,
  useRef,
  FormEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import { authEditProfile, authEditProfileErrorClear } from '../authActions';
import profileStyles from './profileStyles';

const useStyles = profileStyles;
interface Props {
  closeModal: () => void;
}

const Profile: React.FC<Props> = (props) => {
  //The authenticated user state from the reducer.
  const authState = useSelector((state: AppState) => state.auth);
  //Component styles.
  const styles = useStyles();
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Store the current users name
  const [name, setName] = useState({
    value: authState.currentUser!.name,
    error: false,
  });
  //Changes the user name when the user types.
  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName((prev) => ({ ...prev, value: event.target.value }));
  };
  //Checks if the user name is correct.
  const onBlurName = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 3) {
      setName((prev) => ({ ...prev, error: true }));
    } else {
      setName((prev) => ({ ...prev, error: false }));
    }
  };
  //Store the state of the last name input.
  const [lastName, setLastName] = useState({
    value: authState.currentUser!.lastName,
    error: false,
  });
  //Changes the user last name when the user types.
  const onChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName((prev) => ({ ...prev, value: event.target.value }));
  };
  //Checks if the user last name is correct.
  const onBlurLastName = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 3) {
      setLastName((prev) => ({ ...prev, error: true }));
    } else {
      setLastName((prev) => ({ ...prev, error: false }));
    }
  };
  //Store the state of the email input.
  const [email, setEmail] = useState({
    value: authState.currentUser!.email,
    error: false,
  });
  //Changes the user email when the user types.
  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail((prev) => ({ ...prev, value: event.target.value }));
  };
  //Check if the email is valid when the user clicks away from the input.
  const onBlurEmail = (event: FocusEvent<HTMLInputElement>) => {
    let res = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    setEmail((prev) => ({ ...prev, error: !res.test(event.target.value) }));
  };
  //Stores the reference of the upload profile picture.
  const fileUploader = useRef<HTMLInputElement>(null);
  // executes the click event like if the user has click the file input.
  const upload = () => {
    if (fileUploader.current) {
      fileUploader.current.click();
    }
  };
  //Stores the file and url of the current uploaded picture.
  const [picture, setPicture] = useState<{ file: File; url: string } | null>({
    file: new File([], 'dontTake'),
    url: authState.currentUser?.profileUrl
      ? '/images/' + authState.currentUser.profileUrl
      : '',
  });
  // If the user changes the picture or uploads a picture it store it in the picture state.
  const onChangeFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files[0]) {
        setPicture({
          file: event.target.files[0],
          url: URL.createObjectURL(event.target.files[0]),
        });
      } else {
        setPicture(null);
      }
    }
  };

  //Stores the old password value.
  const [oldPassword, setOldPassword] = useState({ value: '', error: false });
  //Changes the state when the user types on the old password.
  const onChangeOldPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setOldPassword((prev) => ({ ...prev, value: event.target.value }));
  };
  //Check if the value of the old password is correct.
  const onBlurOldPassword = (event: FocusEvent<HTMLInputElement>) => {
    setOldPassword((prev) => ({ ...prev, error: prev.value.length < 4 }));
  };
  //store new password.
  const [newPassword, setNewPassword] = useState({
    value: '',
    error: false,
    errorMessage: '',
  });
  //Handle change of new password input
  const onChangeNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword((prev) => ({ ...prev, value: event.target.value }));
  };
  //When user clicks out it checks if there is an issue with the length of the password.
  const onBlurNewPassword = (event: FocusEvent<HTMLInputElement>) => {
    setNewPassword((prev) => ({ ...prev, error: prev.value.length < 8 }));
  };
  //Store repeat password.
  const [repeatNewPassword, setRepeatNewPassword] = useState({
    value: '',
    error: false,
    errorMessage: '',
  });
  //Handle change of repeat new password input
  const onChangeRepeatNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setRepeatNewPassword((prev) => ({ ...prev, value: event.target.value }));
  };
  //When user clicks out it checks if there is an issue with the length of the password and if the two passwords are the same..
  const onBlurRepeatNewPassword = (event: FocusEvent<HTMLInputElement>) => {
    const repeatPassworValue = event.target.value;
    const newPasswordValue = newPassword.value;
    let error = repeatNewPassword.value.length < 8;
    let errorMessage = 'Set a longer password min is 8 letters';
    if (!error && repeatPassworValue !== newPasswordValue) {
      error = true;
      errorMessage = 'Passwords dont match';
    }
    setRepeatNewPassword((prev) => ({ ...prev, error: error, errorMessage }));
  };

  //Handels form submit.
  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    //Creates a multiform to handle fileupload.
    const formData = new FormData();
    formData.append('name', name.value);
    formData.append('lastName', lastName.value);
    formData.append('email', email.value);
    //If there is no picture or ther is a place holder it wont attach the file.
    if (picture?.file && picture.file.name !== 'dontTake') {
      formData.append('profilePicture', picture.file);
    }
    //Checks if there was an intent of changing the passwords.
    if (
      newPassword.value.length > 7 &&
      repeatNewPassword.value.length > 7 &&
      oldPassword.value.length > 4
    ) {
      //Checks if the values of new password and repeat new password match.
      if (newPassword.value !== repeatNewPassword.value) {
        setRepeatNewPassword((prev) => ({
          ...prev,
          error: true,
          errorMessage: 'Passwords dont match',
        }));
        return;
      }
      formData.append('oldPassword', oldPassword.value);
      formData.append('newPassword', newPassword.value);
      formData.append('repeatNewPassword', repeatNewPassword.value);
    }
    dispatch(authEditProfile(authState.currentUser!.token, formData));
  };
  //Clear error or message.
  const onClearError = () => {
    dispatch(authEditProfileErrorClear());
  };
  //Disables button if there is any error on any input.
  const error =
    name.error ||
    lastName.error ||
    email.error ||
    oldPassword.error ||
    newPassword.error ||
    repeatNewPassword.error ||
    (newPassword.value.length > 0 &&
      newPassword.value !== repeatNewPassword.value);
  return (
    <div className={styles.container}>
      <Typography variant='h3' component='h3'>
        My Profile
      </Typography>
      <form autoComplete='off' onSubmit={formSubmit}>
        <div style={{ display: 'flex', marginTop: '0.8rem' }}>
          <div style={{ marginTop: '0.625rem' }}>
            <div
              style={{
                width: '150px',
                height: '150px',
                backgroundColor: '#E1E1E1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              {picture?.file && picture.url.length > 0 && (
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={picture!.url}
                  alt='your-pictures'
                />
              )}
              {picture?.file &&
                picture.file.name === 'dontTake' &&
                picture.url.length === 0 && (
                  <Button
                    type='button'
                    style={{
                      borderRadius: '50%',
                      width: '30px',
                      height: '30px',
                      padding: 0,
                      minWidth: '30px',
                    }}
                    color='secondary'
                    variant='contained'
                    onClick={upload}
                  >
                    +
                  </Button>
                )}
              <input
                type='file'
                multiple={false}
                accept='image/png,image/jpg,image/jpeg'
                style={{ display: 'none' }}
                ref={fileUploader}
                onChange={onChangeFileUpload}
              ></input>
            </div>
            <div style={{ padding: '10px' }}>
              <Typography variant='subtitle2'>
                Upload your profile picture.
              </Typography>
              <Typography variant='subtitle2'>
                Maximum size 500px x 500px.
              </Typography>
              <div style={{ display: 'flex' }}>
                {Boolean(picture) && (
                  <Typography
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    variant='subtitle2'
                    onClick={upload}
                    color='secondary'
                  >
                    Change picture.
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div>
            <TextField
              value={name.value}
              name='user-name'
              onChange={onChangeName}
              onBlur={onBlurName}
              error={name.error}
              className={`${styles.inputs} ${styles.firstInputText}`}
              label='First name'
              variant='standard'
              helperText={name.error ? 'No less than 3 characers' : ' '}
              autoComplete='off'
              fullWidth
            />
            <TextField
              value={lastName.value}
              name='user-last-name'
              onChange={onChangeLastName}
              onBlur={onBlurLastName}
              error={lastName.error}
              className={`${styles.inputs} ${styles.firstInputText}`}
              label='Last name'
              variant='standard'
              helperText={lastName.error ? 'No less than 3 characers' : ' '}
              autoComplete='off'
              fullWidth
            />
            <TextField
              type='email'
              name='email'
              label='Email'
              variant='standard'
              className={`${styles.inputs} ${styles.firstInputText}`}
              onChange={onChangeEmail}
              onBlur={onBlurEmail}
              value={email.value}
              error={email.error}
              helperText={email.error ? 'Set a valid email' : ' '}
              autoComplete='off'
              fullWidth
            />
          </div>
        </div>
        <div style={{ marginTop: '1.875rem', display: 'flex' }}>
          <div>
            <Typography variant='h5' component='h5'>
              Change password
            </Typography>
          </div>

          <div>
            <TextField
              type='password'
              name='old-password'
              label='Old password'
              variant='standard'
              className={`${styles.inputs} ${styles.firstInputText}`}
              onChange={onChangeOldPassword}
              onBlur={onBlurOldPassword}
              value={oldPassword.value}
              error={oldPassword.error}
              helperText={
                oldPassword.error
                  ? 'Set a longer password min is 8 letters'
                  : ' '
              }
              autoComplete='off'
              fullWidth
            />
            <TextField
              type='password'
              name='new-password'
              label='New password'
              variant='standard'
              className={`${styles.inputs} ${styles.firstInputText}`}
              onChange={onChangeNewPassword}
              onBlur={onBlurNewPassword}
              value={newPassword.value}
              error={newPassword.error}
              helperText={
                newPassword.error
                  ? 'Set a longer password min is 8 letters'
                  : ' '
              }
              autoComplete='off'
              fullWidth
            />
            <TextField
              type='password'
              name='repeat-new-password'
              label='Repeat new password'
              variant='standard'
              className={`${styles.inputs} ${styles.firstInputText}`}
              onChange={onChangeRepeatNewPassword}
              onBlur={onBlurRepeatNewPassword}
              value={repeatNewPassword.value}
              error={repeatNewPassword.error}
              helperText={
                repeatNewPassword.error ? repeatNewPassword.errorMessage : ' '
              }
              autoComplete='off'
              fullWidth
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1.25rem',
          }}
        >
          <Button
            type='button'
            variant='text'
            color='primary'
            style={{ marginRight: '1.25rem' }}
            onClick={props.closeModal}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={error}
          >
            {false ? <CircularProgress /> : 'Update'}
          </Button>
        </div>
      </form>
      <Snackbar
        open={authState.error || authState.profileUpdated}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={authState.error ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {authState.error ? authState.errorMessage : 'Profile updated!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Profile;
