import { useState, useEffect, SyntheticEvent } from 'react';
import {
  Autocomplete,
  TextField,
  AutocompleteChangeReason,
} from '@material-ui/core';
import { getUsersByRoleApi } from '../../../Users/usersApi';
import autoCompleteUserStyles from './autoCompleteUsersStyles';

const useStyle = autoCompleteUserStyles;

interface Props {
  token: string;
  roleId: number;
  label: string;
  defaultValue?: { id: number; name: string; lastName: string }[];
  changeValue: (
    event: SyntheticEvent,
    value: {
      name: string;
      id: number;
      lastName: string;
    }[],
    reason: AutocompleteChangeReason
  ) => void;
}
const AutoCompleteUsers: React.FC<Props> = (props) => {
  //Manage if the autocomplete is open or not to show values.
  const [open, setOpen] = useState(false);
  // Manage the options available for the autocomplete.
  const [options, setOptions] = useState<
    { name: string; id: number; lastName: string }[]
  >([]);
  //If there is no valut it show as it is loading.
  const loading = open && options.length === 0;
  //Gets users from the server.
  useEffect(() => {
    let unmounted = false;

    async function getUsers() {
      try {
        const users = await getUsersByRoleApi(props.token, props.roleId);
        if (!unmounted) {
          setOptions(users.users);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getUsers();
    return () => {
      unmounted = true;
    };
  }, [props.token, props.roleId]);

  const style = useStyle();
  return (
    <Autocomplete
      value={props.defaultValue}
      fullWidth={false}
      className={style.autoCompleteStandard}
      open={open}
      multiple
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      options={options}
      loading={loading}
      onChange={props.changeValue}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        option.name + (option.lastName ? ' ' + option.lastName : '')
      }
      renderInput={(params) => (
        <TextField
          {...params}
          className={style.input}
          fullWidth={false}
          variant='standard'
          label={props.label}
        />
      )}
    />
  );
};

export default AutoCompleteUsers;
