import { makeStyles, Theme } from "@material-ui/core";


const autoCompleteStyles = makeStyles((theme: Theme) => ({
    autoCompleteOutlined: {
        width: 'fit-content',
        margin: 0,
        padding: '0',
        backgroundColor: '#A9ACB9;',
        border: 'none',
        marginRight: '10px',
        '& .MuiIconButton-label': {
            color: 'white'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            marginTop: 0,
            height: '2.475rem',
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: 0
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 12px) scale(1)',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)'
        }

    },
    inputOutlined: {
        minWidth: '10.25rem',
        height: '2.475rem',
        marginTop: 0,
        padding: 0,
    },
    autoCompleteStandard: {
        display: 'flex',
        padding: '0',
        minWidth: '12.20rem',
        minHeight:'4.375rem',
        '& .MuiInputLabel-root': {
            color: theme.palette.text.primary
        }
    },
    inputStandard: {
        minWidth: '12.20rem',

    }
}));

export default autoCompleteStyles;