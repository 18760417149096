import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const campaignFiltersStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingRight: "6.25rem",
    paddingLeft: "1.9375rem",
    marginBottom: "4.3125rem",
    marginTop: "0.3125rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "1.5rem",
  },
  chipContainer: {
    minHeight: "50px",
    minWidth: "200px",
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    padding: "12px",
    maxWidth: "500px",
    flexWrap: "wrap",
  },
  chip: {
    margin: "1px",
  },
  statusChipOn: {
    backgroundColor: theme.palette.success.light,
  },
  statusChipOff: {
    backgroundColor: theme.palette.error.main,
  },
  datPicker: {
    '& .react-daterange-picker__wrapper': {
        border: 'none',
        borderBottom: 'thin solid gray'
    },
    '& .react-daterange-picker__calendar': {
        width: 'auto'
    }
},
}));

export default campaignFiltersStyles;
