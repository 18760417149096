import React, { ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import { usersLimitChange, usersPageChange } from '../usersActions';
import TablesMenu from '../../shared/TablesMenu/TablesMenu';
import { UsersApi } from '../usersTypes';
import TableComponent from '../../shared/TableComponent/TableComponent';
const tableDefinition = [
  { title: 'Id', value: (user: UsersApi) => user.id },
  { title: 'Name', value: (user: UsersApi) => user.name },
  { title: 'Last Name', value: (user: UsersApi) => user.lastName },
  { title: 'Email', value: (user: UsersApi) => user.email },
  { title: 'Role', value: (user: UsersApi) => user.role.name },
  { title: 'Status', value: (user: UsersApi) => user.userStatus },
];

interface Props {
  setOpenEditModal: () => void;
  openTableMenu: { id: number; element: null | HTMLInputElement };
  onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  onCloseTableMenu: () => void;
}
const UsersTable: React.FC<Props> = (props) => {
  //The users state from the reducer.
  const userState = useSelector((state: AppState) => state.users);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();

  //Changes the page from the table.
  const onChangePage = (page: number) => {
    //Sends the new page value to the reducer.
    dispatch(usersPageChange(page));
  };
  //Changes the limit of the table.
  const onChangeLimit = (limit: number) => {
    //Sends the limit to the reducer.
    dispatch(usersLimitChange(limit));
  };

  return (
    <>
      <TableComponent
        name='Users'
        tableDefinition={tableDefinition}
        items={userState.users}
        amount={userState.amount}
        limit={userState.limit}
        loading={userState.loading}
        error={userState.error}
        page={userState.page}
        openMenuId={props.openTableMenu.id}
        onChangeCheckbox={props.onChangeCheckbox}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
      />
      <TablesMenu
        open={Boolean(props.openTableMenu.element)}
        anchorEl={props.openTableMenu.element}
        onClose={props.onCloseTableMenu}
        onClickButton={props.setOpenEditModal}
      />
    </>
  );
};

export default UsersTable;
