import React, {
  ChangeEvent,
  useState,
  FocusEvent,
  FormEvent,
  SyntheticEvent,
  useEffect,
} from 'react';
import {
  TextField,
  Typography,
  Button,
  Checkbox,
  FormLabel,
  AutocompleteChangeReason,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import AutoCompleteUsers from '../CreateAccount/AutoCompleteUsers/AutoCompleteUsers';
import rolesDictionary from '../../shared/permissions/rolesDictionary';
import createAccountStyles from '../CreateAccount/createAccountStyles';
import { EditAccountObject } from '../accountsTypes';
import {
  deleteAccount,
  deleteAccountErrorClear,
  editAccount,
  editAccountErrorClear,
  getAccounts,
  getSingleAccount,
  singleAccountErrorClear,
} from '../accountsActions';
import { Close } from '@material-ui/icons';
import EditAccountSkeleton from './EditAccountSkeleton';
import DeleteDialog from '../../shared/DeleteDialog/DeleteDialog';
const useStyles = createAccountStyles;

interface Props {
  accountId: number;
  closeModal: () => void;
  closeMenu: () => void;
}
const EditAccount: React.FC<Props> = (props) => {
  //The authenticated user state from the reducer.
  const authState = useSelector((state: AppState) => state.auth);
  //The account state from the reducer.
  const accountState = useSelector((state: AppState) => state.accounts);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Component styles.
  const styles = useStyles();
  // It gets the selected account from the server.
  useEffect(() => {
    dispatch(getSingleAccount(authState.currentUser!.token, props.accountId));
  }, [dispatch, authState.currentUser, props.accountId]);

  //Sets the values to the respective values from the account.
  useEffect(() => {
    setAccountName({ error: false, value: accountState.singleAccount!.name });
    setDomain({
      error: false,
      errorMessage: '',
      value: accountState.singleAccount!.domain,
    });
    setIsAffiliate(accountState.singleAccount!.isAffiliate);
    setVolummParam(accountState.singleAccount!.voluumClickIdParam!);
    if (
      accountState.singleAccount?.accountManager &&
      accountState.singleAccount?.technicalAccountManager &&
      accountState.singleAccount!.accountsUsers
    ) {
      setAccountManagers(
        accountState.singleAccount!.accountManager.map((user) => ({
          name: user.users.name,
          id: user.users.id,
          lastName: user.users.lastName,
        }))
      );
      setTechAccManagers(
        accountState.singleAccount!.technicalAccountManager.map((user) => ({
          name: user.users.name,
          id: user.users.id,
          lastName: user.users.lastName,
        }))
      );
      setClients(
        accountState.singleAccount!.accountsUsers.map((user) => ({
          name: user.users.name,
          id: user.users.id,
          lastName: user.users.lastName,
        }))
      );
    }
  }, [props.accountId, accountState.singleAccount]);
  // State to store the account name value and if it has an error.
  const [accountName, setAccountName] = useState({
    value: accountState.singleAccount!.name,
    error: false,
  });
  //Manages changes on the controlled account name input.
  const onChangeAccountName = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountName({ value: event.target.value, error: false });
  };
  // Check if there is any error on the account input name.
  const onBlurAccount = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setAccountName((prev) => ({ ...prev, error: true }));
    }
  };
  //State to store the domain input
  const [domain, setDomain] = useState({
    value: accountState.singleAccount!.domain,
    error: false,
    errorMessage: '',
  });
  //Sets the value of the domain when changed
  const onChangeDomain = (event: ChangeEvent<HTMLInputElement>) => {
    setDomain({ value: event.target.value, error: false, errorMessage: '' });
  };
  //Checks if the domain is a valid value.
  const onBlurDomain = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    try {
      const url = new URL(event.target.value);
      if (url.protocol !== 'https:') {
        setDomain((prev) => ({
          ...prev,
          error: true,
          errorMessage: 'Use https',
        }));
      }
    } catch (err) {
      setDomain((prev) => ({
        ...prev,
        error: true,
        errorMessage: 'Enter a valid URL',
      }));
    }
  };

  // State to store the volumm click id param.
  const [volummParam, setVolummParam] = useState('');
  //Manages changes on the controlled volumm param input.
  const onChangeVolummParam = (event: ChangeEvent<HTMLInputElement>) => {
    setVolummParam(event.target.value);
  };
  //Store the affiliate checkbox value.
  const [isAffiliate, setIsAffiliate] = useState(
    accountState.singleAccount!.isAffiliate
  );
  //Manage the change of value of the  affiliate value.
  const onChangeAffiliate = () => {
    setIsAffiliate((prev) => !prev);
  };

  //Store the state of the accountManagers for this account.
  const [accountManagers, setAccountManagers] = useState<
    { name: string; id: number; lastName: string }[]
  >([]);
  //manage the change of value for the account managers.
  const changeAccountManagers = (
    event: SyntheticEvent,
    value: { name: string; id: number; lastName: string }[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason !== 'clear') {
      setAccountManagers(value);
    }
    if (reason === 'clear') {
      setAccountManagers([]);
    }
  };
  //Store the state of the tech accountManagers for this account.
  const [techAccManagers, setTechAccManagers] = useState<
    { name: string; id: number; lastName: string }[]
  >([]);
  //manage the change of value for the tech account managers.
  const changeTechAccManagers = (
    event: SyntheticEvent,
    value: { name: string; id: number; lastName: string }[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason !== 'clear') {
      setTechAccManagers(value);
    }
    if (reason === 'clear') {
    }
  };
  //Store the state of the clients for this account.
  const [clients, setClients] = useState<
    { name: string; id: number; lastName: string }[]
  >([]);
  //manage the change of value for the clients.
  const changeClients = (
    event: SyntheticEvent,
    value: { name: string; id: number; lastName: string }[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason !== 'clear') {
      setClients(value);
    }
    if (reason === 'clear') {
      setClients([]);
    }
  };
  //Manages the submisssion for the account form.
  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    //Create a copy of the selected account managers as Ids
    const accountManagersSelected = accountManagers.map((accM) => accM.id);
    //Create a copy of the selected tech account managers as ids.
    const techAccountManagersSelected = techAccManagers.map(
      (techAccM) => techAccM.id
    );
    //Create a copy of the selected clients as ids.
    const users = clients.map((user) => user.id);
    //Check if there was a deleted account manager by filtering the account managers in the singleAccount that were not found in the selected account managers.
    const deletedAccountManagers = accountState
      .singleAccount!.accountManager.filter(
        (el) => !accountManagersSelected.includes(el.userId)
      )
      .map((el) => el.userId);
    //Check if there was a deleted tec account manager by filtering the tec account managers in the singleAccount that were not found in the selected tech account managers.
    const deletedTechAccManagers = accountState
      .singleAccount!.technicalAccountManager.filter(
        (el) => !techAccountManagersSelected.includes(el.userId)
      )
      .map((el) => el.userId);
    //Check if there was a deleted client by filtering the  clients in the singleAccount that were not found in the selected clients.
    const deletedUsers = accountState
      .singleAccount!.accountsUsers.filter((el) => !users.includes(el.userId))
      .map((el) => el.userId);
    //we create the object that is going to be sent to the server.
    const accountObject: EditAccountObject = {
      name: accountName.value,
      domain: domain.value,
      isAffiliate: isAffiliate,
      voluumClickId: volummParam,
      //we filtered out the account managers that already exists in the account to prevent insertion errors.
      accountManagers: accountManagersSelected.filter((user) => {
        const oldUsers = accountState.singleAccount!.accountManager.map(
          (oldUser) => oldUser.userId
        );
        if (!oldUsers.includes(user)) {
          return user;
        }
        return false;
      }),
      //we filtered out the tec account managers that already exists in the account to prevent insertion errors.
      techAccManagers: techAccountManagersSelected.filter((user) => {
        const oldUsers =
          accountState.singleAccount!.technicalAccountManager.map(
            (oldUser) => oldUser.userId
          );
        if (!oldUsers.includes(user)) {
          return user;
        }
        return false;
      }),
      ////we filtered out the clients that already exists in the account to prevent insertion errors.
      users: users.filter((user) => {
        const oldUsers = accountState.singleAccount!.accountsUsers.map(
          (oldUser) => oldUser.userId
        );
        if (!oldUsers.includes(user)) {
          return user;
        }
        return false;
      }),
      deleteAccMan: deletedAccountManagers,
      deleteTechMan: deletedTechAccManagers,
      deleteUser: deletedUsers,
    };
    dispatch(
      editAccount(
        authState.currentUser!.token,
        accountObject,
        accountState.singleAccount!.id
      )
    );
  };
  //If there is any error the button will be disable.
  const error = accountName.error || domain.error;
  //Clears the error pop up.
  const onClearError = () => {
    dispatch(editAccountErrorClear());
    //Closes the modal if the edit was successfull.
    if (!accountState.editError) {
      props.closeMenu();
      props.closeModal();
      dispatch(
        getAccounts(
          authState.currentUser!.token,
          accountState.page,
          accountState.limit,
          accountState.search
        )
      );
    }
  };
  //Dispatch the delete account.
  const deleteAcc = () => {
    dispatch(
      deleteAccount(
        authState.currentUser!.token,
        accountState.singleAccount!.id
      )
    );
  };
  //Clears the error of deletion.
  // to do check if it closes if there was an error in server when deleting.
  const clearErrorDelete = () => {
    dispatch(deleteAccountErrorClear());
    props.closeMenu();
    props.closeModal();
    dispatch(
      getAccounts(
        authState.currentUser!.token,
        accountState.page,
        accountState.limit,
        accountState.search
      )
    );
  };
  //Closes error for single account on editing.
  const clearErrorSingleAccount = () => {
    dispatch(singleAccountErrorClear());
  };
  //Manage the state of the dialog that confirms deletion.
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  //Opens the delete dialog.
  const deleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  // If user accepts it closes the dialog and proceed to delete the account.
  const acceptDeleteDialog = () => {
    setOpenDeleteDialog(false);
    deleteAcc();
  };
  //When the user rejects it just closes the dialog.
  const rejectDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  let editBody = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <TextField
        value={accountName.value}
        name='account-name'
        className={`${styles.inputs} ${styles.firstInputText}`}
        label='Acount name'
        disabled={authState.currentUser!.role.id === rolesDictionary.client}
        variant='standard'
      />
      <TextField
        type='url'
        name='domain'
        value={domain.value}
        className={styles.inputs}
        label='Account website'
        variant='standard'
        disabled={authState.currentUser!.role.id === rolesDictionary.client}
      />
    </div>
  );
  if (authState.currentUser!.role.id !== rolesDictionary.client) {
    editBody = (
      <form className={styles.form} onSubmit={formSubmit}>
        <TextField
          value={accountName.value}
          name='account-name'
          onChange={onChangeAccountName}
          onBlur={onBlurAccount}
          error={accountName.error}
          className={`${styles.inputs} ${styles.firstInputText}`}
          label='Acount name'
          variant='standard'
          helperText={accountName.error ? 'No less than 3 characers' : ' '}
          disabled={authState.currentUser!.role.id === rolesDictionary.client}
          required
        />
        <TextField
          type='url'
          name='domain'
          value={domain.value}
          onChange={onChangeDomain}
          onBlur={onBlurDomain}
          error={domain.error}
          className={styles.inputs}
          label='Account website'
          variant='standard'
          helperText={domain.error ? domain.errorMessage : ' '}
          disabled={authState.currentUser!.role.id === rolesDictionary.client}
          required
        />
        <TextField
          type='text'
          name='clickid'
          value={volummParam}
          onChange={onChangeVolummParam}
          className={styles.inputs}
          label='Voluum Click id param'
          variant='standard'
          helperText={'Without {} it will be added on the server'}
        />

        {authState.currentUser!.role.id !== rolesDictionary.client && (
          <div className={styles.affiliateWrapper}>
            <FormLabel component='label'>
              Is it an affiliate network?
              <Checkbox checked={isAffiliate} onChange={onChangeAffiliate} />
            </FormLabel>
          </div>
        )}

        {authState.currentUser!.role.id !== rolesDictionary.client &&
          !accountState.singleAccountLoading && (
            <div className={styles.accountUsers}>
              <Typography>Assigned to:</Typography>
              <AutoCompleteUsers
                token={authState.currentUser!.token}
                roleId={rolesDictionary.accountManager}
                label={'Account Managers'}
                changeValue={changeAccountManagers}
                defaultValue={accountManagers}
              />
              <AutoCompleteUsers
                token={authState.currentUser!.token}
                roleId={rolesDictionary.techAccountManager}
                label={'Tech Account Managers'}
                changeValue={changeTechAccManagers}
                defaultValue={techAccManagers}
              />
              <AutoCompleteUsers
                token={authState.currentUser!.token}
                roleId={rolesDictionary.client}
                label={'Client'}
                changeValue={changeClients}
                defaultValue={clients}
              />
            </div>
          )}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={deleteDialog}
            type='button'
            variant='text'
            color='primary'
            style={{ marginRight: '1.25rem' }}
            disabled={
              error ||
              accountState.editLoading ||
              accountState.editError ||
              accountState.deleteAccountLoading ||
              accountState.deleteAccountError
            }
          >
            <Delete style={{ marginRight: '0.3125rem' }} /> Delete
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={
              error ||
              accountState.editLoading ||
              accountState.editError ||
              accountState.deleteAccountLoading ||
              accountState.deleteAccountError
            }
          >
            {accountState.editLoading ? <CircularProgress /> : 'Submit'}
          </Button>
        </div>
      </form>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          {authState.currentUser!.role.id !== rolesDictionary.client
            ? 'Edit Account'
            : 'View Account'}
        </Typography>
      </div>

      {!accountState.singleAccountLoading && editBody}
      {accountState.singleAccountLoading && <EditAccountSkeleton />}

      <Snackbar
        open={accountState.editError || Boolean(accountState.editAccountInfo)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={accountState.editError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {accountState.editError
            ? accountState.editErrorMessage
            : 'Account edited!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={
          Boolean(accountState.deletedAccount) ||
          accountState.deleteAccountError
        }
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorDelete}
      >
        <Alert
          severity={accountState.deleteAccountError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorDelete}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {accountState.deleteAccountError
            ? accountState.deletedAccountErrorMessage
            : 'Account Deleted!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={accountState.singleAccountError}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={clearErrorSingleAccount}
      >
        <Alert
          severity='error'
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={clearErrorSingleAccount}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {accountState.singleAccountError
            ? accountState.singleAccountErrorMsg
            : 'Cant find account!'}
        </Alert>
      </Snackbar>
      <DeleteDialog
        title='Delete Account'
        bodyText='Do you want to delete this account?'
        open={openDeleteDialog}
        handleAcceptance={acceptDeleteDialog}
        handleRejection={rejectDeleteDialog}
      />
    </div>
  );
};

export default EditAccount;
