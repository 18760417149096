import React from 'react';
import { InputBase } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import searchInputStyles from './searchInputStyles';

const useStyles = searchInputStyles;

interface Props {
  extraClass?: string;
  disabled: boolean;
  searchValue: string;
  setSearch: (inputVal: string) => void;
}

const SearchBar: React.FC<Props> = ({
  extraClass,
  searchValue,
  setSearch,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.search} ${extraClass ? extraClass : ''}`}>
      <div className={classes.searchIcon}>
        <Search />
      </div>

      <InputBase
        placeholder='Search...'
        inputProps={{ 'aria-label': 'search campaigns' }}
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        value={searchValue}
        onChange={(event) => setSearch(event.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchBar;
