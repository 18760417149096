import * as userTypes from './usersTypes';
import * as userActions from './usersActions';
const initialSingleUser: userTypes.UsersApi = {
  id: 0,
  name: '',
  lastName:'',
  email: '',
  role: { id: 0, name: '' },
  roleId: 0,
  userStatus: '',
};
const initialState: userTypes.UsersState = {
  users: null,
  page: 0,
  limit: 10,
  amount: 0,
  search: '',
  searching: false,
  loading: false,
  error: false,
  errorMessage: null,
  singleUser: initialSingleUser,
  singleUserError: false,
  singleUserErrorMsg: null,
  singleUserLoading: false,
  createError: false,
  createErrorMsg: null,
  createLoading: false,
  created: null,
  editError: false,
  editErrorMessage: null,
  editUserInfo: null,
  editLoading: false,
  deleteUserLoading: false,
  deletedUser: null,
  deleteUserError: false,
  deleteUserErroMsg: null,
};

const usersGetStart = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    loading: true,
  };
};

const usersGetSuccess = (
  state: userTypes.UsersState,
  action: userTypes.GetUsersSucces
): userTypes.UsersState => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
    users: action.payload.users,
    amount: action.payload.amount,
    searching: false,
  };
};
const usersGetFail = (state: userTypes.UsersState, action: userTypes.GetUsersFail): userTypes.UsersState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};

const usersSearch = (state: userTypes.UsersState, action: userTypes.UsersSearch): userTypes.UsersState => {
  return {
    ...state,
    search: action.payload.search,
    searching: action.payload.searching,
    error: false,
    errorMessage: null,
    loading: false,
    page:0,
  };
};
const usersChangePage = (
  state: userTypes.UsersState,
  action: userTypes.UsersChangePage
): userTypes.UsersState => {
  return {
    ...state,
    page: action.payload.page,
  };
};

const usersLimitChange = (
  state: userTypes.UsersState,
  action: userTypes.UsersLimitChage
): userTypes.UsersState => {
  return {
    ...state,
    limit: action.payload.limit,
  };
};

const usersErrorClear = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    error: false,
    errorMessage: null,
  };
};

const userCreateStart = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    createLoading: true,
  };
};
const userCreateFail = (state: userTypes.UsersState, action: userTypes.UserCreateFail): userTypes.UsersState => {
  return {
    ...state,
    createLoading: false,
    createError: action.payload.error,
    createErrorMsg: action.payload.errorMessage,
  };
};

const userCreateSuccess = (
  state: userTypes.UsersState,
  action: userTypes.UserCreateSuccess
): userTypes.UsersState => {
  return {
    ...state,
    created: action.payload,
    createLoading: false,
    createError: false,
    createErrorMsg: null,
  };
};

const userCreateErrorClear = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    createError: false,
    createErrorMsg: null,
    created: null,
  };
};

const singleUserGetStart = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    singleUserLoading: true,
  };
};
const singleUserGetSuccess = (
  state: userTypes.UsersState,
  action: userTypes.SingleUserGetSuccess
): userTypes.UsersState => {
  return {
    ...state,
    singleUser: action.payload,
    singleUserLoading: false,
    singleUserError: false,
  };
};
const singleUserGetFail = (
  state: userTypes.UsersState,
  action: userTypes.SingleUserGetFail
): userTypes.UsersState => {
  return {
    ...state,
    singleUserError: action.payload.error,
    singleUserErrorMsg: action.payload.errorMessage,
    singleUser: initialSingleUser,
  };
};
const singleUserErrorClear = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    singleUserError: false,
    singleUserErrorMsg: null,
  };
};

const userEditStart = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    editLoading: true,
  };
};
const userEditSuccess = (
  state: userTypes.UsersState,
  action: userTypes.UserEditSuccess
): userTypes.UsersState => {
  return {
    ...state,
    editUserInfo: action.payload,
    editError: false,
    editErrorMessage: null,
    editLoading: false,
  };
};

const userEditFail = (state: userTypes.UsersState, action: userTypes.UserEditFail): userTypes.UsersState => {
  return {
    ...state,
    editUserInfo: null,
    editError: action.payload.error,
    editErrorMessage: action.payload.errorMessage,
    editLoading: false,
  };
};
const userEditErrorClear = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    editError: false,
    editErrorMessage: null,
    editUserInfo: null,
  };
};

const userDeleteStart = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    deleteUserLoading: true,
  };
};
const userDeleteFail = (state: userTypes.UsersState, action: userTypes.UserDeleteFail): userTypes.UsersState => {
  return {
    ...state,
    deleteUserLoading: false,
    deleteUserError: action.payload.error,
    deleteUserErroMsg: action.payload.errorMessage,
  };
};

const userDeleteSuccess = (
  state: userTypes.UsersState,
  action: userTypes.UserDeleteSuccess
): userTypes.UsersState => {
  return {
    ...state,
    deletedUser: action.payload,
    deleteUserLoading: false,
    deleteUserError: false,
    deleteUserErroMsg: null,
  };
};
const userDeleteClearError = (state: userTypes.UsersState): userTypes.UsersState => {
  return {
    ...state,
    deleteUserError: false,
    deleteUserErroMsg: null,
    deletedUser: null,
  };
};

const reducer = (state: userTypes.UsersState = initialState, action: userTypes.UsersActionsTypes) => {
  switch (action.type) {
    case userActions.USERS_GET_START:
      return usersGetStart(state);
    case userActions.USERS_GET_SUCCESS:
      return usersGetSuccess(state, action);
    case userActions.USERS_GET_FAIL:
      return usersGetFail(state, action);
    case userActions.USERS_SEARCH:
      return usersSearch(state, action);
    case userActions.USERS_CHANGE_PAGE:
      return usersChangePage(state, action);
    case userActions.USERS_LIMIT_CHANGE:
      return usersLimitChange(state, action);
    case userActions.USERS_ERROR_CLEAR:
      return usersErrorClear(state);
    case userActions.USER_CREATE_START:
      return userCreateStart(state);
    case userActions.USER_CREATE_SUCCESS:
      return userCreateSuccess(state, action);
    case userActions.USER_CREATE_FAIL:
      return userCreateFail(state, action);
    case userActions.USER_CREATE_CLEAR_ERROR:
      return userCreateErrorClear(state);
    case userActions.SINGLE_USER_GET_START:
      return singleUserGetStart(state);
    case userActions.SINGLE_USER_GET_SUCCESS:
      return singleUserGetSuccess(state, action);
    case userActions.SINGLE_USER_GET_FAIL:
      return singleUserGetFail(state, action);
    case userActions.SINGLE_USER_ERROR_CLEAR:
      return singleUserErrorClear(state);
    case userActions.USER_EDIT_START:
      return userEditStart(state);
    case userActions.USER_EDIT_SUCCESS:
      return userEditSuccess(state, action);
    case userActions.USER_EDIT_FAIL:
      return userEditFail(state, action);
    case userActions.USER_EDIT_ERROR_CLEAR:
      return userEditErrorClear(state);
    case userActions.USER_DELETE_START:
      return userDeleteStart(state);
    case userActions.USER_DELETE_FAIL:
      return userDeleteFail(state, action);
    case userActions.USER_DELETE_SUCCESS:
      return userDeleteSuccess(state, action);
    case userActions.USER_DELETE_ERROR_CLEAR:
      return userDeleteClearError(state);
    default:
      return state;
  }
};

export default reducer;
