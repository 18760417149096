import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import allReducers from './reducers';
import { applyMiddleware, createStore } from 'redux';
import { authInit } from '../Auth/authActions';
import { AppActions, AppState } from './storeTypes';

const configureStore = () => {
  const store = createStore(
    allReducers,
    undefined,
    composeWithDevTools(applyMiddleware(thunkMiddleware as ThunkMiddleware<AppState, AppActions>))
  );
  store.dispatch(authInit());
  return store;
};

export default configureStore;
