import { Checkbox, FormControlLabel } from '@material-ui/core';
interface Props {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}
const CheckboxWithLabel = (props: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={(event) => props.onChange(event.target.checked)}
        />
      }
      label={props.label}
    />
  );
};

export default CheckboxWithLabel;
