import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store/storeTypes';
import { accountErrorClear, getAccounts } from './accountsActions';
import PageTitle from '../shared/PageTitle/PageTitle';
import EditAccount from './EditAccount/EditAccount';
import ModalContainer from '../shared/Modal/ModalContainer';
import CreateAccount from './CreateAccount/CreateAccount';
import AccountHeader from './AccountHeader/AccountHeader';
import AccountTable from './AccountsTable/AccountsTable';
import AppContainer from '../shared/AppContainer/AppContainer';

const AccountsPage: React.FC = () => {
  //The authenticated user state from the reducer.
  const authState = useSelector((state: AppState) => state.auth);
  //The account state from the reducer.
  const accountState = useSelector((state: AppState) => state.accounts);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //For each render if the values of the filters has changed and is not searching the accounts will be fetched.
  useEffect(() => {
    if (!accountState.searching) {
      dispatch(
        getAccounts(
          authState.currentUser!.token,
          accountState.page,
          accountState.limit,
          accountState.search
        )
      );
    }
  }, [
    authState.currentUser,
    dispatch,
    accountState.search,
    accountState.page,
    accountState.searching,
    accountState.limit,
  ]);

  //State to manage the opening of the table menu. Take it to redux state.
  const [openTableMenu, setOpenTableMenu] = useState<{
    id: number;
    element: null | HTMLInputElement;
  }>({
    id: 0,
    element: null,
  });

  //When a checkbox is checked it opens the menu.
  const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setOpenTableMenu({
      element: event.target,
      id: parseInt(event.target.value),
    });
  };
  //When a click is registered outside the menu it will close it and remove the element id.
  const onCloseTableMenu = () => {
    setOpenTableMenu({ id: 0, element: null });
  };

  //Closes the error when the x is click.
  const onClearError = () => {
    dispatch(accountErrorClear());
  };
  //State to manage if the edit modal is open.
  const [openEditModal, setOpenEditModal] = useState(false);
  //Opens edit modal.
  const setOpenToEditModal = () => {
    setOpenEditModal(true);
  };
  //Closes edit modal.
  const setCloseToEditModal = () => {
    setOpenEditModal(false);
  };
  //State to manage if create modal is open or closed.
  const [createAccountOpen, setCreateAccountOpen] = useState(false);
  //Opens create modal
  const openCreateAccount = () => {
    setCreateAccountOpen(true);
  };
  //Closes create modal.
  const closeCreateAccount = () => {
    setCreateAccountOpen(false);
  };

  return (
    <AppContainer>
      <>
        <AccountHeader
          loading={accountState.loading}
          search={accountState.search}
        />
        <main>
          <PageTitle
            title='Accounts'
            buttonText='Create account'
            userRoleId={authState.currentUser!.role.id}
            openCreate={openCreateAccount}
          />

          <AccountTable
            onChangeCheckbox={onChangeCheckBox}
            onCloseTableMenu={onCloseTableMenu}
            openTableMenu={openTableMenu}
            setOpenEditModal={setOpenToEditModal}
          />
          <Snackbar
            open={accountState.error}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={onClearError}
          >
            <Alert
              severity='error'
              role='alert'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={onClearError}
                >
                  <Close />
                </IconButton>
              }
              variant='filled'
            >
              {accountState.errorMessage}
            </Alert>
          </Snackbar>

          <ModalContainer open={openEditModal} close={setCloseToEditModal}>
            {
              <EditAccount
                closeModal={setCloseToEditModal}
                closeMenu={onCloseTableMenu}
                accountId={openTableMenu.id}
              />
            }
          </ModalContainer>
          <ModalContainer open={createAccountOpen} close={closeCreateAccount}>
            <CreateAccount />
          </ModalContainer>
        </main>
      </>
    </AppContainer>
  );
};

export default AccountsPage;
