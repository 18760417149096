import Axios from 'axios';

import { GeoCreate, GeosApi, DeletedGeoAnswer } from './geosTypes';

export const getTableGeosApi = async (token: string, page: number, numberOffers: number, search: string) => {
  const { data } = await Axios.get<{ message: string; data: { geos: GeosApi[]; amount: string } }>(
    `/api/geos/?page=${page}&take=${numberOffers}&search=${search}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

//Create geo
export const createGeoApi = async (token: string, body: GeoCreate) => {
  const { data } = await Axios.post<{ message: string; data: { geo: GeosApi } }>('/api/geos', body, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Single Geo
export const getOneGeoApi = async (token: string, geoId: number) => {
  const { data } = await Axios.get<{ message: string; data: { geo: GeosApi } }>(`/api/geos/${geoId}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Edit Geo
export const editGeoApi = async (token: string, body: GeoCreate, geoId: number) => {
  const { data } = await Axios.patch<{ message: string; data: { geo: GeosApi } }>(`/api/geos/${geoId}`, body, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Delete Geo
export const deleteGeoApi = async (token: string, geoId: number) => {
  const { data } = await Axios.delete<DeletedGeoAnswer>('/api/geos', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    data: { id: geoId },
  });
  return data.data;
};

export const getGeosForFilterApi = async (token: string) => {
  const { data } = await Axios.get<{message:string,data:{geos:GeosApi[]}}>('/api/geos/autocomplete', {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};
