import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const pageTitleNoButtonStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingRight: '6.25rem',
    paddingLeft: '1.9375rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '4.5rem',
  },
}));

export default pageTitleNoButtonStyles;
