import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const singleCampaignFilterStyle = makeStyles((theme: Theme) => ({
  container: {
    paddingRight: '6.25rem',
    paddingLeft: '1.9375rem',
    marginBottom: '4.3125rem',
    marginTop: '0.3125rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '1.5rem',
  },
  datPicker: {
    '& .react-daterange-picker__wrapper': {
      border: 'none',
      borderBottom: 'thin solid gray',
    },
    '& .react-daterange-picker__calendar': {
      width: 'auto',
    },
  },
}));

export default singleCampaignFilterStyle;
