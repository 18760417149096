import errorMessageBuilder from '../shared/errorMessageBuilder/errorMessageBuilder';
import { AppActions, AppState, ThunkDispatchType } from '../store/storeTypes';
import { editUserProfileApi, getMeApi, resetPasswordApi, resetPasswordEmailApi, signInApi } from './authApi';
import { CurrentUser } from './authTypes';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REDIRECT_PATH = 'AUTH_REDIRECT_PATH';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';
export const AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS = 'AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS';
export const AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS_CLEAR_MESSAGE =
  'AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS_CLEAR_MESSAGE';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_EDIT_PROFILE_START = 'AUTH_EDIT_PROFILE_START';
export const AUTH_EDIT_PROFILE_SUCCESS = 'AUTH_EDIT_PROFILE_SUCCESS';
export const AUTH_EDIT_PROFILE_FAIL = 'AUTH_EDIT_PROFILE_FAIL';
export const AUTH_EDIT_PROFILE_ERROR_CLEAR = 'AUTH_EDIT_PROFILE_ERROR_CLEAR';
export const AUTH_INIT_START ='AUTH_INIT_START';

export const checkAuthTimeout = (time: number) => async (dispatch: ThunkDispatchType, getState: () => AppState) => {
  setTimeout(() => {
    dispatch(logout());
  }, time);
};

export const logout = (): AppActions => {
  localStorage.removeItem('expiresIn');
  localStorage.removeItem('token');
  return { type: AUTH_LOGOUT };
};

export const authStart = (): AppActions => {
  return { type: AUTH_START };
};

export const authSuccess = (
  token: string,
  userId: number,
  name: string,
  lastName: string,
  email: string,
  userStatus: string,
  profileUrl: string,
  role: { id: number; name: string }
): AppActions => {
  return {
    type: AUTH_SUCCESS,
    payload: {
      token,
      name,
      lastName,
      userId,
      email,
      userStatus,
      profileUrl,
      role,
    },
  };
};
export const authErrorClear = (): AppActions => {
  return { type: AUTH_CLEAR_ERROR };
};
export const authFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: AUTH_FAIL,
    payload: { error, errorMessage },
  };
};

export const resetPasswordEmailSucces = (email: string): AppActions => {
  return {
    type: AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS,
    paload: { email },
  };
};

export const resetPasswordMessageClear = (): AppActions => {
  return {
    type: AUTH_RESET_PASSWORD_SEND_EMAIL_SUCCESS_CLEAR_MESSAGE,
  };
};
export const resetPasswordSuccess = (): AppActions => {
  return {
    type: AUTH_RESET_PASSWORD_SUCCESS,
  };
};
export const signIn = (email: string, password: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(authStart());
    const me = await signInApi(email, password);
    localStorage.setItem('token', me.token);
    localStorage.setItem('expiresIn', me.expiresIn.toString());
    const timer = new Date(me.expiresIn).getTime() - Date.now();
    dispatch(authSuccess(me.token, me.id, me.name, me.lastName, me.email, me.userStatus, me.profilePicture, me.role));
    dispatch(checkAuthTimeout(timer));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(authFail(true, errorMessage ? errorMessage : err));
  }
};

export const authInitStart =():AppActions=>{
  return{
    type:AUTH_INIT_START
  }
}
export const authInit = () => async (dispatch: ThunkDispatchType, getState: () => AppState) => {
  try {
    const getToken = localStorage.getItem('token');
    const getExpiresIn = localStorage.getItem('expiresIn');
    if (!getToken) {
      return dispatch(logout());
    }
    dispatch(authInitStart());

    if (getExpiresIn) {
      const today = new Date();
      const expiresInDate = new Date(parseInt(getExpiresIn));
      if (expiresInDate <= today) {
        return dispatch(logout());
      }

      if (getToken && today <= expiresInDate) {
        const me = await getMeApi(getToken!);
        dispatch(
          authSuccess(
            getToken!,
            me.user.id,
            me.user.name,
            me.user.lastName,
            me.user.email,
            me.user.userStatus,
            me.user.profilePicture,
            me.user.role
          )
        );

        dispatch(checkAuthTimeout(expiresInDate.getTime() - today.getTime()));
      }
    }
  } catch (err) {
    dispatch(logout());
  }
};

export const authResetPasswordEmail = (email: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(authStart());
    await resetPasswordEmailApi(email);
    dispatch(resetPasswordEmailSucces(email));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(authFail(true, errorMessage ? errorMessage : err));
  }
};

export const authResetPassword = (email: string, password: string, repeatPassword:string, token: string) => async (
  dispatch: ThunkDispatchType,
  getState: () => AppState
) => {
  try {
    dispatch(authStart());
    await resetPasswordApi (email,password,repeatPassword,token);
    dispatch(resetPasswordSuccess())
    console.log(email, token, password,repeatPassword);
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(authFail(true, errorMessage ? errorMessage : err));
  }
};

export const authEditProfileStart = (): AppActions => {
  return {
    type: AUTH_EDIT_PROFILE_START,
  };
};
export const authEditProfileSuccess = (user: CurrentUser): AppActions => {
  return {
    type: AUTH_EDIT_PROFILE_SUCCESS,
    payload: { user },
  };
};

export const authEditProfileFail = (error: boolean, errorMessage: string): AppActions => {
  return {
    type: AUTH_EDIT_PROFILE_FAIL,
    payload: { error, errorMessage },
  };
};
export const authEditProfileErrorClear =():AppActions=>{
  return{
    type:AUTH_EDIT_PROFILE_ERROR_CLEAR
  }
}
export const authEditProfile = (token: string,userInfo:FormData) => async (dispatch: ThunkDispatchType, getState: () => AppState) => {
  try {
    dispatch(authEditProfileStart());
    const editUser = await editUserProfileApi(token,userInfo);
    dispatch(authEditProfileSuccess(editUser.user));
  } catch (err) {
    const errorMessage = errorMessageBuilder(err);
    dispatch(authEditProfileFail(true, errorMessage ? errorMessage : err));
  }
};
