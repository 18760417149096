import * as catTypes from './categoriesTypes';
import * as catActions from './categoriesActions';
const initialSingleCat: catTypes.CategoriesApi = { id: 0, name: '' };
const initialState: catTypes.CategoriesState = {
  categories: null,
  page: 0,
  limit: 10,
  amount: 0,
  search: '',
  searching: false,
  loading: false,
  error: false,
  errorMessage: null,
  singleCategory: initialSingleCat,
  singleCategoryError: false,
  singleCategoryErrorMsg: null,
  singleCategoryLoading: false,
  createError: false,
  createErrorMsg: null,
  createLoading: false,
  created: null,
  editError: false,
  editErrorMessage: null,
  editCategoryInfo: null,
  editLoading: false,
  deleteCategoryLoading: false,
  deletedCategory: null,
  deleteCategoryError: false,
  deleteCategoryErroMsg: null,
};

const categoriesGetStart = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    loading: true,
  };
};

const categoriesGetSuccess = (state: catTypes.CategoriesState, action: catTypes.GetCategoriesSucces): catTypes.CategoriesState => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
    categories: action.payload.categories,
    amount: action.payload.amount,
    searching: false,
  };
};
const categoriesGetFail = (state: catTypes.CategoriesState, action: catTypes.GetCategoriesFail): catTypes.CategoriesState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};

const categoriesSearch = (state: catTypes.CategoriesState, action: catTypes.CategoriesSearch): catTypes.CategoriesState => {
  return {
    ...state,
    search: action.payload.search,
    searching: action.payload.searching,
    error: false,
    errorMessage: null,
    loading: false,
    page:0,
  };
};
const categoriesChangePage = (state: catTypes.CategoriesState, action: catTypes.CategoriesChangePage): catTypes.CategoriesState => {
  return {
    ...state,
    page: action.payload.page,
  };
};

const categoriesLimitChange = (state: catTypes.CategoriesState, action: catTypes.CategoriesLimitChage): catTypes.CategoriesState => {
  return {
    ...state,
    limit: action.payload.limit,
  };
};

const categoriesErrorClear = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    error: false,
    errorMessage: null,
  };
};

const categoryCreateStart = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    createLoading: true,
  };
};
const categoryCreateFail = (state: catTypes.CategoriesState, action: catTypes.CategoryCreateFail): catTypes.CategoriesState => {
  return {
    ...state,
    createLoading: false,
    createError: action.payload.error,
    createErrorMsg: action.payload.errorMessage,
  };
};

const categoryCreateSuccess = (state: catTypes.CategoriesState, action: catTypes.CategoryCreateSuccess): catTypes.CategoriesState => {
  return {
    ...state,
    created: action.payload,
    createLoading: false,
    createError: false,
    createErrorMsg: null,
  };
};

const categoryCreateErrorClear = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    createError: false,
    createErrorMsg: null,
    created: null,
  };
};

const singleCategoryGetStart = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
    return {
      ...state,
      singleCategoryLoading: true,
    };
  };
  const singleCategoryGetSuccess = (
    state: catTypes.CategoriesState,
    action: catTypes.SingleCategoryGetSuccess
  ): catTypes.CategoriesState => {
    return {
      ...state,
      singleCategory: action.payload,
      singleCategoryLoading: false,
      singleCategoryError: false,
    };
  };
  const singleCategoryGetFail = (
    state: catTypes.CategoriesState,
    action: catTypes.SingleCategoryGetFail
  ): catTypes.CategoriesState => {
    return {
      ...state,
      singleCategoryError: action.payload.error,
      singleCategoryErrorMsg: action.payload.errorMessage,
      singleCategory: initialSingleCat,
    };
  };
  const singleCategoryErrorClear = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
    return {
      ...state,
      singleCategoryError: false,
      singleCategoryErrorMsg: null,
    };
  };

const categoryEditStart = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    editLoading: true,
  };
};
const categoryEditSuccess = (state: catTypes.CategoriesState, action: catTypes.CategoryEditSuccess): catTypes.CategoriesState => {
  return {
    ...state,
    editCategoryInfo: action.payload,
    editError: false,
    editErrorMessage: null,
    editLoading: false,
  };
};

const categoryEditFail = (state: catTypes.CategoriesState, action: catTypes.CategoryEditFail): catTypes.CategoriesState => {
  return {
    ...state,
    editCategoryInfo: null,
    editError: action.payload.error,
    editErrorMessage: action.payload.errorMessage,
    editLoading: false,
  };
};
const categoryEditErrorClear = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    editError: false,
    editErrorMessage: null,
    editCategoryInfo: null,
  };
};

const categoryDeleteStart = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    deleteCategoryLoading: true,
  };
};
const categoryDeleteFail = (state: catTypes.CategoriesState, action: catTypes.CategoryDeleteFail): catTypes.CategoriesState => {
  return {
    ...state,
    deleteCategoryLoading: false,
    deleteCategoryError: action.payload.error,
    deleteCategoryErroMsg: action.payload.errorMessage,
  };
};

const categoryDeleteSuccess = (state: catTypes.CategoriesState, action: catTypes.CategoryDeleteSuccess): catTypes.CategoriesState => {
  return {
    ...state,
    deletedCategory: action.payload,
    deleteCategoryLoading: false,
    deleteCategoryError: false,
    deleteCategoryErroMsg: null,
  };
};
const categoryDeleteClearError = (state: catTypes.CategoriesState): catTypes.CategoriesState => {
  return {
    ...state,
    deleteCategoryError: false,
    deleteCategoryErroMsg: null,
    deletedCategory: null,
  };
};

const reducer = (state: catTypes.CategoriesState = initialState, action: catTypes.CategoriesActionsTypes) => {
  switch (action.type) {
    case catActions.CATEGORIES_GET_START:
      return categoriesGetStart(state);
    case catActions.CATEGORIES_GET_SUCCESS:
      return categoriesGetSuccess(state, action);
    case catActions.CATEGORIES_GET_FAIL:
      return categoriesGetFail(state, action);
    case catActions.CATEGORIES_SEARCH:
      return categoriesSearch(state, action);
    case catActions.CATEGORIES_CHANGE_PAGE:
      return categoriesChangePage(state, action);
    case catActions.CATEGORIES_LIMIT_CHANGE:
      return categoriesLimitChange(state, action);
    case catActions.CATEGORIES_ERROR_CLEAR:
      return categoriesErrorClear(state);
    case catActions.CATEGORY_CREATE_START:
      return categoryCreateStart(state);
    case catActions.CATEGORY_CREATE_SUCCESS:
      return categoryCreateSuccess(state, action);
    case catActions.CATEGORY_CREATE_FAIL:
      return categoryCreateFail(state, action);
    case catActions.CATEGORY_CREATE_CLEAR_ERROR:
      return categoryCreateErrorClear(state);
    case catActions.SINGLE_CATEGORY_GET_START:
      return singleCategoryGetStart(state);
    case catActions.SINGLE_CATEGORY_GET_SUCCESS:
      return singleCategoryGetSuccess(state, action);
    case catActions.SINGLE_CATEGORY_GET_FAIL:
      return singleCategoryGetFail(state, action);
    case catActions.SINGLE_CATEGORY_ERROR_CLEAR:
        return singleCategoryErrorClear(state)
    case catActions.CATEGORY_EDIT_START:
      return categoryEditStart(state);
    case catActions.CATEGORY_EDIT_SUCCESS:
      return categoryEditSuccess(state, action);
    case catActions.CATEGORY_EDIT_FAIL:
      return categoryEditFail(state, action);
    case catActions.CATEGORY_EDIT_ERROR_CLEAR:
      return categoryEditErrorClear(state);
    case catActions.CATEGORY_DELETE_START:
      return categoryDeleteStart(state);
    case catActions.CATEGORY_DELETE_FAIL:
      return categoryDeleteFail(state, action);
    case catActions.CATEGORY_DELETE_SUCCESS:
      return categoryDeleteSuccess(state, action);
    case catActions.CATEGORY_DELETE_ERROR_CLEAR:
      return categoryDeleteClearError(state);
    default:
      return state;
  }
};

export default reducer;
