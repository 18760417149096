import { TableContainer,Skeleton, Typography, Button } from "@material-ui/core";
import React from "react";
import AppHeader from "../../shared/AppHeader/AppHeader";
import AppMenuSkeleton from "../../shared/AppMenu/AppMenuSkeleton/AppMenuSkeleton";
import SearchInput from "../../shared/SearchInput/SearchInput";
import TableSkeleton from "../../shared/TableSkeleton/TableSkeleton";




export default function AppSkeleton(){


    return (
    <div style={{ display: 'flex' }}>
        <AppMenuSkeleton />
            <div style={{ flexGrow: 1 }} >
             <AppHeader search={ <Skeleton><SearchInput searchValue={''} setSearch={()=>{}} disabled={true}  /> </Skeleton>} />
                <main>
                    <div style={{ paddingRight: '6.25rem', paddingLeft:'1.9375rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '4.5rem' }} >
                       <Skeleton style={{width:'300px'}} > <Typography component='h2' variant='h3'>Accounts</Typography></Skeleton>
                       <Skeleton> <Button  variant='contained' color='primary'>Create Account</Button></Skeleton>
                    </div>
                    <div style={{ paddingRight: '6.25rem', paddingLeft:'1.9375rem', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: '1.5rem' }}>

                    </div>
                    <TableContainer style={{ paddingRight: '6.25rem',paddingLeft:'1.9375rem' }}>
                    <TableSkeleton />
                    </TableContainer>
                    
                </main>
            </div>
        </div>);
}