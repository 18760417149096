import * as accActions from './accountsActions';
import * as accTypes from './accountsTypes';
const initialSingleAccount = {
  name: '',
  id: 0,
  domain: '',
  createdAt: '',
  isAffiliate: false,
  voluumId: '',
  updatedAt: '',
  voluumClickIdParam: '',
  accountManager: [],
  accountsUsers: [],
  technicalAccountManager: [],
};
const initialState: accTypes.AccountsState = {
  accounts: null,
  page: 0,
  limit: 10,
  amount: 0,
  search: '',
  searching: false,
  singleAccount: initialSingleAccount,
  singleAccountError: false,
  singleAccountErrorMsg: null,
  singleAccountLoading: false,
  loading: false,
  error: false,
  errorMessage: null,
  createLoading: false,
  created: null,
  createError: false,
  createErrorMessage: null,
  editLoading: false,
  editError: false,
  editErrorMessage: null,
  editAccountInfo: null,
  deleteAccountLoading: false,
  deletedAccount: null,
  deleteAccountError: false,
  deletedAccountErrorMessage: null,
};

const accountsGetStart = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    loading: true,
  };
};

const accountsGetSuccess = (
  state: accTypes.AccountsState,
  action: accTypes.AccountsGetSuccess
): accTypes.AccountsState => {
  return {
    ...state,
    loading: false,
    accounts: action.payload.accounts,
    amount: action.payload.amount,
    searching: false,
    error: false,
    errorMessage: null,
  };
};

const accountsGetFail = (state: accTypes.AccountsState, action: accTypes.AccountGetFail): accTypes.AccountsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
    errorMessage: action.payload.errorMessage,
  };
};

const accountsSearch = (state: accTypes.AccountsState, action: accTypes.AccountsSearch): accTypes.AccountsState => {
  return {
    ...state,
    search: action.payload.search,
    searching: action.payload.searching,
    error: false,
    errorMessage: null,
    loading: false,
    page: 0,
  };
};
const accountChangePage = (
  state: accTypes.AccountsState,
  action: accTypes.AccountChangePage
): accTypes.AccountsState => {
  return {
    ...state,
    page: action.payload.page,
  };
};

const accountLimitChange = (
  state: accTypes.AccountsState,
  action: accTypes.AccountLimitChage
): accTypes.AccountsState => {
  return {
    ...state,
    limit: action.payload.limit,
  };
};

const accountErrorClear = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    error: false,
    errorMessage: null,
  };
};

const accountCreateStart = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    createLoading: true,
  };
};
const accountCreateFail = (
  state: accTypes.AccountsState,
  action: accTypes.AccountCreateFail
): accTypes.AccountsState => {
  return {
    ...state,
    createLoading: false,
    createError: action.payload.error,
    createErrorMessage: action.payload.errorMessage,
  };
};

const accountCreateSuccess = (
  state: accTypes.AccountsState,
  action: accTypes.AccountCreateSuccess
): accTypes.AccountsState => {
  return {
    ...state,
    created: action.payload,
    createLoading: false,
    createError: false,
    createErrorMessage: null,
  };
};

const accountCreateErrorClear = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    createError: false,
    createErrorMessage: null,
    created: null,
  };
};

const singleAccountGetStart = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    singleAccountLoading: true,
  };
};
const singleAccountGetSuccess = (
  state: accTypes.AccountsState,
  action: accTypes.SingleAccountGetSuccess
): accTypes.AccountsState => {
  return {
    ...state,
    singleAccount: action.payload,
    singleAccountLoading: false,
    singleAccountError: false,
  };
};
const singleAccountGetFail = (
  state: accTypes.AccountsState,
  action: accTypes.SingleAccountGetFail
): accTypes.AccountsState => {
  return {
    ...state,
    singleAccountError: action.payload.error,
    singleAccountErrorMsg: action.payload.errorMessage,
    singleAccount: initialSingleAccount,
  };
};
const singleAccountErrorClear = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    singleAccountError: false,
    singleAccountErrorMsg: null,
  };
};

const accountEditStart = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    editLoading: true,
  };
};
const accountEditSuccess = (
  state: accTypes.AccountsState,
  action: accTypes.AccountEditSuccess
): accTypes.AccountsState => {
  return {
    ...state,
    editAccountInfo: action.payload,
    editError: false,
    editErrorMessage: null,
    editLoading: false,
  };
};

const accountEditFail = (state: accTypes.AccountsState, action: accTypes.AccountEditFail): accTypes.AccountsState => {
  return {
    ...state,
    editAccountInfo: null,
    editError: action.payload.error,
    editErrorMessage: action.payload.errorMessage,
    editLoading: false,
  };
};
const accountEditErrorClear = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    editError: false,
    editErrorMessage: null,
    editAccountInfo: null,
  };
};

const accountDeleteStart = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    deleteAccountLoading: true,
  };
};
const accountDeleteFail = (
  state: accTypes.AccountsState,
  action: accTypes.AccountDeleteFail
): accTypes.AccountsState => {
  return {
    ...state,
    deleteAccountLoading: false,
    deleteAccountError: action.payload.error,
    deletedAccountErrorMessage: action.payload.errorMessage,
  };
};

const accountDeleteSuccess = (
  state: accTypes.AccountsState,
  action: accTypes.AccountDeleteSuccess
): accTypes.AccountsState => {
  return {
    ...state,
    deletedAccount: action.payload,
    deleteAccountLoading: false,
    deleteAccountError: false,
    deletedAccountErrorMessage: null,
  };
};
const accountDeleteClearError = (state: accTypes.AccountsState): accTypes.AccountsState => {
  return {
    ...state,
    deleteAccountError: false,
    deletedAccountErrorMessage: null,
    deletedAccount: null,
  };
};
const reducer = (state: accTypes.AccountsState = initialState, action: accTypes.AccountActionTypes) => {
  switch (action.type) {
    case accActions.ACCOUNTS_GET_START:
      return accountsGetStart(state);
    case accActions.ACCOUNTS_GET_FAIL:
      return accountsGetFail(state, action);
    case accActions.ACCOUNTS_GET_SUCCESS:
      return accountsGetSuccess(state, action);
    case accActions.ACCOUNTS_SEARCH:
      return accountsSearch(state, action);
    case accActions.ACCOUNT_CHANGE_PAGE:
      return accountChangePage(state, action);
    case accActions.ACCOUNT_LIMIT_CHANGE:
      return accountLimitChange(state, action);
    case accActions.ACCOUNT_ERROR_CLEAR:
      return accountErrorClear(state);
    case accActions.ACCOUNT_CREATE_START:
      return accountCreateStart(state);
    case accActions.ACCOUNT_CREATE_FAIL:
      return accountCreateFail(state, action);
    case accActions.ACCOUNT_CREATE_SUCCESS:
      return accountCreateSuccess(state, action);
    case accActions.ACCOUNT_CREATE_CLEAR_ERROR:
      return accountCreateErrorClear(state);
    case accActions.SINGLE_ACCOUNT_GET_START:
      return singleAccountGetStart(state);
    case accActions.SINGLE_ACCOUNT_GET_SUCCESS:
      return singleAccountGetSuccess(state, action);
    case accActions.SINGLE_ACCOUNT_GET_FAIL:
      return singleAccountGetFail(state, action);
    case accActions.SINGLE_ACCOUNT_ERROR_CLEAR:
      return singleAccountErrorClear(state);
    case accActions.ACCOUNT_EDIT_START:
      return accountEditStart(state);
    case accActions.ACCOUNT_EDIT_SUCCESS:
      return accountEditSuccess(state, action);
    case accActions.ACCOUNT_EDIT_FAIL:
      return accountEditFail(state, action);
    case accActions.ACCOUNT_EDIT_ERROR_CLEAR:
      return accountEditErrorClear(state);
    case accActions.ACCOUNT_DELETE_START:
      return accountDeleteStart(state);
    case accActions.ACCOUNT_DELETE_FAIL:
      return accountDeleteFail(state, action);
    case accActions.ACCOUNT_DELETE_SUCCESS:
      return accountDeleteSuccess(state, action);
    case accActions.ACCOUNT_DELETE_ERROR_CLEAR:
      return accountDeleteClearError(state);
    default:
      return state;
  }
};

export default reducer;
