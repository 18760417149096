import Axios from 'axios';

import {  UsersApi,DeletedUserAnswer } from './usersTypes';

export const getTableStrategiesApi = async (token: string, page: number, numberOffers: number, search: string) => {
  const { data } = await Axios.get<{ message: string; data: { users: UsersApi[]; amount: string } }>(
    `/api/users/?page=${page}&take=${numberOffers}&search=${search}`,
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
};

//Create User  UserCreate
export const createUserApi = async (token: string, body: FormData) => {
  const { data } = await Axios.post<{ message: string; data: { user: UsersApi } }>('/api/users', body, {
    headers: { 'Accept':'application/json','Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
  });
  
  return data.data;
};

//Single User
export const getOneUserApi = async (token: string, userId: number) => {
  const { data } = await Axios.get<{ message: string; data: { user: UsersApi } }>(`/api/users/${userId}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};

//Edit User
export const editUserApi=async(token:string,body:FormData,userId:number)=>{
    const {data}= await Axios.patch<{message:string,data:{user:UsersApi}}>(`/api/users/${userId}`,body,{
      headers: { 'Accept':'application/json','Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
      });
    return data.data
}

//Delete User
export const deleteUserApi= async(token:string,userId:number)=>{
    const {data}= await Axios.delete<DeletedUserAnswer>('/api/users',{
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        data: { id:userId },
      })
    return data.data
}

//Get status
export const getStatusInputApi = async(token:string)=>{
  const {data}= await Axios.get<{message:string,data:{status:['Active','Not-Active','Blocked']}}>('/api/users/statusinput',{
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
  return data.data;
}
//Get status
export const getRolesIputApi = async(token:string)=>{
  const {data}= await Axios.get<{message:string,data:{roles:{id:number,name:string}[]}}>('/api/users/rolesinput',{
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
  return data.data;
}

export const getUsersByRoleApi = async (token: string, roleId: number) => {
  const { data } = await Axios.get<{
    message: string;
    data: { users: { name: string; id: number; lastName: string }[] };
  }>(`/api/users/roles/${roleId}`, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  return data.data;
};