import React, { ChangeEvent, useState, FocusEvent, FormEvent } from 'react';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import createGeoStyles from './createGeoStyles';
import { GeoCreate } from '../geosTypes';
import { geoCreateErrorClear, createGeo } from '../geosActions';
import { Close } from '@material-ui/icons';

const useStyles = createGeoStyles;

const CreateGeo: React.FC = () => {
  const authState = useSelector((state: AppState) => state.auth);
  const geoState = useSelector((state: AppState) => state.geos);
  const dispatch = useDispatch();
  const styles = useStyles();

  const [geoName, setGeoName] = useState({ value: '', error: false });
  const onChangeGeoName = (event: ChangeEvent<HTMLInputElement>) => {
    setGeoName({ value: event.target.value, error: false });
  };
  const onBlurGeoName = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 3) {
      setGeoName((prev) => ({ ...prev, error: true }));
    }
  };

  const [code, setCode] = useState({ value: '', error: false });
  const onChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setCode({ value: event.target.value, error: false });
  };
  const onBlurCode = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length !== 2) {
      setCode((prev) => ({ ...prev, error: true }));
    }
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    const geoObject: GeoCreate = {
      name: geoName.value,
      code: code.value,
    };
    dispatch(createGeo(authState.currentUser!.token, geoObject));
  };

  const error = geoName.error ? true : code.error ? true : false;
  const onClearError = () => {
    dispatch(geoCreateErrorClear());
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant='h3' component='h3'>
          Create new Geo
        </Typography>
      </div>

      <form className={styles.form} onSubmit={formSubmit}>
        <TextField
          value={geoName.value}
          name='geo-name'
          onChange={onChangeGeoName}
          onBlur={onBlurGeoName}
          error={geoName.error}
          className={`${styles.inputs} ${styles.firstInputText}`}
          label='Geo name'
          variant='standard'
          helperText={geoName.error ? 'No less than 3 characers' : ' '}
          required
        />
        <TextField
          name='code'
          value={code.value}
          onChange={onChangeCode}
          onBlur={onBlurCode}
          error={code.error}
          className={styles.inputs}
          label='Country short name'
          variant='standard'
          helperText={code.error ? 'Country code is 2 characters EX:CO' : ' '}
          required
        />

        <Button
          className={styles.button}
          variant='contained'
          color='primary'
          disabled={error || geoState.createLoading || geoState.createError}
        >
          {geoState.createLoading ? <CircularProgress /> : 'Submit'}
        </Button>
      </form>
      <Snackbar
        open={geoState.createError || Boolean(geoState.created)}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={onClearError}
      >
        <Alert
          severity={geoState.createError ? 'error' : 'success'}
          role='alert'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClearError}
            >
              <Close />{' '}
            </IconButton>
          }
          variant='filled'
        >
          {geoState.createError ? geoState.createErrorMsg : 'Geo created!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateGeo;
