import React from 'react';
import { useDispatch } from 'react-redux';
import AppHeader from '../../shared/AppHeader/AppHeader';
import SearchInput from '../../shared/SearchInput/SearchInput';
import { usersSearch } from '../usersActions';

interface Props {
  search: string;
  loading: boolean;
}
//Timer for search
let timer: NodeJS.Timeout;
//type timer = ReturnType<typeof setTimeout>;
const UserHeader: React.FC<Props> = (props) => {
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Search value changes when user types.
  const onChangeSearch = (searchVal: string) => {
    //clear previour timeout
    clearTimeout(timer);
    //We send the typing value to the reducer.
    dispatch(usersSearch(searchVal, true));
    //Set the timeout to dispatch the search
    timer = setTimeout(() => dispatch(usersSearch(searchVal, false)), 500);
  };

  return (
    <AppHeader
      search={
        <SearchInput
          searchValue={props.search}
          setSearch={onChangeSearch}
          disabled={props.loading}
        />
      }
    />
  );
};

export default UserHeader;
