import { Modal, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface Props {
  open: boolean;
  close: () => void;
}
const ModalContainer: React.FC<Props> = (props) => {
  return (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        style={{
          outline: 'none',
          minWidth: '43.75rem',
          maxWidth: '55rem',
          maxHeight: '55.8rem',
          minHeight: '30rem',
        }}
        elevation={10}
      >
        <>
          <div style={{ padding: '1.82rem' }}>
            <Close style={{ cursor: 'pointer' }} onClick={props.close} />
          </div>
          {props.children}
        </>
      </Paper>
    </Modal>
  );
};

export default ModalContainer;
