import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from './../store/storeTypes';
import { strategiesErrorClear, getStrategies } from './strategiesActions';
import AppContainer from '../shared/AppContainer/AppContainer';
import PageTitle from '../shared/PageTitle/PageTitle';
import EditStrategy from './EditStrategy/EditStrategy';
import ModalContainer from './../shared/Modal/ModalContainer';
import CreateGeo from './CreateStrategy/CreateStrategy';
import StrategyHeader from './StrategyHeader/StrategyHeader';
import StraregiesTable from './StrategiesTable/StrategiesTable';

const StrategiesPage: React.FC = () => {
  //The authenticated user state from the reducer.
  const authState = useSelector((state: AppState) => state.auth);
  //The strategy state from the reducer.
  const strategyState = useSelector((state: AppState) => state.strategies);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //For each render if the values of the filters has changed and is not searching the accounts will be fetched.
  useEffect(() => {
    if (!strategyState.searching) {
      dispatch(
        getStrategies(
          authState.currentUser!.token,
          strategyState.page,
          strategyState.limit,
          strategyState.search
        )
      );
    }
  }, [
    authState.currentUser,
    dispatch,
    strategyState.search,
    strategyState.page,
    strategyState.searching,
    strategyState.limit,
  ]);
  //State to manage the opening of the table menu.
  const [openTableMenu, setOpenTableMenu] = useState<{
    id: number;
    element: null | HTMLInputElement;
  }>({
    id: 0,
    element: null,
  });
  //When a checkbox is checked it opens the menu.
  const onChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setOpenTableMenu({
      element: event.target,
      id: parseInt(event.target.value),
    });
  };
  //When a click is registered outside the menu it will close it and remove the element id.
  const onCloseTableMenu = () => {
    setOpenTableMenu({ id: 0, element: null });
  };
  //Closes the error when the x is click.
  const onClearError = () => {
    dispatch(strategiesErrorClear());
  };
  //State to manage if the edit modal is open.
  const [openEditModal, setOpenEditModal] = useState(false);
  //Opens edit modal.
  const setOpenToEditModal = () => {
    setOpenEditModal(true);
  };
  //Closes edit modal.
  const setCloseToEditModal = () => {
    setOpenEditModal(false);
  };
  //State to manage if create modal is open or closed.
  const [createStrategyOpen, setCreateStrategyOpen] = useState(false);
  //Opens create modal
  const openCreateStrategy = () => {
    setCreateStrategyOpen(true);
  };
  //Closes create modal.
  const closeCreateStrategy = () => {
    setCreateStrategyOpen(false);
  };

  return (
    <AppContainer>
      <>
        <StrategyHeader
          loading={strategyState.loading}
          search={strategyState.search}
        />
        <main>
          <PageTitle
            buttonText='Create Strategy'
            openCreate={openCreateStrategy}
            title='Strategies'
            userRoleId={authState.currentUser!.role.id}
          />
          <StraregiesTable
            onChangeCheckbox={onChangeCheckBox}
            onCloseTableMenu={onCloseTableMenu}
            openTableMenu={openTableMenu}
            setOpenEditModal={setOpenToEditModal}
          />

          <Snackbar
            open={strategyState.error}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            onClose={onClearError}
          >
            <Alert
              severity='error'
              role='alert'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={onClearError}
                >
                  <Close />{' '}
                </IconButton>
              }
              variant='filled'
            >
              {strategyState.errorMessage}
            </Alert>
          </Snackbar>

          <ModalContainer open={openEditModal} close={setCloseToEditModal}>
            {
              <EditStrategy
                closeModal={setCloseToEditModal}
                closeMenu={onCloseTableMenu}
                strategyId={openTableMenu.id}
              />
            }
          </ModalContainer>
          <ModalContainer open={createStrategyOpen} close={closeCreateStrategy}>
            <CreateGeo />
          </ModalContainer>
        </main>
      </>
    </AppContainer>
  );
};

export default StrategiesPage;
