import React, { ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/storeTypes';
import {
  strategiesLimitChange,
  strategiesPageChange,
} from '../strategiesActions';
import { StrategiesApi } from '../strategiesTypes';
import TableComponent from '../../shared/TableComponent/TableComponent';
import TablesMenu from '../../shared/TablesMenu/TablesMenu';

const tableDefinition = [
  { title: 'Id', value: (strategy: StrategiesApi) => strategy.id },
  { title: 'Name', value: (strategy: StrategiesApi) => strategy.name },
];

interface Props {
  setOpenEditModal: () => void;
  openTableMenu: { id: number; element: null | HTMLInputElement };
  onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  onCloseTableMenu: () => void;
}
const StrategiesTable: React.FC<Props> = (props) => {
  //The account state from the reducer.
  const strategyState = useSelector((state: AppState) => state.strategies);
  // dispatch hook to dispatch actions.
  const dispatch = useDispatch();
  //Changes the page from the table.
  const onChangePage = (page: number) => {
    //Sends the new page value to the reducer.
    dispatch(strategiesPageChange(page));
  };
  //Changes the limit of the table.
  const onChangeLimit = (limit: number) => {
    //Sends the limit to the reducer.
    dispatch(strategiesLimitChange(limit));
  };

  return (
    <>
      <TableComponent
        name='Strategies'
        tableDefinition={tableDefinition}
        items={strategyState.strategies}
        amount={strategyState.amount}
        limit={strategyState.limit}
        loading={strategyState.loading}
        error={strategyState.error}
        page={strategyState.page}
        openMenuId={props.openTableMenu.id}
        onChangeCheckbox={props.onChangeCheckbox}
        onChangeLimit={onChangeLimit}
        onChangePage={onChangePage}
      />
      <TablesMenu
        open={Boolean(props.openTableMenu.element)}
        anchorEl={props.openTableMenu.element}
        onClose={props.onCloseTableMenu}
        onClickButton={props.setOpenEditModal}
      />
    </>
  );
};

export default StrategiesTable;
